import React from 'react';
import { Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { UtctoLocaleFormmatter } from '../../../utils/Utils';

function Notice(props) {
    const ty = props.ty;
    const subjectTitle = props.subjectTitle;
    const subjectDate = props.subjectDate;
    const id = props.id;
    
    return (
        <div className="list">
            <Link to={"/notice/"+id}>
                <div className="list-subject row">
                    <div className="list-title col-9 col-sm-9">
                        {
                            {
                                NOTICE:
                                    <Badge pill bg="danger" className="badge">공지</Badge>,
                                GUIDE:
                                    <Badge pill bg="primary" className="badge">이용가이드</Badge>,
                            }[ty]
                        }
                        <span className="">{subjectTitle}</span>
                    </div>
                    <div className="list-date col-2 col-sm-3 text-center">
                        { UtctoLocaleFormmatter(subjectDate) }
                    </div>
                </div>
            </Link>
        </div>
    )
}

export default Notice
import {
    GET_LANDING_NOTICE_LIST,
    GET_DETAIL_ARTICLE,
    GET_ARTICLE_LIST
} from './types';
import { apiGetPrams, apiGet } from '../api/api';

export function getLandingNoticeList() {
    const request = apiGetPrams("/api/v1/articles", {"currentPage": 1, "perPage": 5, "ty": "NOTICE"})
    .then(response => response.data);

    return  {
        type: GET_LANDING_NOTICE_LIST,
        payload: request
    }
}

export function getArticleList(param) {
    const request = apiGetPrams("/api/v1/articles", param)
    .then(response => response.data);

    return  {
        type: GET_ARTICLE_LIST,
        payload: request
    }
}

export function getDetailArticle(id) {
    const request = apiGet("/api/v1/articles/"+id)
    .then(response => response.data);

    return  {
        type: GET_DETAIL_ARTICLE,
        payload: request
    }
}
import React, { useLayoutEffect, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import InputMask from "react-input-mask";
import CheckBox from "../../../components/CheckBox/CheckBox";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { qrpayPaycardValidation } from "../../../validations/Validation";
import { getStipulation } from "../../../_actions/stipulation_action";

function Payment(props) {
  const dispatch = useDispatch();
  const [stipulrationCheckList, setStipulrationCheckList] = useState([
    false,
    false,
    false,
  ]); // 약관
  const setAllCheck = props.setAllCheck;
  const allCheck = props.allCheck;
  const onQrpaySubmit = props.onQrpaySubmit;
  const schema = qrpayPaycardValidation;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();

  const [policy, setPolicy] = useState("");
  const [qrpayFinance, setQrpayFinance] = useState("");
  const [privacy, setPrivacy] = useState("");

  useLayoutEffect(() => {
    dispatch(getStipulation("이용약관")).then((response) => {
      if (response.payload.result === "OK") {
        if (response.payload.data.cn) {
          setPolicy(response.payload.data.cn);
        } else {
          alert(
            "[페이지 오류 안내] 홈페이지 이용에 불편을 드려 죄송합니다. 새로고침 (F5키)을 누르시거나, 잠시 후에 이용해 주십시오."
          );
          //   let urlarr = window.location.href.split("/");
          //   window.location.href = urlarr[0] + "/QRpay";
          navigate("/QRpay");
        }
      } else {
        // 실패 처리
        alert(
          "[페이지 오류 안내] 홈페이지 이용에 불편을 드려 죄송합니다. 새로고침 (F5키)을 누르시거나, 잠시 후에 이용해 주십시오."
        );
        // let urlarr = window.location.href.split("/");
        //   window.location.href = urlarr[0] + "/QRpay";
        navigate("/QRpay");
      }
    });

    dispatch(getStipulation("전자금융거래 이용약관")).then((response) => {
      if (response.payload.result === "OK") {
        if (response.payload.data.cn) {
          setQrpayFinance(response.payload.data.cn);
        } else {
          alert(
            "[페이지 오류 안내] 홈페이지 이용에 불편을 드려 죄송합니다. 새로고침 (F5키)을 누르시거나, 잠시 후에 이용해 주십시오."
          );
          //   let urlarr = window.location.href.split("/");
          //     window.location.href = urlarr[0] + "/QRpay";
          navigate("/QRpay");
        }
      } else {
        // 실패 처리
        alert(
          "[페이지 오류 안내] 홈페이지 이용에 불편을 드려 죄송합니다. 새로고침 (F5키)을 누르시거나, 잠시 후에 이용해 주십시오."
        );
        // let urlarr = window.location.href.split("/");
        // window.location.href = urlarr[0] + "/QRpay";
        navigate("/QRpay");
      }
    });

    dispatch(getStipulation("개인정보 수집 및 이용 동의 (비회원 결제)")).then(
      (response) => {
        if (response.payload.result === "OK") {
          if (response.payload.data.cn) {
            setPrivacy(response.payload.data.cn);
          } else {
            alert(
              "[페이지 오류 안내] 홈페이지 이용에 불편을 드려 죄송합니다. 새로고침 (F5키)을 누르시거나, 잠시 후에 이용해 주십시오."
            );
            // let urlarr = window.location.href.split("/");
            // window.location.href = urlarr[0] + "/QRpay";
            navigate("/QRpay");
          }
        } else {
          // 실패 처리
          alert(
            "[페이지 오류 안내] 홈페이지 이용에 불편을 드려 죄송합니다. 새로고침 (F5키)을 누르시거나, 잠시 후에 이용해 주십시오."
          );
          //   let urlarr = window.location.href.split("/");
          //   window.location.href = urlarr[0] + "/QRpay";
          navigate("/QRpay");
        }
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCheckHandler = (index) => {
    switch (index.target.value) {
      case "policy":
        setStipulrationCheckList([
          !stipulrationCheckList[0],
          stipulrationCheckList[1],
          stipulrationCheckList[2],
        ]);
        if (stipulrationCheckList[1] && stipulrationCheckList[2]) {
          setAllCheck(true);
        }

        if (stipulrationCheckList[0] && allCheck) {
          setAllCheck(false);
        }
        break;
      case "qrpay-finance":
        setStipulrationCheckList([
          stipulrationCheckList[0],
          !stipulrationCheckList[1],
          stipulrationCheckList[2],
        ]);
        if (stipulrationCheckList[0] && stipulrationCheckList[2]) {
          setAllCheck(true);
        }

        if (stipulrationCheckList[1] && allCheck) {
          setAllCheck(false);
        }
        break;
      case "privacy":
        setStipulrationCheckList([
          stipulrationCheckList[0],
          stipulrationCheckList[1],
          !stipulrationCheckList[2],
        ]);
        if (stipulrationCheckList[0] && stipulrationCheckList[1]) {
          setAllCheck(true);
        }

        if (stipulrationCheckList[2] && allCheck) {
          setAllCheck(false);
        }
        break;
      default:
        // 모두 동의하기 버튼 선택시
        setStipulrationCheckList([!allCheck, !allCheck, !allCheck]);
        setAllCheck(!allCheck);
        break;
    }
  };

  return (
    <div>
      {/* {errors.allCheck?.message && alert( errors.allCheck?.message)} */}
      <Form onSubmit={handleSubmit(onQrpaySubmit)}>
        {/* <input type="hidden" {...register("allCheck")} value={allCheck}/> */}
        <div className="text-center mb-3">
          <small className="text-muted">
            * 신용카드 결제정보를 입력해주세요
          </small>
        </div>

        <div className="col-12 form-group mb-4">
          <div className="row">
            <div className="col-4">
              <Form.Check
                inline
                type="radio"
                label="개인카드"
                value="personal"
                name="paycard_type"
                id="personal"
                defaultChecked="checked"
              />
            </div>
            <div className="col-8">
              <Form.Check
                inline
                type="radio"
                label="법인카드"
                value="corporation"
                name="paycard_type"
                id="corporation"
              />
            </div>
          </div>
        </div>

        <div>
          <div className="row form-group">
            <div className="col-4">
              카드 번호 <span className="text-danger"> *</span>
            </div>
            <div className="col-8">
              <InputMask
                className={
                  "form-control " +
                  (errors.paycardNo?.message ? "is-invalid" : "")
                }
                mask="9999-9999-9999-9999"
                maskChar=""
                {...register("paycardNo")}
              />
              {errors.paycardNo?.message && (
                <div className="invalid-feedback">
                  {errors.paycardNo?.message}
                </div>
              )}
              <Form.Text className="text-muted">
                예) 1234-1234-1234-1234
              </Form.Text>
            </div>
          </div>
          <div className="row form-group">
            <div className="col-4">
              유효기간 <span className="text-danger"> *</span>
            </div>
            <div className="col-8">
              <InputMask
                className={
                  "form-control " +
                  (errors.paycardExp?.message ? "is-invalid" : "")
                }
                mask="99-99"
                maskChar=""
                {...register("paycardExp")}
              />
              {errors.paycardExp?.message && (
                <div className="invalid-feedback">
                  {errors.paycardExp?.message}
                </div>
              )}
              <Form.Text className="text-muted">
                월-년 순서로 입력, 예) 08-24
              </Form.Text>
            </div>
          </div>
          <div className="row form-group">
            <div className="col-4">
              생년월일/사업자번호 <span className="text-danger"> *</span>
            </div>
            <div className="col-8">
              <InputMask
                className={
                  "form-control " +
                  (errors.paycardBirth?.message ? "is-invalid" : "")
                }
                mask="9999999999"
                maskChar=""
                {...register("paycardBirth")}
              />
              {errors.paycardBirth?.message && (
                <div className="invalid-feedback">
                  {errors.paycardBirth?.message}
                </div>
              )}
              <Form.Text className="text-muted">
                예) 850823(개인), 1234578900(사업자)
              </Form.Text>
            </div>
          </div>
          <div className="row form-group">
            <div className="col-4">
              비밀번호 앞 2자리 <span className="text-danger"> *</span>
            </div>
            <div className="col-8">
              <InputMask
                type="password"
                className={
                  "form-control " +
                  (errors.paycardPw?.message ? "is-invalid" : "")
                }
                mask="99"
                maskChar=""
                {...register("paycardPw")}
              />
              {errors.paycardPw?.message && (
                <div className="invalid-feedback">
                  {errors.paycardPw?.message}
                </div>
              )}
            </div>
          </div>
        </div>

        <section className="section-agree col-12 mb-4">
          <div className="form-group mb-3">
            <CheckBox
              name="all"
              label="모두 동의합니다."
              onCheck={onCheckHandler}
              checked={allCheck}
              value="allCheck"
            />
          </div>

          <div className="form-group mb-3">
            <CheckBox
              name="policy"
              label="(필수) 충전왕 서비스 이용약관에 동의합니다."
              onCheck={onCheckHandler}
              checked={stipulrationCheckList[0]}
              value="policy"
            />
            {policy ? (
              <div
                className="iframe w-100"
                dangerouslySetInnerHTML={{ __html: policy }}
              ></div>
            ) : (
              <div className="iframe w-100">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            )}
          </div>

          <div className="form-group mb-3">
            <CheckBox
              name="qrpay-finance"
              label="(필수) 전자금융거래 이용약관에 동의합니다."
              onCheck={onCheckHandler}
              checked={stipulrationCheckList[1]}
              value="qrpay-finance"
            />
            {/* <div className="iframe w-100" dangerouslySetInnerHTML= {{__html: text}}></div> */}
            {qrpayFinance ? (
              <div
                className="iframe w-100"
                dangerouslySetInnerHTML={{ __html: qrpayFinance }}
              ></div>
            ) : (
              <div className="iframe w-100">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            )}
          </div>
          <div className="form-group mb-3">
            <CheckBox
              name="privacy"
              label="(필수) 개인정보 수집 및 이용에 동의합니다."
              onCheck={onCheckHandler}
              checked={stipulrationCheckList[2]}
              value="privacy"
            />
            {/* <div className="iframe w-100" dangerouslySetInnerHTML= {{__html: text}}></div> */}
            {privacy ? (
              <div
                className="iframe w-100"
                dangerouslySetInnerHTML={{ __html: privacy }}
              ></div>
            ) : (
              <div className="iframe w-100">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            )}
          </div>
        </section>
        <div className="navigator">
          <Link to={-1} className="prev float-left">
            이전
          </Link>
          <button
            type="submit"
            className="next text-right float-right active btn-go-pay btn"
          >
            결제
          </button>
          <div className="clearfix"></div>
        </div>
      </Form>
    </div>
  );
}

export default Payment;

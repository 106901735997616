import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { logOutUser } from "../../_actions/user_action";

function SideBarModal(props) {
  // 로그인 여부
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.user.loginReqSuccess);
  const [, setSideBarShow] = useState(props.show);
  const navigate = useNavigate();

  useEffect(() => {
    setSideBarShow(props.show);
  }, [props.show]);

  const onClickCloseHandler = () => {
    props.setSideBarShow(false);
  };

  const onClickLogoutHandler = () => {
    dispatch(logOutUser());
    props.setSideBarShow(false);
    //let urlarr = window.location.href.split("/");
    //window.location.href=urlarr[0] + "/";
    navigate("/");
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      aria-labelledby="contained-modal-title-vcenter"
      // centered
      className="sidebar"
      autoFocus
      fullscreen="md-down"
    >
      <Modal.Header>
        <button
          type="button"
          className="btn-close"
          onClick={onClickCloseHandler}
        ></button>
        {!isLoggedIn ? (
          <Link
            to="/login"
            className="btn btn-login"
            onClick={onClickCloseHandler}
          >
            로그인
          </Link>
        ) : (
          <button
            to="/loginout"
            className="btn btn-login"
            onClick={onClickLogoutHandler}
          >
            로그아웃
          </button>
        )}
      </Modal.Header>
      <Modal.Body>
        <div className="list-group list-group-flush">
          <Link
            to="/"
            className="list-group-item list-group-item-action"
            onClick={onClickCloseHandler}
          >
            Home
          </Link>
          <Link
            to="/receptions"
            className="list-group-item list-group-item-action"
            onClick={onClickCloseHandler}
          >
            충전기설치신청
          </Link>
          {/* <Link to="/notice?currentPage=1" className="list-group-item list-group-item-action" onClick={onClickCloseHandler}>공지사항</Link> */}
          {!isLoggedIn && (
            <Link
              to="/register"
              className="list-group-item list-group-item-action"
              onClick={onClickCloseHandler}
            >
              멤버십가입
            </Link>
          )}
          {isLoggedIn && (
            <Link
              to="/me"
              className="list-group-item list-group-item-action"
              onClick={onClickCloseHandler}
            >
              회원정보
            </Link>
          )}
          {isLoggedIn && (
            <Link
              to="/charging?currentPage=1"
              className="list-group-item list-group-item-action"
              onClick={onClickCloseHandler}
            >
              충전이력
            </Link>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default SideBarModal;

import React from 'react'
import QRpay from '../Layouts/QRpay/QRpay'
import ShowInfo from './Contents/ShowInfo'

function QRpayDetail() {
  return (
    <QRpay QRContent={<ShowInfo />} />
  ) 
}

export default QRpayDetail
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import InputMask from "react-input-mask";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { updatePaycardValidation } from "../../validations/Validation";
import { updateUserPaycard } from "../../_actions/user_action";

function Paycard() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const schema = updatePaycardValidation;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();

  const onSubmitHandler = (event) => {
    let body = {
      id: user.loginReqSuccess.data.id,
      paycardNo: event.paycardNo.replace(/ /gi, "").replace(/-/gi, ""),
      paycardExp: event.paycardExp.replace(/ /gi, "").replace(/-/gi, ""),
      paycardBirth: event.paycardBirth.replace(/ /gi, "").replace(/-/gi, ""),
      paycardPw: event.paycardPw.replace(/ /gi, ""),
    };

    if (window.confirm("결제카드를 변경 하시겠습니까?")) {
      dispatch(updateUserPaycard(body)).then((response) => {
        if (response.payload.result === "OK") {
          alert("정상적으로 완료되었습니다.");
          // let urlarr = window.location.href.split("/");
          // window.location.href=urlarr[0] + "/me";
          navigate("/me");
        } else {
          if (response.payload.data.error) {
            alert(
              "[" +
                response.payload.data.error.msg.ResultCode +
                "] " +
                response.payload.data.error.msg.ResultMsg
            );
          } else if (response.payload.data.validation) {
            alert(
              "[" +
                response.payload.data.validation.error +
                "] " +
                response.payload.data.validation.msg
            );
          } else {
            alert(
              "[페이지 오류 안내] 홈페이지 이용에 불편을 드려 죄송합니다. 새로고침 (F5키)을 누르시거나, 잠시 후에 이용해 주십시오."
            );
          }
        }
      });
    }
  };

  return (
    <main className="me-index">
      <div className="container">
        <section className="section-4">
          <div className="section-4-title text-center position-relative">
            결제카드 변경
          </div>
          <Form onSubmit={handleSubmit(onSubmitHandler)}>
            <table className="table border">
              <tbody>
                <tr>
                  <th>
                    카드 번호 <span className="text-danger"> *</span>
                  </th>
                  <td>
                    <InputMask
                      className={
                        "form-control " +
                        (errors.paycardNo?.message ? "is-invalid" : "")
                      }
                      mask="9999-9999-9999-9999"
                      maskChar=""
                      name="paycardNo"
                      {...register("paycardNo")}
                    />
                    {errors.paycardNo?.message && (
                      <div className="invalid-feedback">
                        {errors.paycardNo?.message}
                      </div>
                    )}
                    <Form.Text className="text-muted">
                      예) 1234-1234-1234-1234
                    </Form.Text>
                  </td>
                </tr>
                <tr>
                  <th>
                    유효 기간 <span className="text-danger"> *</span>
                  </th>
                  <td>
                    <InputMask
                      className={
                        "form-control " +
                        (errors.paycardExp?.message ? "is-invalid" : "")
                      }
                      mask="99-99"
                      maskChar=""
                      name="paycardExp"
                      {...register("paycardExp")}
                    />
                    {errors.paycardExp?.message && (
                      <div className="invalid-feedback">
                        {errors.paycardExp?.message}
                      </div>
                    )}
                    <Form.Text className="text-muted">
                      월-년 순서로 입력, 예) 08-24
                    </Form.Text>
                  </td>
                </tr>
                <tr>
                  <th>
                    생년월일/사업자번호 <span className="text-danger"> *</span>
                  </th>
                  <td>
                    <InputMask
                      className={
                        "form-control " +
                        (errors.paycardBirth?.message ? "is-invalid" : "")
                      }
                      mask="9999999999"
                      maskChar=""
                      name="paycardBirth"
                      {...register("paycardBirth")}
                    />
                    {errors.paycardBirth?.message && (
                      <div className="invalid-feedback">
                        {errors.paycardBirth?.message}
                      </div>
                    )}
                    <Form.Text className="text-muted">
                      예) 850823(개인), 1234578900(사업자)
                    </Form.Text>
                  </td>
                </tr>
                <tr>
                  <th>
                    비밀번호 앞 2자리 <span className="text-danger"> *</span>
                  </th>
                  <td>
                    <InputMask
                      className={
                        "form-control " +
                        (errors.paycardPw?.message ? "is-invalid" : "")
                      }
                      mask="99"
                      maskChar=""
                      type="password"
                      name="paycardPw"
                      {...register("paycardPw")}
                    />
                    {errors.paycardPw?.message && (
                      <div className="invalid-feedback">
                        {errors.paycardPw?.message}
                      </div>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="btns my-5 text-center">
              <button type="submit" className="btn btn-main rounded-pill">
                수정
              </button>
              <Link to="/me" className="btn btn-gray rounded-pill">
                취소
              </Link>
            </div>
          </Form>
        </section>
      </div>
    </main>
  );
}

export default Paycard;

import React from 'react';
import { Link } from 'react-router-dom';
import iconComp from '../../img/icon-comp.png';

function ChargerReceptionCompletePage() {
  return (
    <main className="reception">
      <div className="container">
        <div className="col text-center">
          <img src={iconComp} className="mt-5" alt="icon_comp" />

          <div className="d-none d-lg-block mt-5">
            <h3>충전기 설치 신청이 완료되었습니다.</h3>
          </div>
          <div className="d-block d-lg-none mt-5">
            <h4>충전기 설치 신청이<br/>완료되었습니다.</h4>
          </div>

          <div className="notice mt-3">
            <span>담당자</span>가 <span>3일 안</span>에 <span>연락</span> 드리도록 하겠습니다.
          </div>

          <div className="text-center mt-5 btns">
            <Link to="/" className="btn btn-gray rounded-pill">홈으로</Link>
            {/* <Link to="/subsidy" className="btn btn-main rounded-pill">보조금 정보</Link> */}
          </div>
        </div>
      </div>
    </main>
  )
}

export default ChargerReceptionCompletePage
import {
    CHARGER_RECEPTION
} from './types';
import { apiPost } from '../api/api';

export function chargerReception(data) {
    const request = apiPost("/api/v1/chargerInstallReq/store", data)
    .then(response => response.data);

    return  {
        type: CHARGER_RECEPTION,
        payload: request
    }
}


import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Form, FormControl, InputGroup } from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { getChargerToQr } from "../../../_actions/qr_action";

function SearchQRcode() {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const location = useLocation();
  let [isSearchChager, setIsSearchChager] = useState("");
  const params = new URLSearchParams(location.search); //url의 파라미터값을 가져온다.
  let sid = params.get("sid");
  let cid = params.get("cid");
  let qrcode = params.get("qrcode");
  //sid, cid는 evwhere 어플에서 링크로 오는 애들이 사용된다.
  if (sid && cid) {
    let pram = {
      sid: sid,
      cid: cid,
    };

    dispatch(getChargerToQr(pram)).then((response) => {
      if (response.payload.result === "OK") {
        // 조회 성공
        if (response.payload.data.qrcode) {
          navigate(response.payload.data.qrcode);
        } else {
          alert("비회원 현장결제를 지원하지 않는 충전기입니다.");
        }
      } else {
        setIsSearchChager(false);
      }
    });
  } else if (qrcode) {
    let pram = {
      qrcode: qrcode,
    };
    dispatch(getChargerToQr(pram)).then((response) => {
      if (response.payload.result === "OK") {
        // 조회 성공
        if (response.payload.data.qrcode) {
          navigate(response.payload.data.qrcode);
        } else {
          alert("비회원 현장결제를 지원하지 않는 충전기입니다.");
        }
      } else {
        setIsSearchChager(false);
      }
    });
  }

  const onSubmitHandler = (event) => {
    event.preventDefault();
    let qrcode = event.currentTarget.qrcode.value;
    let params = {
      qrcode: qrcode,
    };

    dispatch(getChargerToQr(params)).then((response) => {
      if (response.payload.result === "OK") {
        // 조회 성공
        setIsSearchChager(true);
        let path = qrcode;
        navigate(path);
      } else {
        setIsSearchChager(false);
      }
    });
  };
  return (
    <div>
      <div className="row item-center mb-4">
        <div className="col-sm-12 col-lg-10 mt-3">
          <Form onSubmit={onSubmitHandler}>
            <InputGroup>
              <FormControl
                placeholder="충전기에 부착된 QR번호를 입력해주세요"
                name="qrcode"
                className={isSearchChager === false ? "is-invalid" : ""}
              />
              <input type="hidden" name="isSearchChager" />
              <button type="submit" variant="btn btn-gray" className="btn btn-gray" id="button-addon2">
                검색
              </button>
              {!isSearchChager && <div className="invalid-feedback">유효하지 않은 큐알코드/충전기 입니다.</div>}
            </InputGroup>
          </Form>
          <div div="" className="col-sm-12">
            <small className="form-text text-muted">큐알코드 번호는 충전기에서 확인할 수 있습니다.</small>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="text-center">
          <p className="qr-text">
            <span className="text-evplug">충전왕 회원으로 가입하세요.</span>
          </p>
          <p>
            <b className="primary">훨씬</b> <span className="text-evplug">저렴한 요금</span>으로
            <br />
            이용 하실 수 있습니다.
          </p>
        </div>
        <div className="text-center">
          <Link to="/register" className="btn btn-main">
            회원가입
          </Link>
        </div>
      </div>
    </div>
  );
}

export default SearchQRcode;

import React, { useState } from "react";
import { Col, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import CheckBox from "../../components/CheckBox/CheckBox";
import InputBox from "../../components/InputBox/InputBox";
import DefaultModal from "../../components/Modal/DefaultModal";
import StipulationBlock from "../../components/StipulationBlock/StipulationBlock";
import AddressModal from "../../components/Modal/AddressModal";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { companyRegisterValidation } from "../../validations/Validation";
import { useDispatch } from "react-redux";
import { getCarList } from "../../_actions/car_action";
import { storeCompanyUser } from "../../_actions/user_action";

function RegisterCompanyPage(props) {
  const dispatch = useDispatch();
  const [stipulrationCheckList, setStipulrationCheckList] = useState([false, false]); // 약관
  const [allCheck, setAllCheck] = useState(false);
  const [isRegisterFormShow, setIsRegisterFormShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalAddressShow, setModalAddressShow] = useState(false);
  const [phone, setPhone] = useState("");
  const [isCertifyPhone, setIsCertifyPhone] = useState(false);
  const addressModal = (modal) => {
    setModalAddressShow([modalAddressShow]);
  };
  const navigate = useNavigate();

  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState();
  const [modalCloseText, setModalCloseText] = useState("");
  const [carCompany, setCarCompany] = useState([]);
  const [car, setCar] = useState([{ modelNm: "선택없음", id: "none" }]);

  const schema = companyRegisterValidation;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onClickCertifyPhoneModalHandler = (event) => {
    if (!modalShow) {
      setModalShow(true);
      setModalTitle("휴대전화 인증");
      setModalCloseText("등록");
      setModalContent("CertifyPhone");
    }
  };

  const onSubmit = (event) => {
    let formData = new FormData();
    formData.append("ty", "COMPANY");
    formData.append("lgnId", event.lgnId);
    formData.append("nm", event.nm);
    formData.append("phone", event.phone);
    formData.append("email", event.email);
    formData.append("lgnPassword", event.password);
    formData.append("adres", event.adres === "" || !event.adres ? "-" : event.adres);
    formData.append("rdnmadr", event.rdnmadr ?? "-");
    // formData.append("adresDetail", event.adresDetail === "" || !event.adresDetail ? "-" :  event.adresDetail);
    formData.append("zipCode", event.zipCode);
    formData.append("paycardNo", event.paycardNo);
    formData.append("paycardExp", event.paycardExp);
    formData.append("paycardBirth", event.paycardBirth);
    formData.append("paycardPw", event.paycardPw);
    formData.append("carNo", event.carNo.replace(/ /gi, ""));
    // formData.append("carModelId", car[event.carModelId].id);
    formData.append("carModelId", event.carModelId);
    formData.append("managerNm", event.managerNm);
    formData.append("managerCttpc", event.managerCttpc);
    formData.append("managerEmail", event.managerEmail);
    formData.append("coFax", event.coFax ?? null);
    formData.append("smsRcptnAgreYn", event.smsRcptnAgreYn ? "Y" : "N");
    formData.append("emlRcptnAgreYn", event.emlRcptnAgreYn ? "Y" : "N");

    if (event.adresDetail !== "") {
      formData.append("adresDetail", event.adresDetail);
    }
    if (event.file[0]) {
      formData.append("file", event.file[0]);
    }

    dispatch(storeCompanyUser(formData)).then((response) => {
      if (response.payload.result === "OK") {
        // let urlarr = window.location.href.split("/");
        // window.location.href = urlarr[0] + "/register/complete";
        navigate("/register/complete");
      } else {
        if (response.payload.msg === "exist") {
          if (response.payload.data.data === "lgnId") {
            alert("이미 가입되어 있는 아이디 입니다.");
          } else if (response.payload.data.data === "phone") {
            alert("이미 가입되어 있는 핸드폰 번호 입니다.");
          } else if (response.payload.data.data === "email") {
            alert("이미 가입되어 있는 이메일 입니다.");
          } else {
            alert("이미 가입되어 있는 " + response.payload.data.data + " 입니다.");
          }
        } else if (response.payload.msg === "paycard") {
          // 결제카드 등록실패시 해당 메세지 alert
          alert(response.payload.data);
        } else if (response.payload.data.validation) {
          alert("[" + response.payload.data.validation.error + "] " + response.payload.data.validation.msg);
        } else {
          alert("[페이지 오류 안내] 홈페이지 이용에 불편을 드려 죄송합니다. 새로고침 (F5키)을 누르시거나, 잠시 후에 이용해 주십시오.");
        }
      }
    });
  };

  const onCheckHandler = (index) => {
    switch (index.target.id) {
      case "policy":
        setStipulrationCheckList([!stipulrationCheckList[0], stipulrationCheckList[1]]);
        if (stipulrationCheckList[1]) {
          setAllCheck(true);
        }

        if (stipulrationCheckList[0] && allCheck) {
          setAllCheck(false);
        }
        break;
      case "privacy":
        setStipulrationCheckList([stipulrationCheckList[0], !stipulrationCheckList[1]]);
        if (stipulrationCheckList[0]) {
          setAllCheck(true);
        }

        if (stipulrationCheckList[1] && allCheck) {
          setAllCheck(false);
        }
        break;
      default:
        // 모두 동의하기 버튼 선택시
        setStipulrationCheckList([!allCheck, !allCheck, !allCheck]);
        setAllCheck(!allCheck);
        break;
    }
  };

  const onClickPrevhandler = (event) => {
    setIsRegisterFormShow(false);
  };

  const onChangeRegisterFormHandler = (event) => {
    if (allCheck) {
      dispatch(getCarList()).then((response) => {
        if (response.payload.result === "OK") {
          let tempList = [{ nm: "선택없음" }];
          for (var i = 0; i < response.payload.data.length; i++) {
            tempList.push({ nm: response.payload.data[i]["nm"] });
          }
          let setList = [...new Set(tempList.map(JSON.stringify))].map(JSON.parse);
          setCarCompany(setList);
        } else {
          alert("[페이지 오류 안내] 홈페이지 이용에 불편을 드려 죄송합니다. 새로고침 (F5키)을 누르시거나, 잠시 후에 이용해 주십시오.");
          //   let urlarr = window.location.href.split("/");
          //   window.location.href = urlarr[0] + "/" + urlarr[1];
          navigate("/");
        }
      });

      setIsRegisterFormShow(true);
    } else {
      alert("약관에 모두 동의해주셔야 가입이 가능합니다.");
    }
  };
  const onChangeCarCompanyHandler = (event) => {
    let tempList = [{ modelNm: "선택없음", id: "none" }];
    let companyName = event.target.value;
    if (event.target.value !== "선택없음") {
      dispatch(getCarList(event.target.value)).then((response) => {
        if (response.payload.result === "OK") {
          for (var i = 0; i < response.payload.data.length; i++) {
            tempList.push({ modelNm: response.payload.data[i].modelNm, id: response.payload.data[i].id });
          }
          // tempList.unshift({ modelNm: '선택없음',value : 'none' });
          let carInfoList = response.payload.data;
          let filterCarList = [{ modelNm: "선택없음", id: "none" }];
          carInfoList.map((value) => (value["nm"] === companyName ? filterCarList.push(value) : null));
          setCar(filterCarList);
        } else {
          alert("[페이지 오류 안내] 홈페이지 이용에 불편을 드려 죄송합니다. 새로고침 (F5키)을 누르시거나, 잠시 후에 이용해 주십시오.");
          //   let urlarr = window.location.href.split("/");
          //   window.location.href = urlarr[0] + "/" + urlarr[1];
          navigate("/");
        }
      });
    } else {
      setCar(tempList);
    }
  };
  const onCreditCardModalHandler = (event) => {
    if (!modalShow) {
      setModalShow(true);
      setModalTitle("신용카드 등록");
      setModalCloseText("등록");
      setModalContent("CreditCard");
    }
  };

  const onAddressModalHandler = (event) => {
    if (!modalAddressShow) {
      setModalAddressShow(true);
    }
  };
  return (
    <main className="join">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="text-center mt-5 mb-4">
              <h3 className="d-none d-lg-block">멤버십 가입</h3>
              <h5 className="d-block d-lg-none">멤버십 가입</h5>
            </div>

            <div className="row justify-content-center mb-5">
              <div className="col-auto">
                <div className="btn-group join-step btn-section">
                  <button
                    type="button"
                    className={"btn btn-outline-primary btn-rounded clikable btn-section-agree " + (!isRegisterFormShow && "active")}
                  >
                    약관동의
                  </button>
                  <button type="button" className={"btn btn-outline-primary clikable btn-section-input " + (isRegisterFormShow && "active")}>
                    회원정보입력
                  </button>
                  <button type="button" className="btn btn-outline-primary btn-rounded">
                    가입완료
                  </button>
                </div>
              </div>
            </div>

            <section className={"container section-agree " + (isRegisterFormShow && "hide")}>
              <hr className="hr-gray" />
              <div className="padding-if">
                <StipulationBlock
                  page="join"
                  title1="충전왕"
                  title2="이용약관"
                  ty="policy"
                  onCheck={onCheckHandler}
                  checked={stipulrationCheckList[0]}
                />
              </div>
              {/* <hr className="hr-gray"/>
                    <div className="padding-if">
                        <StipulationBlock page="join" title1="충전왕" title2="전자금융거래 이용약관" ty="policy-finance" onCheck={onCheckHandler} checked={stipulrationCheckList[1]}/>
                    </div> */}
              <hr className="hr-gray" />
              <div className="padding-if">
                <StipulationBlock
                  page="join"
                  title1="충전왕"
                  title2="개인정보 수집 및 이용 동의"
                  ty="privacy"
                  onCheck={onCheckHandler}
                  checked={stipulrationCheckList[1]}
                />
              </div>
              <hr className="hr-gray" />
              <div className="padding-if">
                <StipulationBlock page="join" ty="" onCheck={onCheckHandler} checked={allCheck} />
              </div>

              {/* <hr className="hr-gray d-block d-lg-none"/> */}
              <div className="navigator">
                <Link to="/register" className="prev float-left">
                  이전
                </Link>
                <Link to="" onClick={onChangeRegisterFormHandler} className="next float-right active btn-go-input-agree">
                  다음
                </Link>
                <div className="clearfix"></div>
              </div>
            </section>

            <section className={"section-form " + (!isRegisterFormShow && "hide")}>
              <Form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <div className="card mb-4">
                      <div className="card-body">
                        <InputBox
                          label="아이디"
                          name="lgnId"
                          placeholder="3~20글자 영문, 숫자를 입력해주세요."
                          required={true}
                          register={register("lgnId")}
                          errors={errors.lgnId?.message ? errors.lgnId?.message : null}
                        />
                        <hr className="hr-gray mt-0 mb-3" />
                        <InputBox
                          label="사업자명"
                          name="nm"
                          placeholder="상호, 법인명"
                          required={true}
                          register={register("nm")}
                          errors={errors.nm?.message ? errors.nm?.message : null}
                        />
                        <hr className="hr-gray mt-0 mb-3" />
                        {/* <InputBox label="휴대전화" name="phone" placeholder="숫자만 입력해주세요." required={true} register={register("phone")} errors={errors.phone?.message ? errors.phone?.message : null} /> */}
                        <InputBox
                          label="휴대전화"
                          name="phone"
                          value={phone}
                          placeholder="클릭하여 입력해주세요."
                          required={true}
                          register={register("phone")}
                          errors={errors.phone?.message ? errors.phone?.message : null}
                          onClickHandler={onClickCertifyPhoneModalHandler}
                          readonly
                          tailButtonLabelLength={2}
                          tailButtonLabel="인증"
                          tailButtonClick={onClickCertifyPhoneModalHandler}
                        />
                        <input type="hidden" name="certifyPhone" {...register("certifyPhone")} value={isCertifyPhone} />
                        <hr className="hr-gray mt-0 mb-3" />
                        <InputBox
                          label="사업자이메일"
                          name="email"
                          placeholder="이메일을 입력해주세요."
                          required={true}
                          register={register("email")}
                          errors={errors.email?.message ? errors.email?.message : null}
                        />
                        <hr className="hr-gray mt-0 mb-3" />
                        <InputBox
                          type="password"
                          label="비밀번호"
                          name="password"
                          placeholder="특수문자 포함 영문 8~20글자를 입력해주세요."
                          required={true}
                          register={register("password")}
                          errors={errors.password?.message ? errors.password?.message : null}
                        />
                        <hr className="hr-gray mt-0 mb-3" />
                        <InputBox
                          type="password"
                          label="비밀번호확인"
                          name="passwordConfirmation"
                          placeholder="비밀번호를 재입력해주세요."
                          required={true}
                          register={register("passwordConfirmation")}
                          errors={errors.passwordConfirmation?.message ? errors.passwordConfirmation?.message : null}
                        />
                        <hr className="hr-gray mt-0 mb-3" />
                        <InputBox
                          label="사업자주소"
                          name="rdnmadr"
                          placeholder="클릭하여 입력해주세요."
                          readonly={true}
                          required={true}
                          register={register("rdnmadr")}
                          errors={errors.rdnmadr?.message ? errors.rdnmadr?.message : null}
                          onClickHandler={onAddressModalHandler}
                        />
                        <input name="adres" type="hidden" {...register("adres")} />
                        <input name="zipCode" type="hidden" {...register("zipCode")} />
                        <hr className="hr-gray mt-0 mb-3" />
                        <InputBox
                          label=" "
                          name="adresDetail"
                          register={register("adresDetail")}
                          errors={errors.adresDetail?.message ? errors.adresDetail?.message : null}
                        />
                      </div>
                    </div>

                    <div className="card mb-4">
                      <div className="card-body">
                        <div className="form-group row">
                          <div className="col-sm-3">
                            <Form.Label>차량제조사</Form.Label>
                            <span className="text-danger"> *</span>
                          </div>
                          <div className="col-sm-9">
                            <Form.Select
                              key="carCompany"
                              name="carModelComapny"
                              className={errors.carModelComapny ? "is-invalid" : null}
                              {...register("carModelComapny", { value: "선택없음" })}
                              defaultValue={"선택없음"}
                              onChange={(e) => {
                                setValue("carModelComapny", e.target.value, {
                                  shouldValidate: true,
                                });
                                setValue("carModelId", "none", {});
                                onChangeCarCompanyHandler(e);
                              }}
                            >
                              {carCompany.map((comapny, key) => (
                                <option key={"comapnyNm_" + key} value={comapny.nm}>
                                  {comapny.nm}
                                </option>
                              ))}
                            </Form.Select>
                            <div className="invalid-feedback">{errors.carModelComapny?.message}</div>
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-sm-3">
                            <Form.Label>차량정보</Form.Label>
                            <span className="text-danger"> *</span>
                          </div>
                          <div className="col-sm-9">
                            <Form.Select
                              key="test"
                              className={errors.carModelId ? "is-invalid" : null}
                              name="carModelId"
                              {...register("carModelId", { value: "none" })}
                              defaultValue={"none"}
                              onChange={(e) => {
                                setValue("carModelId", e.target.value, {
                                  shouldValidate: true,
                                });
                              }}
                            >
                              {car.map((car, key) => (
                                <option key={"carModelId_" + key} value={car.id}>
                                  {car.modelNm}
                                </option>
                              ))}
                            </Form.Select>
                            <div className="invalid-feedback">{errors.carModelId?.message}</div>
                          </div>
                        </div>
                        <hr className="hr-gray mt-0" />
                        <div className="row">
                          <div className="col-sm-3">
                            <Form.Label>차량번호</Form.Label>
                            <span className="text-danger"> *</span>
                          </div>
                          <Col xs={9}>
                            <InputBox
                              label_length="0"
                              name="carNo"
                              register={register("carNo")}
                              placeholder="대표 차량번호를 입력해주세요."
                              errors={errors.carNo?.message ? errors.carNo?.message : null}
                            />
                          </Col>
                        </div>
                        {/* <InputBox label="차량번호" name="carNo" placeholder="대표 차량번호를 입력해주세요." required={true}/> */}
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-lg-6">
                    <div className="card mb-4">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-sm-3">
                            <Form.Label>결제카드정보</Form.Label>
                            <span className="text-danger"> *</span>
                          </div>
                          <Col xs={9}>
                            <InputBox
                              label_length="0"
                              name="hiddenPaycardNo"
                              placeholder="클릭하여 입력해주세요."
                              register={register("hiddenPaycardNo")}
                              errors={errors.hiddenPaycardNo?.message ? errors.hiddenPaycardNo?.message : null}
                              readonly={true}
                              required={true}
                              onClickHandler={onCreditCardModalHandler}
                            />
                            <input name="paycardNo" type="hidden" {...register("paycardNo")} />
                            <input name="paycardExp" type="hidden" {...register("paycardExp")} />
                            <input name="paycardBirth" type="hidden" {...register("paycardBirth")} />
                            <input name="paycardPw" type="hidden" {...register("paycardPw")} />
                          </Col>
                        </div>
                        {/* <InputBox label="결제카드정보" name="paycardNo" placeholder="클릭하여 입력해주세요." readonly={true} required={true} onClickHandler={onCreditCardModalHandler}/> */}
                      </div>
                    </div>

                    <div className="card mb-4">
                      <div className="card-body">
                        <InputBox
                          label="담당자명"
                          name="managerNm"
                          placeholder="담당자 이름을 입력해주세요."
                          required={true}
                          register={register("managerNm")}
                          errors={errors.managerNm?.message ? errors.managerNm?.message : null}
                        />
                        <InputBox
                          label="담당자이메일"
                          name="managerEmail"
                          placeholder="담당자 이메일을 입력해주세요."
                          required={true}
                          register={register("managerEmail")}
                          errors={errors.managerEmail?.message ? errors.managerEmail?.message : null}
                        />
                        <InputBox
                          label="담당자연락처"
                          name="managerCttpc"
                          placeholder="숫자만 입력해주세요."
                          required={true}
                          register={register("managerCttpc")}
                          errors={errors.managerCttpc?.message ? errors.managerCttpc?.message : null}
                        />
                        <InputBox
                          label="FAX"
                          name="coFax"
                          placeholder="숫자만 입력해주세요."
                          register={register("coFax")}
                          errors={errors.coFax?.message ? errors.coFax?.message : null}
                        />
                        <div className="form-group row">
                          <div className="col-sm-3">
                            <Form.Label>구비서류</Form.Label>
                          </div>
                          <div className="col-sm-9">
                            <input
                              type="file"
                              id="file"
                              name="file"
                              accept=".zip"
                              {...register("file")}
                              className={"form-control " + (errors.file?.message ? "is-invalid" : "")}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <b>혜택 및 광고성 정보 수신 동의 (선택)</b>
                    <p> </p>
                    <div className="card">
                      <div className="card-body">
                        <div className="flex">
                          <div className="col-6 check">
                            <CheckBox name="smsRcptnAgreYn" label="SMS 수신여부" register={register("smsRcptnAgreYn")} />
                          </div>
                          <div className="col-6">
                            <CheckBox name="emlRcptnAgreYn" label="이메일 수신여부" register={register("emlRcptnAgreYn")} />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="notice-member">
                      * 주의사항
                      <ul>
                        <li className="text-danger">
                          구비서류는 세금계산서 발행을 희망하시는 경우에만 업로드 해주세요. (압축된 <strong>500KB 미만의 .zip</strong> 파일)
                        </li>
                        <li>구비서류: 사업자등록증, 법인인감증명서, 위임장, 담당자 신분증 사본(주민번호 뒷자리를 꼭 가려주세요)</li>
                        <li>회원 가입 시 멤버십카드는 자동으로 발급되어 입력하신 주소로 발송됩니다.</li>
                        <li>
                          회원 가입 시 발급되는 멤버십카드 분실 또는 파손시 서비스 담당자를 통해 재발급 받으실 수 있고, 재발급 비용은 별도 청구됩니다.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="navigator">
                  <button type="button" onClick={onClickPrevhandler} className="btn prev float-left">
                    이전
                  </button>
                  {/* <Link to="/register" className="prev float-left">이전</Link> */}
                  <button type="submit" className="next btn float-right active btn-go-input-agree">
                    다음
                  </button>
                  <div className="clearfix"></div>
                </div>
              </Form>
            </section>
          </div>
        </div>
      </div>
      <AddressModal
        addressModal={addressModal}
        show={modalAddressShow}
        onHide={() => setModalAddressShow(false)}
        setModalAddressShow={setModalAddressShow}
        setValue={setValue}
      />
      <DefaultModal
        modalTitle={modalTitle}
        modalContent={modalContent}
        modalCloseText={modalCloseText}
        show={modalShow}
        onHide={() => setModalShow(false)}
        setPhone={setPhone}
        setIsCertifyPhone={setIsCertifyPhone}
        setValue={setValue}
      />
    </main>
  );
}

export default RegisterCompanyPage;

import {
    GET_LANDING_NOTICE_LIST
} from '../_actions/types';

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = {}, action) {
    switch (action.type) {
        case GET_LANDING_NOTICE_LIST:
            return { ...state, getLandingNoticeReqSuccess: action.payload}
        default:
            return state;
    }
}
import { combineReducers } from 'redux';
import user from './user_reducer';
import certify from './certify_reducer';
import article from './article_reducer';
import qr from './qr_reducer';
import { persistReducer } from 'redux-persist';     
// import storage from 'redux-persist/lib/storage';
// import storage from "redux-persist/lib/storage";
import storageSession from 'redux-persist/lib/storage/session';

//persist reducer redux state를 storage에 저장할 때 사용
//페이지를 새로 고침할 때 sessionStorage에 저장된 데이터는 사라지지 않습니다. 하지만 탭을 닫고 새로 열 때는 사라집니다.

const persistConfig = {
key: "root",
    storage: storageSession,
    // auth, certify, article 3개의 reducer 중에 user reducer만 sessionStorage에 저장
    // whitelist: ["user", "qr"]
    whitelist: ["user"]   //persist 지정할 모듈.
};

export const rootReducer = combineReducers({
    user,
    certify,
    article,
    qr
});

export default persistReducer(persistConfig, rootReducer);
import React from 'react';
import QRpay from '../Layouts/QRpay/QRpay';
import ChargingInfo from './Contents/ChargingInfo';

function QRpayChargingInfoPage() {
  return (
    <QRpay QRContent={<ChargingInfo />} />
  )
}

export default QRpayChargingInfoPage
import {
    CERTIFY_REQ_PHONE,
    CERTIFY_AUTH_CODE
} from './types';
import { apiPost} from '../api/api';

export function certifyReqPhone(data) {
    const request = apiPost("/api/v1/users/authCode", data)
    .then(response => response.data);

    return  {
        type: CERTIFY_REQ_PHONE,
        payload: request
    }
}

export function certifyAuthCode(data) {
    const request = apiPost("/api/v1/users/checkCode", data)
    .then(response => response.data);

    return  {
        type: CERTIFY_AUTH_CODE,
        payload: request
    }
}


import {
    LOGIN_USER,
    LOGOUT_USER,
    REGISTER_USER,
    AUTH_USER,
    STIPULATION_AGREE_USER
} from '../_actions/types';

const initialState = {
    loginReqSuccess: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
        case LOGIN_USER:
            if (action.payload.result === "OK") {
                return { ...state, loginReqSuccess: action.payload}
            } else {
                return { ...state}
            }
            // return { ...state, loginReqSuccess: true}
        case LOGOUT_USER:
            return { ...state, loginReqSuccess: action.payload}
        case REGISTER_USER:
            return { ...state, register: action.payload}
        case AUTH_USER:
            return { ...state, userData: action.payload}
        case STIPULATION_AGREE_USER:
            return { ...state, agreeData: action.payload}
        default:
            return state;
    }
}
import React from 'react';
import { UtctoLocaleDateTimeFormmatter } from '../../../utils/Utils';

function Charging(props) {
    const stationNm = props.stationNm ?? "-";
    const chrgrIdntfr = props.chrgrIdntfr ?? "-";
    const beginDt = props.beginDt ? UtctoLocaleDateTimeFormmatter(props.beginDt) : "-";
    const endDt = props.endDt ? UtctoLocaleDateTimeFormmatter(props.endDt) : "-";
    const elctcQy = props.elctcQy ?? "-";
    const elctcPc = props.elctcPc ?? "-";
    let paySttus = props.paySttus ?? "-";

    switch(paySttus) {
        case 'READY':
            paySttus="결제대기";
            break;
        case 'SUCCESS':
            paySttus="결제성공";
            break;
        case 'EXCEPT':
            paySttus="결제예외";
            break;
        case 'FAIL':
            paySttus="결제실패";
            break;
        case 'ALL_CANCEL':
            paySttus="전체취소";
            break;
        case 'ACCOUNT':
            paySttus="계좌입금";
            break;
        case 'ADMIN_CANCEL':
            paySttus="관리자취소";
            break;
        case 'TAX':
            paySttus="세금계산서";
            break;
        case 'CANCEL_FAIL':
            paySttus="취소실패";
            break;
        case 'CANCEL_READY':
            paySttus="취소대기";
            break;
        default :
            paySttus="알수없음";
            break;
    }

    return (
        <div className="list">
            <div className="a">
                <div className="list-subject row text-center verticle-center">
                    <div className="list-title col-2 col-sm-2">
                        {stationNm}
                    </div>
                    <div className="list-date col-2 col-sm-2">
                        {chrgrIdntfr}
                    </div>
                    <div className="list-date col-3 col-sm-3">
                        {beginDt} ~  {endDt}
                    </div>
                    <div className="list-date col-2 col-sm-2">
                        {elctcQy}
                    </div>
                    <div className="list-date col-1 col-sm-1">
                        {parseInt(elctcPc)}
                    </div>
                    <div className="list-date col-2 col-sm-2">
                        {paySttus}
                    </div>
                </div>
            </div>
        </div>
    )
}




export default Charging
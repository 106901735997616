import React from 'react';

function QRpay(props) {
    const QRContent = props.QRContent;

    return (
        <main className="qrpay">
           <div className="qrpay-guide pb-5">
               <div className="container margin-container item-center">
                   <div className="top-box">
                       <div className="text-center mb-4">
                           <h3 className="d-none d-lg-block">QR번호를 입력해주세요</h3>
                           <h5 className="d-block d-lg-none">QR번호를 입력해주세요</h5>
                       </div>
                       {QRContent}
                   </div>
               </div>
           </div>
        </main>
    )
}

export default QRpay
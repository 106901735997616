import {
    GET_STIPULATION
} from './types';
import { apiGet } from '../api/api';

export function getStipulation(nm) {
    const request = apiGet("/api/v1/stipulations/"+ nm)
    .then(response => response.data);

    return  {
        type: GET_STIPULATION,
        payload: request
    }
}

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import promiseMiddleware from 'redux-promise';
import ReduxThunk from 'redux-thunk';
import NavBar from './views/Layouts/NavBar';
import Footer from './views/Layouts/Footer';
import ScrollTop from './components/ScrollTop/ScrollTop';
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import persistedReducer from "./_reducers/index";

//redux-thunk 는 리덕스가 비동기 액션을 디스패치 하도록 도와주는 역할을 한다
//redux-thunk라는 미들웨어는 API 호출이 필요할 때 쓴다.(async, await)
//redux thunk는 안쓰인것 같다.
// 액션 객체가 아닌 함수를 디스패치할 수 있다 => 함수를 디스패치하는 예시가 생각이 안남.
// redux-promise를 사용함
//리덕스의 store는 페이지를 새로고침 할 경우 state가 날아가는 것을 볼수 있다.
//이것에 대한 대응 방안으로 localStorage 또는 session에 저장하고자 하는 reducer state를 저장하여, 
//새로고침 하여도 저장공간에 있는 데이터를 redux에 불러오는 형식으로 이루어진다.
//위에서 말한 이 작동을 위해 redux-persist를 사용합니다.
const store = createStore(persistedReducer, applyMiddleware(promiseMiddleware, ReduxThunk));
//persistStore : 새로 고침, 종료해도 지속될 store 생성
const persistor = persistStore(store);

ReactDOM.render(
  // <React.StrictMode>
    // <Provider 
    //   store={createStoreWithMiddleware(Reducer,
    //     window.__REDUX_DEVTOOLS_EXTENSION__ &&
    //     window.__REDUX_DEVTOOLS_EXTENSION__()
    //   )}>
    // <Provider 
    //   store={createStoreWithMiddleware(store,
    //     window.__REDUX_DEVTOOLS_EXTENSION__ &&
    //     window.__REDUX_DEVTOOLS_EXTENSION__()
    //   )}>

    //react로 개발하는 경우 App 컴포넌트를 PersistGate로 매핑
    //persist store가 redux에 저장될 때까지 앱 UI 렌더링이 지연된다.
      <Provider
        store={store}
      > 
        <PersistGate persistor={persistor}>
          <BrowserRouter>
            <ScrollTop />
            <NavBar />
            <App />
            <Footer/>
          </BrowserRouter>
        </PersistGate>
      </Provider>,
  // </React.StrictMode>,
  
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import React from 'react';
import Pagination from 'react-bootstrap/Pagination';

function Page(props) {
  let items = [];
  const { currentPage, totalPage, onClickPageChange} = props;

  for (let number = 1; number <= totalPage; number++) {
    items.push(
      <Pagination.Item key={number} active={number === parseInt(currentPage)} onClick={() => onClickPageChange(number)}>
        {number}
      </Pagination.Item>,
    );
  }

  return (
    <div className="mt-3 item-center">
        <Pagination>{items}</Pagination>
    </div>
  )
}

export default Page
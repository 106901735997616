import { useEffect } from 'react'
import { useLocation } from 'react-router';

export default  function ScrollTop() {
    const { pathname } = useLocation();
    //사용자가 현재 머물러있는 페이지에 대한 정보를 알려주는 hook

    //페이지 이동할때마다 scroll이 가장 상위에 있도록 해줌.
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
  
    return null;
}

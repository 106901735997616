import React, { useEffect, useLayoutEffect, useState } from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { getChargerToQr } from "../../../_actions/qr_action";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";

function ShowInfo(props) {
  const dispatch = useDispatch();
  const { qrcode } = useParams();
  let navigate = useNavigate();
  let [searchQrcode, setSearchQrcode] = useState(qrcode);
  let [charger, setCharger] = useState({});
  useLayoutEffect(() => {
    let params = {
      qrcode: qrcode,
    };

    dispatch(getChargerToQr(params)).then((response) => {
      if (response.payload.result === "OK") {
        // 조회 성공
        setCharger(response.payload.data);
      } else {
        alert(
          "[페이지 오류 안내] 홈페이지 이용에 불편을 드려 죄송합니다. 새로고침 (F5키)을 누르시거나, 잠시 후에 이용해 주십시오."
        );
        // let urlarr = window.location.href.split("/");
        // window.location.href=urlarr[0] + "/QRpay";
        navigate("/QRpay");
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let params = {
      qrcode: qrcode,
    };
    dispatch(getChargerToQr(params)).then((response) => {
      if (response.payload.result === "OK") {
        // 조회 성공
        setCharger(response.payload.data);
      } else {
        if (response.payload.data.exist === false) {
          //   let urlarr = window.location.href.split("/");
          //   window.location.href = urlarr[0] + "/QRpay?qrcode=" + qrcode;
          navigate(`/QRpay?qrcode=${qrcode}`);
        } else {
          alert(
            "[페이지 오류 안내] 홈페이지 이용에 불편을 드려 죄송합니다. 새로고침 (F5키)을 누르시거나, 잠시 후에 이용해 주십시오."
          );
          //   let urlarr = window.location.href.split("/");
          //   window.location.href = urlarr[0] + "/QRpay";
          navigate("/QRpay");
        }
      }
    });
  }, [dispatch, qrcode]);

  const onClickQRSearchHandler = () => {
    let path = "/QRpay/" + searchQrcode;
    navigate(path);
  };

  const onChangeQRcodeHandler = (event) => {
    setSearchQrcode(event.currentTarget.value);
  };

  const onClickPayHandler = (event) => {
    if (window.confirm("충전기 정보를 충분히 확인하였습니까?")) {
      navigate("charging_info", {
        state: { qrcode: qrcode, charger: charger },
      });

      // navigate.push({
      //     pathname:"/charging_info",
      //     state: {
      //         data: qrcode
      //     }
      // });
    }
  };

  const onKeyDownHandler = (event) => {
    if (event.key === "Enter") {
      onClickQRSearchHandler();
    }
  };

  const ing_div = (
    <div>
      <label className="col-sm-1 col-1 col-form-label">
        <div className="orange-circle"></div>
      </label>
      <span className="col-11 col-form-label">사용중</span>
    </div>
  );

  const ready_div = (
    <div>
      <label className="col-sm-1 col-1 col-form-label">
        <div className="green-circle"></div>
      </label>
      <span className="col-11 col-form-label">사용가능</span>
    </div>
  );

  const no_div = (
    <div>
      <span className="col-11 col-form-label text-danger">
        현재 사용할 수 없는 충전기입니다.
      </span>
    </div>
  );

  return (
    Object.keys(charger).length !== 0 && (
      <div>
        <div className="text-center mb-3">
          <small className="text-muted">* 충전소 정보를 확인해 주세요</small>
        </div>
        {
          // 01: 운영 , 02: 시범운영 모드일 때, 상태체크
          charger.chargerMode === "01" || charger.chargerMode === "02" ? (
            charger.sttus === "AA" || charger.sttus === "AB" ? (
              <button
                type="button"
                className="btn btn-main rounded-pill mb-2 pay"
                onClick={onClickPayHandler}
              >
                결제하기
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-main rounded-pill mb-2 pay"
                onClick={onClickPayHandler}
                disabled
              >
                결제하기
              </button>
            )
          ) : (
            <button
              type="button"
              className="btn btn-main rounded-pill mb-2 pay"
              onClick={onClickPayHandler}
              disabled
            >
              결제하기
            </button>
          )
        }
        <div className="mb-2">
          <div className="row">
            {
              // 01: 운영 , 02: 시범운영 모드일 때, 상태체크
              charger.chargerMode === "01" || charger.chargerMode === "02"
                ? {
                    // 기존 DB
                    // 'NONE': no_div,
                    // 'PREPARING': ready_div,
                    // 'AVAILABLE': ready_div,
                    // 'CHARGING': ing_div,
                    // 'FINISHING':ing_div,
                    // 'CHARGE_END': ing_div,
                    // 'SUSPENDEDEVSE': ing_div,
                    // 'SUSPENDEDEV': ing_div,
                    // 'RESERVED': no_div,
                    // 'UNAVAILABLE': no_div,
                    // 'FAULTED': no_div,
                    // 'DISCONNECT': no_div,
                    // 'UNKNOWN': no_div

                    AA: ready_div,
                    AB: ready_div,
                    AC: ing_div,
                    AD: ing_div,
                    AL: ing_div,
                    AE: ing_div,
                    AF: ing_div,
                    AG: no_div,
                    AH: no_div,
                    AI: no_div,
                    AJ: no_div,
                    AK: no_div,
                  }[charger.sttus]
                : no_div
            }
          </div>
          <div className="row">
            <label className="col-sm-3 col-5 col-form-label">
              큐알코드 번호
            </label>
            <div className="col-sm-9 col-7">
              <label className="col-form-label">{charger.qrcode}</label>
            </div>
          </div>
          <div className="row">
            <label className="col-sm-3 col-5 col-form-label">충전소명</label>
            <div className="col-sm-9 col-7">
              <label className="col-form-label">{charger.stationNm}</label>
            </div>
          </div>
          <div className="row">
            <label className="col-sm-3 col-5 col-form-label">충전소 주소</label>
            <div className="col-sm-9 col-7">
              <label className="col-form-label">
                {charger.stationRdnmadr
                  ? charger.stationRdnmadr
                  : charger.stationAdres ?? "-"}
              </label>
            </div>
          </div>
          <div className="row">
            <label className="col-sm-3 col-5 col-form-label">충전기 종류</label>
            <div className="col-sm-9 col-7">
              <label className="col-form-label">
                {
                  {
                    // 기존 DB
                    // "00":"알수없음",
                    // "01":"AC완속",
                    // "02":"DC콤보x1",
                    // "05":"DC콤보+차데모",
                    // "08":"DC콤보+차데모+AC3",
                    // "0E":"DC콤보x2",
                    // "0F":"콘센트(220v)"

                    AA: "플러그없음",
                    AB: "AC완속",
                    AC: "DC콤보X1",
                    AD: "DC차데모",
                    AE: "AC3",
                    AF: "DC콤보+DC차데모",
                    AG: "DC콤보+AC3",
                    AH: "DC차데모+AC3",
                    AI: "DC콤보+DC차데모+AC3",
                    AJ: "무선 충전",
                    AK: "DC급속(버스)",
                    AL: "AC급속(버스)",
                    AM: "급속(이륜차, e-mobility)",
                    AN: "완속(이륜차, e-mobility)",
                    AO: "콘센트(콘센트)",
                    AP: "DC콤보X2",
                  }[charger.plugTy]
                }
              </label>
            </div>
          </div>
          <div className="row">
            <label className="col-sm-3 col-5 col-form-label">
              이용가능 시간
            </label>
            <div className="col-sm-9 col-7">
              <label className="col-form-label">{charger.ormpm}</label>
            </div>
          </div>
          <div className="row">
            <label className="col-sm-3 col-5 col-form-label">서비스 기관</label>
            <div className="col-sm-9 col-7">
              <label className="col-form-label">충전왕</label>
            </div>
          </div>
          <div className="row">
            <label className="col-sm-3 col-5 col-form-label">요금</label>
            <div className="col-sm-9 col-7">
              <label className="col-form-label">{charger.price} 원/kWh</label>
            </div>
          </div>
        </div>

        <div className="row item-center mb-4">
          <div div="" className="col-sm-12">
            <small className="form-text text-muted">
              * 충전소 정보가 다른 경우 번호를 재검색해 주세요
            </small>
          </div>
          <div className="col-sm-12 col-lg-12 mt-3">
            <InputGroup>
              <FormControl
                placeholder="다른 큐알코드 번호로 검색"
                name="qrcode"
                onChange={onChangeQRcodeHandler}
                onKeyDown={onKeyDownHandler}
              />
              <button
                variant="btn btn-gray"
                className="btn btn-gray"
                id="button-addon2"
                onClick={onClickQRSearchHandler}
              >
                검색
              </button>
            </InputGroup>
          </div>
        </div>
      </div>
    )
  );
}

export default ShowInfo;

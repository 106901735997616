import React, { useLayoutEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getStipulation } from "../../_actions/stipulation_action";
import { useNavigate } from "react-router-dom";

function StipulationPage(props) {
  const ty = props.ty;
  const dispatch = useDispatch();
  const [content, setContent] = useState("");
  const navigate = useNavigate();

  useLayoutEffect(() => {
    if (ty === "policy") {
      dispatch(getStipulation("이용약관")).then((response) => {
        if (response.payload.result === "OK") {
          if (response.payload.data.cn) {
            setContent(response.payload.data.cn);
          } else {
            alert(
              "[페이지 오류 안내] 홈페이지 이용에 불편을 드려 죄송합니다. 새로고침 (F5키)을 누르시거나, 잠시 후에 이용해 주십시오."
            );
            // let urlarr = window.location.href.split("/");
            // window.location.href = urlarr[0] + "/";
            navigate("/");
          }
        } else {
          // 실패 처리
          alert(
            "[페이지 오류 안내] 홈페이지 이용에 불편을 드려 죄송합니다. 새로고침 (F5키)을 누르시거나, 잠시 후에 이용해 주십시오."
          );
          // let urlarr = window.location.href.split("/");
          // window.location.href = urlarr[0] + "/";
          navigate("/");
        }
      });
    } else if (ty === "privacy") {
      dispatch(getStipulation("개인정보처리방침")).then((response) => {
        if (response.payload.result === "OK") {
          if (response.payload.data.cn) {
            setContent(response.payload.data.cn);
          } else {
            alert(
              "[페이지 오류 안내] 홈페이지 이용에 불편을 드려 죄송합니다. 새로고침 (F5키)을 누르시거나, 잠시 후에 이용해 주십시오."
            );
            // let urlarr = window.location.href.split("/");
            // window.location.href = urlarr[0] + "/";
            navigate("/");
          }
        } else {
          // 실패 처리
          alert(
            "[페이지 오류 안내] 홈페이지 이용에 불편을 드려 죄송합니다. 새로고침 (F5키)을 누르시거나, 잠시 후에 이용해 주십시오."
          );
          // let urlarr = window.location.href.split("/");
          // window.location.href = urlarr[0] + "/";
          navigate("/");
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="common-index">
      <div className="container">
        <section className="stipulation">
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
        </section>
      </div>
    </main>
  );
}

export default StipulationPage;

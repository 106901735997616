import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({children}) => {
    // 로그인 여부 확인 후 이동
    const [user] = useState(useSelector(state => state.user));
    const authenticated = !user.loginReqSuccess ? user.loginReqSuccess : true;
    return authenticated ? children : <Navigate to="/login" />;
}

export default PrivateRoute;
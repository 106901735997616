import axios from "axios";

const apiUtil = axios.create({
  // dev
  //baseURL: 'https://api-dev.evplug.co.kr',
  //baseURL: 'https://enlighten-user-api-dev.evcloud.co.kr',
  baseURL: process.env.REACT_APP_API_URL,
  // release
  //baseURL: 'https://api.evplug.co.kr',
  timeout: 5000,
  headers: { "Content-Type": "application/json" },
  // headers: {"Content-Type": 'multipart/form-data'}
});

export const apiFile = axios.create({
  // dev
  //baseURL: 'https://api-dev.evplug.co.kr',
  // release
  //baseURL: 'https://enlighten-user-api-dev.evcloud.co.kr',
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 5000,
  headers: { "Content-Type": "multipart/form-data" },
  // headers: {"Content-Type": 'multipart/form-data'}
});

// apiUtil.interceptors.request.use(
//     function (config) {
//     	//request 정상
//         return config;
//     },
//     function (error) {
//     	//request 에러
//         return Promise.reject(error);
//     }
// )

// apiUtil.interceptors.response.use(
//     function (response) {
//     	//response 정상
//         return response;
//     },
//     function (error) {
//     	//response 에러
//         return Promise.reject(error);
//     }
// );

export default apiUtil;

/* eslint-disable no-useless-escape */
import * as yup from "yup";

// const lgnId = "아이디";
// const nm = "이름";
// const email = "이메일";
// const password = "비밀번호";

const requiredMessage = "필수 입력입니다.";
const emailTypeMessage = "이메일 형식이 올바르지 않습니다.";

// 로그인 Validation
export const loginValidation = yup
  .object({
    lgnId: yup.string().required(requiredMessage),
    lgnPassword: yup.string().required(requiredMessage),
  })
  .required();

// 아이디 찾기 Validation
export const findIdValidation = yup
  .object({
    email: yup
      .string()
      .required(requiredMessage)
      .matches(/^([\w\.\_\-]*[a-zA-Z0-9])+([-_]?[a-zA-Z0-9])+@[a-zA-Z0-9]+([-_]?[a-zA-Z0-9]+\.)+[a-zA-Z0-9]{2,8}$/, emailTypeMessage),
  })
  .required();

// 비밀번호 찾기 Validation
export const findPasswordValidation = yup
  .object({
    // 숫자만
    // ex 021234567 => 9글자
    // ex 01012345678 => 11글자
    phone: yup
      .string()
      .required(requiredMessage)
      .matches(/^[0-9]{9,11}$/, "9~11글자의 핸드폰 번호(숫자만)를 입력해주세요."),
  })
  .required();

// 인증번호 확인 Validation
export const certifyAuthCodeValidation = yup
  .object({
    authCode: yup
      .string()
      .required(requiredMessage)
      .matches(/^.{6}$/, "6자리로 입력해주세요."),
  })
  .required();

// 개인회원정보 수정 Validation
export const updateMemberUserValidation = yup
  .object({
    // 숫자만
    // ex 021234567 => 9글자
    // ex 01012345678 => 11글자
    phone: yup
      .string()
      .required(requiredMessage)
      .matches(/^[0-9]{9,11}$/, "9~11글자의 핸드폰 번호(숫자만)를 입력해주세요."),
    email: yup
      .string()
      .required(requiredMessage)
      // eslint-disable-next-line no-useless-escape
      .matches(/^([\w\.\_\-]*[a-zA-Z0-9])+([-_]?[a-zA-Z0-9])+@[a-zA-Z0-9]+([-_]?[a-zA-Z0-9]+\.)+[a-zA-Z0-9]{2,8}$/, emailTypeMessage)
      .max(255, "255자리 이하로 입력해주세요."),
    rdnmadr: yup.string().required(requiredMessage).max(255, "255자리 이하로 입력해주세요."),
    carNo: yup
      .string()
      .matches(/^([가-힣]{2})?[0-9]{2,3}[가-힣]{1}\s?\d{4}$/, "올바르지 않은 차량번호 입니다.")
      .required(requiredMessage),
    carModelComapny: yup
      .string()
      .required(requiredMessage)
      .matches(/^(?!선택없음)[a-zA-Z가-힣]+$/, "필수 선택 입니다."),
    carModelId: yup
      .string()
      .required(requiredMessage)
      .matches(/^(?!none)\d+$/, "필수 선택 입니다."),
  })
  .required();

// 법인회원정보 수정 Validation
export const updateCompanyUserValidation = yup
  .object({
    // 숫자만
    // ex 021234567 => 9글자
    // ex 01012345678 => 11글자
    phone: yup
      .string()
      // .matches(/^[0-9]*$/, "숫자만 입력해주세요.")
      // .max(13, "13자리 이하로 입력해주세요.")
      .required(requiredMessage)
      .matches(/^[0-9]{9,11}$/, "9~11글자의 핸드폰 번호(숫자만)를 입력해주세요."),
    email: yup
      .string()
      .required(requiredMessage)
      .matches(/^([\w\.\_\-]*[a-zA-Z0-9])+([-_]?[a-zA-Z0-9])+@[a-zA-Z0-9]+([-_]?[a-zA-Z0-9]+\.)+[a-zA-Z0-9]{2,8}$/, emailTypeMessage)
      .max(255, "255자리 이하로 입력해주세요."),
    rdnmadr: yup.string().required(requiredMessage).max(255, "255자리 이하로 입력해주세요."),
    carNo: yup
      .string()
      .matches(/^([가-힣]{2})?[0-9]{2,3}[가-힣]{1}\s?\d{4}$/, "올바르지 않은 차량번호 입니다.")
      .required(requiredMessage),
    managerNm: yup.string().required(requiredMessage).max(100, "100자리 이하로 입력해주세요."),
    managerEmail: yup
      .string()
      .required(requiredMessage)
      .matches(/^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i, emailTypeMessage)
      .max(255, "255자리 이하로 입력해주세요."),
    managerCttpc: yup
      .string()
      .matches(/^[0-9]{9,11}$/, "9~11글자의 연락처(숫자만)를 입력해주세요.")
      .required(requiredMessage),
    carModelComapny: yup
      .string()
      .required(requiredMessage)
      .matches(/^(?!선택없음)[a-zA-Z가-힣]+$/, "필수 선택 입니다."),
    carModelId: yup
      .string()
      .required(requiredMessage)
      .matches(/^(?!none)\d+$/, "필수 선택 입니다."),

    file: yup.mixed().test(
      "fileSize",
      "500KB 이하의 파일을 등록해주세요.",
      // value => value && value.size <= 0.3
      (value) => {
        if (value[0]) {
          return value[0].size <= 512000;
        } else {
          return true;
        }
      }
    ),
  })
  .required();

// 개인 회원 가입 Validation
export const memberRegisterValidation = yup
  .object({
    lgnId: yup
      .string()
      .required(requiredMessage)
      .matches(/^[a-z0-9]{3,20}$/, "3~20글자 영문, 숫자를 입력해주세요."),
    nm: yup
      .string()
      .required(requiredMessage)
      .matches(/^[a-zA-Zㄱ-힣0-9]*$/, "특수문자는 입력이 불가능합니다.")
      .max(100, "100자리 이하로 입력해주세요."),
    // 숫자만
    // ex 021234567 => 9글자
    // ex 01012345678 => 11글자
    phone: yup
      .string()
      .required(requiredMessage)
      .matches(/^[0-9]{9,11}$/, "9~11글자의 핸드폰 번호(숫자만)를 입력해주세요."),
    email: yup
      .string()
      .required(requiredMessage)
      .matches(/^([\w\.\_\-]*[a-zA-Z0-9])+([-_]?[a-zA-Z0-9])+@[a-zA-Z0-9]+([-_]?[a-zA-Z0-9]+\.)+[a-zA-Z0-9]{2,8}$/, emailTypeMessage)
      .max(255, "255자리 이하로 입력해주세요."),
    password: yup
      .string()
      .required(requiredMessage)
      .matches(/^(?=.*\W)[a-zA-Z0-9\W]{8,20}$/, "특수문자 포함 영문 8~20글자를 입력해주세요."),
    passwordConfirmation: yup
      .string()
      .required(requiredMessage)
      .oneOf([yup.ref("password"), null], "비밀번호가 일치하지 않습니다."),
    rdnmadr: yup.string().required(requiredMessage).max(255, "255자리 이하로 입력해주세요."),
    adres_detail: yup.string().max(255, "255자리 이하로 입력해주세요."),
    carNo: yup
      .string()
      .required(requiredMessage)
      .matches(/^([가-힣]{2})?[0-9]{2,3}[가-힣]{1}\s?\d{4}$/, "올바르지 않은 차량번호 입니다."),
    hiddenPaycardNo: yup.string().required(requiredMessage),
    carModelComapny: yup
      .string()
      .required(requiredMessage)
      .matches(/^(?!선택없음)[a-zA-Z가-힣]+$/, "필수 선택 입니다."),
    carModelId: yup
      .string()
      .required(requiredMessage)
      .matches(/^(?!none)\d+$/, "필수 선택 입니다."),
  })
  .required();

// 법인 회원 가입 Validation
export const companyRegisterValidation = yup
  .object({
    lgnId: yup
      .string()
      .required(requiredMessage)
      .matches(/^[a-z0-9]{3,20}$/, "3~20글자 영문, 숫자를 입력해주세요."),
    nm: yup
      .string()
      .required(requiredMessage)
      .matches(/^[a-zA-Zㄱ-힣0-9]*$/, "특수문자는 입력이 불가능합니다.")
      .max(100, "100자리 이하로 입력해주세요."),
    // 숫자만
    // ex 021234567 => 9글자
    // ex 01012345678 => 11글자
    phone: yup
      .string()
      .required(requiredMessage)
      .matches(/^[0-9]{9,11}$/, "9~11글자의 핸드폰 번호(숫자만)를 입력해주세요."),
    email: yup
      .string()
      .required(requiredMessage)
      .matches(/^([\w\.\_\-]*[a-zA-Z0-9])+([-_]?[a-zA-Z0-9])+@[a-zA-Z0-9]+([-_]?[a-zA-Z0-9]+\.)+[a-zA-Z0-9]{2,8}$/, emailTypeMessage)
      .max(255, "255자리 이하로 입력해주세요."),
    password: yup
      .string()
      .required(requiredMessage)
      .matches(/^(?=.*\W)[a-zA-Z0-9\W]{8,20}$/, "특수문자 포함 영문 8~20글자를 입력해주세요."),
    passwordConfirmation: yup
      .string()
      .required(requiredMessage)
      .oneOf([yup.ref("password"), null], "비밀번호가 일치하지 않습니다."),
    rdnmadr: yup.string().required(requiredMessage).max(255, "255자리 이하로 입력해주세요."),
    adresDetail: yup.string().max(255, "255자리 이하로 입력해주세요."),
    carNo: yup
      .string()
      .required(requiredMessage)
      .matches(/^([가-힣]{2})?[0-9]{2,3}[가-힣]{1}\s?\d{4}$/, "올바르지 않은 차량번호 입니다."),
    hiddenPaycardNo: yup.string().required(requiredMessage),
    managerNm: yup.string().required(requiredMessage).max(100, "100자리 이하로 입력해주세요."),
    managerEmail: yup
      .string()
      .required(requiredMessage)
      .matches(/^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i, emailTypeMessage)
      .max(255, "255자리 이하로 입력해주세요."),
    // 숫자만
    // ex 021234567 => 9글자
    // ex 01012345678 => 11글자
    managerCttpc: yup
      .string()
      .required(requiredMessage)
      .matches(/^[0-9]{9,11}$/, "9~11글자의 핸드폰 번호(숫자만)를 입력해주세요."),
    coFax: yup
      .string()
      .matches(/^[0-9]*$/, "숫자만 입력해주세요.")
      .max(13, "13자리 이하로 입력해주세요."),
    file: yup
      .mixed()
      // .required(requiredMessage)
      .test(
        "fileSize",
        "500KB 이하의 파일을 등록해주세요.",
        // value => value && value.size <= 0.3
        (value) => {
          if (value[0]) {
            return value[0].size <= 512000;
          } else {
            return true;
          }
        }
      ),
    carModelComapny: yup
      .string()
      .required(requiredMessage)
      .matches(/^(?!선택없음)[a-zA-Z가-힣]+$/, "필수 선택 입니다."),
    carModelId: yup
      .string()
      .required(requiredMessage)
      .matches(/^(?!none)\d+$/, "필수 선택 입니다."),
  })
  .required();

// 비밀번호 변경 Validation
export const updatePasswordValidation = yup
  .object({
    password: yup
      .string()
      .required(requiredMessage)
      .matches(/^(?=.*\W)[a-zA-Z0-9\W]{8,20}$/, "특수문자 포함 영문 8~20글자를 입력해주세요."),
    passwordConfirmation: yup
      .string()
      .required(requiredMessage)
      .oneOf([yup.ref("password"), null], "비밀번호가 일치하지 않습니다."),
  })
  .required();

// // QR코드 검색 Validation
// export const searchQRValidation = yup.object({
//     // qrcode: yup
//     //     .string()
//     //     .required(requiredMessage),
//     //     // .oneOf([true], "유효하지 않은 큐알코드 번호입니다.")
//     //     // .matches(/^[0-9]$/, "숫자를 입력해주세요."),
//     // isSearchChager: yup
//     //     .string()
//     //     .when(['qrcode'], (qrcode , schema) => {
//     //         if (qrcode) {
//     //             return schema.oneOf([true], "유효하지 않은 큐알코드 번호입니다.")
//     //         }
//     //         // return qrcode ? schema.oneOf([true], "sds");
//     //         // return num1 > 0 && num2 > 0 ? schema.max(num1 / num2) : schema.max(0);
//     //     }),
//     isSearchChager: yup
//     .string()
//     .oneOf([true], )
//         // .oneOf([])
// }).required();

// QR결제 충전내역 입력 Validation
export const QRChargingInfo = yup
  .object({
    phone: yup
      .string()
      .required(requiredMessage)
      .matches(/^[0-9-]{10,13}$/, "10~13글자의 핸드폰 번호를 입력해주세요."),
    kwhPay: yup.string().nullable().oneOf(["3000", "5000", "10000", "20000", "30000"], requiredMessage),
  })
  .required();

// QR결제 결제카드 입력 Validation
export const qrpayPaycardValidation = yup
  .object({
    paycardNo: yup.string().required(requiredMessage).max(100, "100자리 이하로 입력해주세요."),
    paycardExp: yup
      .string()
      .required(requiredMessage)
      .test("month", "유효하지 않은 값입니다.", (value) => {
        let month = value.split("-")[0];
        if (1 <= parseInt(month) && parseInt(month) < 13) {
          return true;
        } else {
          return false;
        }
      }),
    paycardBirth: yup.string().required(requiredMessage).max(10, "10자리 이하로 입력해주세요."),
    paycardPw: yup.string().required(requiredMessage).max(2, "2자리 이하로 입력해주세요."),
  })
  .required();

// 결제카드 변경 Validation
export const updatePaycardValidation = yup
  .object({
    paycardNo: yup.string().required(requiredMessage).max(100, "100자리 이하로 입력해주세요."),
    paycardExp: yup
      .string()
      .required(requiredMessage)
      .test("month", "유효하지 않은 값입니다.", (value) => {
        let month = value.split("-")[0];
        if (1 <= parseInt(month) && parseInt(month) < 13) {
          return true;
        } else {
          return false;
        }
      }),
    paycardBirth: yup.string().required(requiredMessage).max(10, "10자리 이하로 입력해주세요."),
    paycardPw: yup.string().required(requiredMessage).max(2, "2자리 이하로 입력해주세요."),
  })
  .required();

// 충전기 설치 신청 Validation
export const reqChargerReceptionValidation = yup
  .object({
    nm: yup.string().required(requiredMessage).max(255, "255자리 이하로 입력해주세요."),
    cttpc: yup
      .string()
      .required(requiredMessage)
      .matches(/^[0-9-]{10,13}$/, "10~13글자의 핸드폰 번호를 입력해주세요."),
    email: yup
      .string()
      .required(requiredMessage)
      .matches(/^([\w\.\_\-]*[a-zA-Z0-9])+([-_]?[a-zA-Z0-9])+@[a-zA-Z0-9]+([-_]?[a-zA-Z0-9]+\.)+[a-zA-Z0-9]{2,8}$/, emailTypeMessage)
      .max(255, "255자리 이하로 입력해주세요."),
    ty: yup // checkbox default check가 없는 경우, null값이 들어오기 때문에 nullable() 후 oneOf에서 체크
      .string()
      .nullable()
      .oneOf(["OFFICIAL_USE", "NON_OFFICIAL_USE", "UNKNOWN"], requiredMessage),
    place_ty: yup.string().nullable().oneOf(["SHARE", "GOVERNMENT", "PUBLIC_SERVICE", "OFFICE", "COMMERCE", "OTHER"], requiredMessage),
    side_ty: yup.string().nullable().oneOf(["OUTSIDE", "INSIDE", "UNKNOWN"], requiredMessage),
    rdnmadr: yup.string().required(requiredMessage).max(255, "255자리 이하로 입력해주세요."),
    adres_detail: yup.string().max(255, "255자리 이하로 입력해주세요."),
    car_cnt: yup
      .string()
      .required(requiredMessage)
      .matches(/^[0-9]*$/, "숫자만 입력해주세요."),
  })
  .required();

// (마이페이지) 충전내역 날짜 검색 Validation
export const chargingRangeValidation = yup.object().shape({
  startDate: yup.string(),
  // .when('endDate', {
  //     is: (endDate) => !endDate || endDate.length === 0,
  //     // is:(endDate) => endDate.length > 0,
  //     then: yup.string().required(requiredMessage)
  // }),
  endDate: yup.string(),
  // .when('startDate', {
  //     // is: (startDate) => console.log(startDate),
  //     is:(startDate) => !startDate || startDate.length === 0,
  //     // is: (startDate) => {console.log(!startDate)},
  //     then: yup.string().required(requiredMessage)
  // }),
  anotherFiled: yup.string().when(["startDate", "endDate"], {
    is: (startDate, endDate) => !startDate || startDate.length === 0 || !endDate || endDate.length === 0,
    then: yup.string().required("기간 조회시 필수 입력입니다."),
  }),
});

import React from 'react';
import { Form } from 'react-bootstrap';

function CheckBox(props) {
    const label = props.label;
    const page = props.page;
    const name = props.name;
    const onCheck = props.onCheck;
    const onClick = props.onClick;
    const register = props.register ?? null;

    return (
        // <div className={"mb-2 " + (label!=="모두 동의합니다" ? "agree-box" : "")}>
        <div className={label!=="모두 동의합니다" ? "agree-box" : "all-agree-box"}>
            <div key={name} className={page === "join" ? label !== "모두 동의합니다" ? "form-group my-0 form-check" : "form-group my-0 form-check all-agree" : ""}>
                {
                    onCheck 
                    ? <Form.Check 
                        type="checkbox"
                        name={name}
                        id={name}
                        label={label}
                        onChange={onCheck}
                        checked={props.checked}
                        value={props.value}
                        // defaultChecked={props.checked}
                        {...register}
                    />
                    : <Form.Check 
                        type="checkbox"
                        name={name}
                        id={name}
                        label={label}
                        checked={props.checked}
                        onClick={onClick}
                        value={props.value}
                        // defaultChecked={props.checked}
                        {...register}
                    />
                }
            </div>
        </div>
    )
}

export default CheckBox
import apiUtil, { apiFile } from '../api/apiUtil.js';

export const apiGet = async (url) => {
	return await apiUtil.get(url);
}

export const apiDelete = async (url) => {
	return await apiUtil.delete(url);
}

export const apiGetPrams = async (url, reqData) => {
	if (reqData) {
		url = url + "?";

		let data = [];

		// eslint-disable-next-line array-callback-return
		Object.keys(reqData).map(key => {
			data.push(key+"="+reqData[key]);
		});

		url = url + data.join("&");
	}
	return await apiUtil.get(url);
}

export const apiPost = async (url, reqData) => {
	return await apiUtil({
    	url : url,
        method : 'post',
        data : reqData
    })
}

export const apiFilePost = async (url, formData) => {
	return await apiFile({
		url : url,
		method: 'post',
		data: formData
	})
}

import React from 'react'
import { useSelector } from 'react-redux';
import ListBoard from '../../components/ListBoard/ListBoard'

function Charging() {
    const userId = useSelector((state) => state.user.loginReqSuccess.data.id);

    return (
        <main className="common-index"> 
            <div className="container">
                <section className="charging">
                    <ListBoard boardTitle="충전이력" boardType="charging" pagination={true} search="range" userId={userId} />
                </section>
            </div>
        </main>
    )
}

export default Charging
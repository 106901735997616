import React from 'react';
import iconError from "../../img/icon-error.png";

function NotFound() {
    return (
        <main className="common-index">
            <div className="container">
                <div className="not-found">
                    <div className="col text-center">
                        <img src={iconError} alt="icon_error" className="mt-5"/>
                        <h3 className="my-4">페이지를 찾을 수 없습니다.</h3>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default NotFound
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import logoTop from "../../img/logo-top.png";
import icoHambug from "../../img/ico-hambug.png";
import SideBarModal from "../../components/Modal/SideBarModal";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "react-bootstrap";
import { logOutUser } from "../../_actions/user_action";

function NavBar(props) {
  // 로그인 여부
  // const isLoggedIn = props.isLoggedIn ?? false;
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.user.loginReqSuccess);
  const [sideBarShow, setSideBarShow] = React.useState(false);
  const navigate = useNavigate();

  const onClickSideBarHandler = () => {
    setSideBarShow(true);
  };

  const onClickLogoutHandler = (event) => {
    dispatch(logOutUser());
    //let urlarr = window.location.href.split("/");
    //window.location.href = urlarr[0] + "/";
    navigate("/");
  };

  const checkLogin = (e) => {
    if (isLoggedIn) {
      alert("이미 가입한 사용자입니다.");
      e.preventDefault();
    }
    return;
  };

  return (
    <header>
      <div className="header-inner m-0">
        <div className="container">
          <div className="row">
            <div className="col-1 mobile-btn d-block d-lg-none position-absolute">
              <img
                src={icoHambug}
                alt="ico_hambug"
                onClick={onClickSideBarHandler}
              />
            </div>

            <div className="col-12 col-lg-2 pt-1 header-logo">
              <Link to="/">
                <img src={logoTop} alt="evPlug_logo_top" />
              </Link>
            </div>

            <div className="d-none d-lg-block col-lg-7 text-center pt-2">
              <ul className="list-inline mb-0 main-menu">
                <li className="list-inline-item">
                  <Link to="/notice?currentPage=1">
                    {/* <Link to="/register" onClick={checkLogin}> */}
                    공지사항
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to="/receptions">충전기 설치신청</Link>
                </li>
                {/* <li className="list-inline-item">
                  <Link to="/support">
                    고객지원
                  </Link>
                </li> */}
              </ul>
            </div>
            <div className="d-none d-lg-block col-lg-3 text-right pt-1">
              {!isLoggedIn ? (
                <ul className="list-inline mb-0">
                  <li className="list-inline-item">
                    <Link to="/register" className="btn btn-login">
                      회원가입
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <Link to="/login" className="btn btn-login">
                      로그인
                    </Link>
                  </li>
                </ul>
              ) : (
                <ul className="list-inline mb-0">
                  <li className="list-inline-item">
                    {/* <DropdownButton className="btn btn-me">

                      </DropdownButton> */}
                    <Dropdown>
                      <Dropdown.Toggle className="btn btn-me">
                        마이페이지
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item href="/me">회원정보</Dropdown.Item>
                        <Dropdown.Item href="/charging?currentPage=1">
                          충전이력
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    {/* <Link to="/register" className="btn btn-me">마이페이지</Link> */}
                  </li>
                  <li className="list-inline-item">
                    <button
                      to="/loginout"
                      onClick={onClickLogoutHandler}
                      className="btn btn-login"
                    >
                      로그아웃
                    </button>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>

      <SideBarModal
        show={sideBarShow}
        onHide={() => setSideBarShow(false)}
        setSideBarShow={setSideBarShow}
      />
    </header>
  );
}

export default NavBar;

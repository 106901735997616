import React from 'react';
import { Form } from 'react-bootstrap';

function InputBox(props) {
  const name = props.name;
  const label = props.label;
  const label_length = props.label_length ?? 3;
  const readonly = props.readonly ?? false;
  const required = props.required ?? false;
  const discription = props.discription;
  const placeholder = props.placeholder;
  const onClickHandler = props.onClickHandler;
  const register = props.register;
  const errors = props.errors ?? null;
  const type = props.type ?? "text";
  const tailButtonLabel = props.tailButtonLabel ?? null;
  const tailButtonLabelLength = props.tailButtonLabelLength ?? null;
  const tailButtonClick = props.tailButtonClick ?? null;
  const buttonType = props.buttonType ?? "button";
  

  const onChangeHandler = (event) => {
    if (props.onChange) {
      props.onChange(event.taget.phone);
    }
  }

  return (
    <div className={name !=='carNo'? name !=='hiddenPaycardNo' ? "form-group row" : "row" : "row"}>
      {label && 
        <div className={"col-sm-" + label_length}>
          <Form.Label>{label}</Form.Label>{required && <span className="text-danger"> *</span>}
        </div>
      }
      {
        tailButtonLabel === "인증"
        && 
        <div className={"d-none d-lg-block col-sm-" + (!tailButtonLabel ? 12-label_length : tailButtonLabelLength? (12-tailButtonLabelLength-label_length) : 8-label_length)}>
            <Form.Control type={type} name={name} value={props.value} placeholder={placeholder} readOnly={readonly} onClick={onClickHandler} onChange={onChangeHandler} {...register} className={errors && "is-invalid"}/>
            {discription && 
              <Form.Text className="text-muted">
                {discription}
              </Form.Text>
            }
            {
              errors &&
              <div className="invalid-feedback">{errors}</div>
            }
        </div>
      }
      {
        tailButtonLabel === "인증"
        && 
        <div className={"d-block d-lg-none col-sm-9"}>
         <Form.Control type={type} name={name} value={props.value} placeholder={placeholder} readOnly={readonly} onClick={onClickHandler} onChange={onChangeHandler} {...register} className={errors && "is-invalid"}/>
         {discription && 
           <Form.Text className="text-muted">
             {discription}
           </Form.Text>
         }
         {
           errors &&
           <div className="invalid-feedback">{errors}</div>
         }
        </div>
      }
      {
         tailButtonLabel !== "인증" &&
         <div className={"col-sm-" + (!tailButtonLabel ? 12-label_length : tailButtonLabelLength? (12-tailButtonLabelLength-label_length) : 8-label_length)}>
            <Form.Control type={type} name={name} value={props.value} placeholder={placeholder} readOnly={readonly} onClick={onClickHandler} onChange={onChangeHandler} {...register} className={errors && "is-invalid"}/>
            {discription && 
              <Form.Text className="text-muted">
                {discription}
              </Form.Text>
            }
            {
              errors &&
              <div className="invalid-feedback">{errors}</div>
            }
        </div>
      } 
      {
        tailButtonLabel &&
        <div className={"tail-button col-sm-" + (tailButtonLabelLength ? tailButtonLabelLength : 4)}>
          <button type={buttonType} className={"btn btn-secondary rounded-pill btn-center " + (tailButtonLabel === "인증" ? "d-none d-lg-block" : "")} onClick={tailButtonClick}>{tailButtonLabel}</button>
        </div>
      }
     
    </div>
  )
}

export default InputBox
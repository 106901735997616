import React from 'react';
import SearchQRcode from './Contents/SearchQRcode';
import QRpay from '../Layouts/QRpay/QRpay';

function QRpayPage() {
    return (
        <QRpay QRContent={<SearchQRcode />}/>
    )
}

export default QRpayPage;
import React from "react";
import InputBox from "../../components/InputBox/InputBox";
import { Link, useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { updatePasswordValidation } from "../../validations/Validation";
import { logOutUser, updateUserPassword } from "../../_actions/user_action";

function Password() {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const schema = updatePasswordValidation;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();

  const onSubmitHandler = (event) => {
    if (window.confirm("비밀번호를 변경하시겠습니까?")) {
      let body = {
        id: user.loginReqSuccess.data.id,
        password: event.password,
      };

      dispatch(updateUserPassword(body)).then((response) => {
        if (response.payload.result === "OK") {
          alert("정상적으로 완료되었습니다.");
          dispatch(logOutUser());
          // let urlarr = window.location.href.split("/");
          // window.location.href=urlarr[0] + "/";
          navigate("/");
        } else {
          if (response.payload.data.validation) {
            alert(
              "[" +
                response.payload.data.validation.error +
                "] " +
                response.payload.data.validation.msg
            );
          } else {
            alert(
              "[페이지 오류 안내] 홈페이지 이용에 불편을 드려 죄송합니다. 새로고침 (F5키)을 누르시거나, 잠시 후에 이용해 주십시오."
            );
          }
        }
      });
    }
  };

  return (
    <main className="me-index">
      <div className="container">
        <section className="section-4">
          <div className="section-4-title text-center position-relative">
            비밀번호 변경
          </div>
          <Form onSubmit={handleSubmit(onSubmitHandler)}>
            <table className="table border">
              <tbody>
                <tr>
                  <th>
                    비밀번호 <span className="text-danger"> *</span>
                  </th>
                  <td>
                    <InputBox
                      name="password"
                      type="password"
                      label_length="0"
                      placeholder="특수문자 포함 영문 8~20글자를 입력해주세요."
                      register={register("password")}
                      errors={
                        errors.password?.message
                          ? errors.password?.message
                          : null
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    비밀번호 확인 <span className="text-danger"> *</span>
                  </th>
                  <td>
                    <InputBox
                      name="passwordConfirmation"
                      type="password"
                      label_length="0"
                      placeholder="비밀번호를 재입력해주세요."
                      register={register("passwordConfirmation")}
                      errors={
                        errors.passwordConfirmation?.message
                          ? errors.passwordConfirmation?.message
                          : null
                      }
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <small>
              비밀번호 변경시 로그아웃되어 변경된 비밀번호로 다시 로그인하셔야
              합니다.
            </small>
            <div className="btns my-5 text-center">
              <button type="submit" className="btn btn-main rounded-pill">
                수정
              </button>
              <Link to="/me" className="btn btn-gray rounded-pill">
                취소
              </Link>
            </div>
          </Form>
        </section>
      </div>
    </main>
  );
}

export default Password;

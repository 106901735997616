import React from 'react';
import { Link } from 'react-router-dom';
import iconJoin from '../../img/icon-join.png';

function RegisterCompletePage() {
  return (
    <main className="join">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="text-center mt-5 mb-4">
                <h3 className="d-none d-lg-block">멤버십 가입</h3>
                <h5 className="d-block d-lg-none">멤버십 가입</h5>
            </div>

              <div className="row justify-content-center mb-5">
               <div className="col-auto">
                   <div className="btn-group join-step btn-section">
                   <button type="button" className="btn btn-outline-primary btn-rounded clikable btn-section-agree">
                       약관동의
                   </button>
                   <button type="button" className="btn btn-outline-primary clikable btn-section-input">
                       회원정보입력
                   </button>
                   <button type="button" className="btn btn-outline-primary btn-rounded active">
                       가입완료
                   </button>
                   </div>
               </div>
            </div>

            <div className="text-center">
              <img src={iconJoin} className="mt-3" alt="icon-join"/>

              <div className="d-none d-lg-block mt-4">
                <h2 >충전왕의 멤버가 되신 것을<br/>환영합니다:)</h2>
              </div>
              <div className="d-block d-lg-none mt-4">
                <h4 >충전왕의 멤버가 되신 것을<br/>환영합니다:)</h4>
              </div>

              <div className="mt-4 mb-5">
                <Link to="/" className="btn btn-secondary rounded-pill">
                  홈으로
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default RegisterCompletePage
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import DefaultModal from "../../components/Modal/DefaultModal";
import { loginUser } from "../../_actions/user_action";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginValidation } from "../../validations/Validation";

function LoginPage(props) {
  const dispatch = useDispatch();

  const [lgnId] = useState("");
  const [lgnPassword, setLgnPassword] = useState("");
  const [modalShow, setModalShow] = React.useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState();
  const [modalCloseText, setModalCloseText] = useState("");
  const navigate = useNavigate();

  const schema = loginValidation;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  let errorsMessage = <tr></tr>;

  const onFindIdModalHandler = (event) => {
    if (!modalShow) {
      setModalShow(true);
      setModalTitle("아이디 찾기");
      setModalCloseText("찾기");
      setModalContent("FindId");
    }
  };

  const onFindPasswordHandler = (event) => {
    if (!modalShow) {
      setModalShow(true);
      setModalTitle("비밀번호 찾기");
      setModalCloseText("임시 비밀번호 받기");
      setModalContent("FindPassword");
    }
  };

  const onPasswordHandler = (event) => {
    setLgnPassword(event.currentTarget.value);
  };

  const onSubmitHandler = (event) => {
    // event.preventDefault();
    let body = {
      lgnId: event.lgnId,
      lgnPassword: event.lgnPassword,
    };

    dispatch(loginUser(body)).then((response) => {
      if (response.payload.result === "OK") {
        // let urlarr = window.location.href.split("/");
        // window.location.href = urlarr[0] + "/";
        navigate("/");
      } else {
        if (response.payload.msg === "exist") {
          if (response.payload.data.data === "lgnId") {
            alert("존재하지 않는 아이디 입니다.");
          } else {
            alert(
              "[페이지 오류 안내] 홈페이지 이용에 불편을 드려 죄송합니다. 새로고침 (F5키)을 누르시거나, 잠시 후에 이용해 주십시오."
            );
          }
        } else if (response.payload.msg === "Wrong password.") {
          alert("입력해주신 정보를 다시 확인해주세요.");
        } else if (response.payload.data.validation) {
          alert(
            "[" +
              response.payload.data.validation.error +
              "] " +
              response.payload.data.validation.msg
          );
        } else {
          alert(
            "[페이지 오류 안내] 홈페이지 이용에 불편을 드려 죄송합니다. 새로고침 (F5키)을 누르시거나, 잠시 후에 이용해 주십시오."
          );
        }
      }
    });
  };

  if (errors.lgnId) {
    errorsMessage = (
      <tr className="text-danger">
        <td className="error_msg">{errors.lgnId.message}</td>
      </tr>
    );
  }

  if (errors.lgnPassword) {
    errorsMessage = (
      <tr className="text-danger">
        <td className="error_msg">{errors.lgnPassword.message}</td>
      </tr>
    );
  }

  return (
    <main className="login background-rule">
      <div className="container margin-container">
        <div className="row justify-content-center margin-row">
          <div className="col-12 col-lg-5 margin-col">
            <div className="login-box">
              <div className="text-center mb-4">
                <h3 className="d-none d-lg-block">로그인</h3>
                <h5 className="d-block d-lg-none">로그인</h5>
              </div>

              <form onSubmit={handleSubmit(onSubmitHandler)}>
                <table className="w-100">
                  <tbody>
                    <tr>
                      <td>
                        <input
                          type="text"
                          name="lgnId"
                          id="lgnId"
                          placeholder="아이디"
                          autoFocus
                          tabIndex="1"
                          className={errors.lgnId ? "is-invalid" : ""}
                          {...register("lgnId", { value: lgnId })}
                        />
                      </td>
                      <th rowSpan="2" width="100px">
                        <button
                          type="submit"
                          className="btn btn-loginPage"
                          tabIndex="3"
                        >
                          로그인
                        </button>
                      </th>
                    </tr>
                    <tr>
                      <td>
                        <input
                          type="password"
                          name="lgnPassword"
                          id="lgnPassword"
                          tabIndex="2"
                          placeholder="비밀번호"
                          onChange={onPasswordHandler}
                          {...register("lgnPassword", { value: lgnPassword })}
                        />
                      </td>
                    </tr>
                    {errorsMessage && errorsMessage}{" "}
                    {/*에러 메세지가 있을때 에러메시지를 보여준다.*/}
                  </tbody>
                </table>
              </form>

              <div className="row mt-3">
                <div className="col-5 text-left">
                  <Link to="/register" className="btn btn-me">
                    멤버십가입
                  </Link>
                </div>
                <div className="col-7 text-right align-self-center">
                  <Link to="" onClick={onFindIdModalHandler}>
                    아이디찾기
                  </Link>{" "}
                  |
                  <Link to="" onClick={onFindPasswordHandler}>
                    {" "}
                    비밀번호찾기
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <DefaultModal
        modalTitle={modalTitle}
        modalContent={modalContent}
        modalCloseText={modalCloseText}
        show={modalShow}
        onHide={() => setModalShow(false)}
        page="login"
      />
    </main>
  );
}

export default LoginPage;

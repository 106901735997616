import React from 'react'
import { Col, Modal, Row } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import { useForm } from 'react-hook-form';
import { updatePaycardValidation } from '../../../validations/Validation';
import { yupResolver } from '@hookform/resolvers/yup';

function CreditCard(props) {
  const schema = updatePaycardValidation;
  const setValue = props.setValue;
  const { register, handleSubmit, formState:{ errors } } = useForm({
      resolver: yupResolver(schema)
  });

  const onSubmitHandler = (event) => {
    let cardNo = event.paycardNo.split("- ");
    cardNo = cardNo[0].split("-");

    for (let i = 0; i < cardNo.length-1; i ++) {
      cardNo[i] = cardNo[i].replace(/[0-9]/gi, "*");
    }
    setValue("hiddenPaycardNo", cardNo.join("-"), { shouldValidate: true });
    setValue("paycardNo", (event.paycardNo.replace(/ /gi, "")).replace(/-/gi, ""));
    setValue("paycardExp", event.paycardExp.replace(/ /gi, "").replace(/-/gi, ""));
    setValue("paycardBirth", event.paycardBirth.replace(/ /gi, "").replace(/-/gi, ""));
    setValue("paycardPw", event.paycardPw.replace(/ /gi, ""));
    props.onHide();
  }

  return (
    <div className="creditcard-store">
      <Form onSubmit={handleSubmit(onSubmitHandler)}>
        <Modal.Body>
          {/* <Container> */}
              <Row>
                <Col sm={12} md={4}>
                  카드번호 <span className="text-danger">*</span>
                </Col>
                <Col sm={12} md={8}>
                  <InputMask className={"form-control " + (errors.paycardNo?.message ? "is-invalid" : "")} mask="9999-9999-9999-9999" maskChar="" name="paycardNo" {...register("paycardNo")}/>
                  {
                      errors.paycardNo?.message &&
                      <div className="invalid-feedback">{errors.paycardNo?.message}</div>
                  }
                  <Form.Text className="text-muted">
                      예) 1234-1234-1234-1234
                  </Form.Text>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={4}>
                  유효기간 <span className="text-danger">*</span>
                </Col>
                <Col sm={12} md={8}>
                  <InputMask className={"form-control " + (errors.paycardExp?.message ? "is-invalid" : "")} mask="99-99" maskChar="" name="paycardExp" {...register("paycardExp")} />
                  {
                      errors.paycardExp?.message &&
                      <div className="invalid-feedback">{errors.paycardExp?.message}</div>
                  }
                  <Form.Text className="text-muted">
                  월-년 순서로 입력, 예) 08-24
                  </Form.Text>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={4}>
                  생년월일/사업자번호 <span className="text-danger">*</span>
                </Col>
                <Col sm={12} md={8}>
                  <InputMask className={"form-control " + (errors.paycardBirth?.message ? "is-invalid" : "")} mask="9999999999" maskChar="" name="paycardBirth" {...register("paycardBirth")}/>
                  {
                      errors.paycardBirth?.message &&
                      <div className="invalid-feedback">{errors.paycardBirth?.message}</div>
                  }
                  <Form.Text className="text-muted">
                  예) 850823(개인), 1234578900(사업자)
                  </Form.Text>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={4}>
                  비밀번호 앞 2자리 <span className="text-danger">*</span>
                </Col>
                <Col sm={12} md={8}>
                  <InputMask className={"form-control " + (errors.paycardPw?.message ? "is-invalid" : "")} type="password" mask="99" maskChar="" name="paycardPw" {...register("paycardPw")}/>
                  {
                      errors.paycardPw?.message &&
                      <div className="invalid-feedback">{errors.paycardPw?.message}</div>
                  }
                </Col>
              </Row>
          {/* </Container> */}
        </Modal.Body>
        <Modal.Footer>
          <button type="submit" className="btn btn-main rounded-pill btn-center btn-chargeking">등록</button>
        </Modal.Footer>
      </Form>
    </div>
  )
}

export default CreditCard
import React, { useState } from "react";
import CheckBox from "../../components/CheckBox/CheckBox";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteUser, logOutUser } from "../../_actions/user_action";

function Close() {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [agree, setAgree] = useState("N");
  const navigate = useNavigate();

  const onClickHandler = (event) => {
    if (agree === "Y") {
      // 회원탈퇴 요청

      if (window.confirm("회원 탈퇴를 진행하시겠습니까?")) {
        dispatch(deleteUser(user.loginReqSuccess.data.id)).then((response) => {
          if (response.payload.result === "OK") {
            alert("정상적으로 완료되었습니다.");
            dispatch(logOutUser());
            // let urlarr = window.location.href.split("/");
            // window.location.href = urlarr[0] + "/";
            navigate("/");
          } else {
            if (response.payload.data.validation) {
              alert(
                "[" +
                  response.payload.data.validation.error +
                  "] " +
                  response.payload.data.validation.msg
              );
            } else {
              alert(
                "[페이지 오류 안내] 홈페이지 이용에 불편을 드려 죄송합니다. 새로고침 (F5키)을 누르시거나, 잠시 후에 이용해 주십시오."
              );
            }
          }
        });
      }
    } else {
      alert("탈퇴 규정에 동의해주셔야 탈퇴가 가능합니다.");
    }
  };

  const onCheckHandler = () => {
    if (agree === "Y") {
      setAgree("N");
    } else {
      setAgree("Y");
    }
  };

  return (
    <main className="me-index">
      <div className="container">
        <section className="section-4">
          <div className="section-4-title text-center position-relative">
            회원탈퇴
          </div>
          {/* <Form onSubmit={onSubmitHandler}> */}
          <table className="table">
            <tbody>
              <tr>
                <th className="justify-content-center">탈퇴 규정</th>
                <td>
                  <ul>
                    <li>탈퇴 시 6개월간 재가입이 불가합니다.</li>
                    <li>
                      탈퇴 시 아이디 재사용 및 복구가 불가하오니 신중하게
                      선택하시기 바랍니다.
                    </li>
                    <li>
                      탈퇴한 아이디는{" "}
                      <Link to="/privacy">개인정보처리방침</Link>에 의거하여
                      회원 정보가 처리됩니다.
                    </li>
                  </ul>
                  <div>
                    <CheckBox
                      name="agree"
                      label="동의합니다."
                      onCheck={onCheckHandler}
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="btns my-5 text-center">
            <button
              onClick={onClickHandler}
              to="/close"
              className="btn btn-main rounded-pill"
            >
              회원탈퇴
            </button>
          </div>
          {/* </Form> */}
        </section>
      </div>
    </main>
  );
}

export default Close;

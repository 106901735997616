import {
    GET_CHARGER_TO_QR
} from '../_actions/types';

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = {}, action) {
    switch (action.type) {
        case GET_CHARGER_TO_QR:
            return { ...state, getCharger: action.payload}
        default:
            return state;
    }
}
import React from 'react'
import { Link } from 'react-router-dom';
import * as config from '../../config';

function Footer() {
    let company_name = config.COMPANY_NAME;
    let company_ceo = config.COMPANY_CEO;
    let company_addr = config.COMPANY_ADDR;
    let company_id = config.COMPANY_ID;
    let company_report_num = config.COMPANY_REPORT_NUM;

    return (
        <footer>
            <div className="footer-inner">
                <div className="container">
                    <div className="row">
                        <div className="col text-center">
                            <div className="d-none d-md-block">
                                {company_name}  {company_addr}  대표이사 : {company_ceo}  <br/>
                                통신판매업신고번호 : {company_report_num}  사업자등록번호 : {company_id} <br/> 
                                <Link to="/policy">서비스이용약관</Link> | <Link to="/privacy">개인정보처리방침</Link> 
                            </div>

                            <div className="d-md-none">
                                {company_name}  {company_addr} <br/> 
                                대표이사 : {company_ceo} <br/> 
                                통신판매업신고번호 : {company_report_num}  사업자등록번호 : {company_id} <br/>
                                <Link to="/policy">서비스이용약관</Link> | <Link to="/privacy">개인정보처리방침</Link> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
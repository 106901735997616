import {
    GET_CHARGER_TO_QR,
    QRPAY_PAYMENT
} from './types';
import { apiGetPrams, apiPost } from '../api/api';

export function getChargerToQr(params) {
    const request = apiGetPrams("/api/v1/qr", params)
    .then(response => response.data);

    return  {
        type: GET_CHARGER_TO_QR,
        payload: request
    }
}

export function qrpayPayment(data) {
    const request = apiPost("/api/v1/qr/payment", data)
    .then(response => response.data);

    return {
        type: QRPAY_PAYMENT,
        payload: request
    }
}
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getDetailArticle } from "../../_actions/article_action";
import { useParams } from "react-router-dom";
import { UtctoLocaleFormmatter } from "../../utils/Utils";

function NoticeDetail() {
  const dispatch = useDispatch();
  let { id } = useParams();
  const [article, setArticle] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    // 컴포넌트가 마운트 되었을 때 호출
    dispatch(getDetailArticle(id)).then((response) => {
      if (response.payload.result === "OK") {
        setArticle(response.payload.data);
      } else {
        alert(
          "[페이지 오류 안내] 홈페이지 이용에 불편을 드려 죄송합니다. 새로고침 (F5키)을 누르시거나, 잠시 후에 이용해 주십시오."
        );
        // let urlarr = window.location.href.split("/");
        // window.location.href = urlarr[0] + "/";
        navigate("/");
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="common-index">
      <section className="section-4">
        <div className="container">
          <div className="section-4-title text-center position-relative">
            공지사항
          </div>
          <div className="list-board">
            <div className="list-head">
              <div className="row detail-head">
                <div className="col-12 col-sm-9">{article.sj}</div>
                <div className="col-2 col-sm-3 text-right detail-date d-none d-lg-block">
                  {UtctoLocaleFormmatter(article.regDt)}
                </div>
              </div>
              <div className="detail-date d-block d-lg-none">
                {UtctoLocaleFormmatter(article.regDt)}
              </div>
            </div>
            <div
              className="detail-content"
              dangerouslySetInnerHTML={{ __html: article.cn }}
            ></div>
            <hr></hr>
          </div>
          <div className="text-center detail-bottom">
            <Link to={-1} className="btn btn-login">
              목록으로
            </Link>
          </div>
        </div>
      </section>
    </main>
  );
}

export default NoticeDetail;

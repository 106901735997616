import React, { useState } from "react";
import CheckBox from "../CheckBox/CheckBox";
import Spinner from "react-bootstrap/Spinner";
import { useDispatch } from "react-redux";
import { getStipulation } from "../../_actions/stipulation_action";
import { useNavigate } from "react-router-dom";

function StipulationBlock(props) {
  const dispatch = useDispatch();
  const [policy, setPolicy] = useState("");
  const [privacy, setPrivacy] = useState("");
  const [policyLocation, setPolicyLocation] = useState("");
  const navigate = useNavigate();

  const page = props.page;
  const title1 = props.title1;
  const title2 = props.title2;
  const ty = props.ty;
  const onCheck = props.onCheck;
  let checked = props.checked;
  let label = "";
  let contents = "";
  switch (ty) {
    case "policy":
      if (policy === "") {
        dispatch(getStipulation("이용약관")).then((response) => {
          if (response.payload.result === "OK") {
            if (response.payload.data.cn) {
              setPolicy(response.payload.data.cn);
            } else {
              alert(
                "[페이지 오류 안내] 홈페이지 이용에 불편을 드려 죄송합니다. 새로고침 (F5키)을 누르시거나, 잠시 후에 이용해 주십시오."
              );
              //   let urlarr = window.location.href.split("/");
              //   window.location.href = urlarr[0] + "/" + urlarr[1];
              navigate("/");
            }
          } else {
            // 실패 처리
            alert(
              "[페이지 오류 안내] 홈페이지 이용에 불편을 드려 죄송합니다. 새로고침 (F5키)을 누르시거나, 잠시 후에 이용해 주십시오."
            );
            //let urlarr = window.location.href.split("/");
            //window.location.href = urlarr[0] + "/" + urlarr[1];
            navigate("/");
          }
        });
      }
      break;
    case "policy-station":
      if (policyLocation === "") {
        dispatch(
          getStipulation("개인정보 수집 및 이용 동의 (충전기 설치 신청)")
        ).then((response) => {
          if (response.payload.result === "OK") {
            if (response.payload.data.cn) {
              setPolicyLocation(response.payload.data.cn);
            } else {
              alert(
                "[페이지 오류 안내] 홈페이지 이용에 불편을 드려 죄송합니다. 새로고침 (F5키)을 누르시거나, 잠시 후에 이용해 주십시오."
              );
              //let urlarr = window.location.href.split("/");
              //window.location.href = urlarr[0] + "/" + urlarr[1];
              navigate("/");
            }
          } else {
            // 실패 처리
            alert(
              "[페이지 오류 안내] 홈페이지 이용에 불편을 드려 죄송합니다. 새로고침 (F5키)을 누르시거나, 잠시 후에 이용해 주십시오."
            );
            //let urlarr = window.location.href.split("/");
            //window.location.href = urlarr[0] + "/" + urlarr[1];
            navigate("/");
          }
        });
      }
      break;
    case "privacy":
      if (privacy === "") {
        dispatch(getStipulation("개인정보 수집 및 이용 동의 (회원가입)")).then(
          (response) => {
            if (response.payload.result === "OK") {
              if (response.payload.data.cn) {
                setPrivacy(response.payload.data.cn);
              } else {
                alert(
                  "[페이지 오류 안내] 홈페이지 이용에 불편을 드려 죄송합니다. 새로고침 (F5키)을 누르시거나, 잠시 후에 이용해 주십시오."
                );
                // let urlarr = window.location.href.split("/");
                // window.location.href = urlarr[0] + "/" + urlarr[1];
                navigate("/");
              }
            } else {
              // 실패 처리
              alert(
                "[페이지 오류 안내] 홈페이지 이용에 불편을 드려 죄송합니다. 새로고침 (F5키)을 누르시거나, 잠시 후에 이용해 주십시오."
              );
              //   let urlarr = window.location.href.split("/");
              //   window.location.href = urlarr[0] + "/" + urlarr[1];
              navigate("/");
            }
          }
        );
      }
      break;
    default:
      break;
  }

  if (ty !== "") {
    label = "동의합니다";
  } else {
    label = "모두 동의합니다";
  }

  if (ty !== "") {
    switch (ty) {
      case "policy":
        if (policy) {
          contents = (
            <div
              className="iframe w-100"
              dangerouslySetInnerHTML={{ __html: policy }}
            ></div>
          );
        } else {
          contents = (
            <div className="iframe w-100">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          );
        }
        break;
      case "policy-station":
        if (policyLocation) {
          contents = (
            <div
              className="iframe w-100"
              dangerouslySetInnerHTML={{ __html: policyLocation }}
            ></div>
          );
        } else {
          contents = (
            <div className="iframe w-100">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          );
        }
        break;
      case "privacy":
        if (privacy) {
          contents = (
            <div
              className="iframe w-100"
              dangerouslySetInnerHTML={{ __html: privacy }}
            ></div>
          );
        } else {
          contents = (
            <div className="iframe w-100">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          );
        }
        break;
      default:
        break;
    }
  }

  return (
    <div className="row">
      {ty !== "policy-station" && (
        <div
          className={
            page === "join"
              ? ty === ""
                ? "col-12 col-lg-2 div-relative div-all-padding"
                : "col-12 col-lg-2 div-relative"
              : ""
          }
        >
          {ty !== "" && (
            <div className="agree-title">
              {title1}
              <br />
              {title2}
            </div>
          )}
          <CheckBox
            name={ty === "" ? "all" : ty}
            page={page}
            label={label}
            onCheck={onCheck}
            checked={checked}
          />
        </div>
      )}
      {ty !== "" && (
        <div className={ty !== "policy-station" ? "col-12 col-lg-10" : ""}>
          {contents}
        </div>
      )}
    </div>
  );
}

export default StipulationBlock;

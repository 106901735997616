import React from 'react'
import { Modal } from 'react-bootstrap';
import CreditCard from './Contents/CreditCard';
import FindId from './Contents/FindId';
import FindPassword from './Contents/FindPassword';
import CertifyPhone from './Contents/CertifyPhone';

function DefaultModal(props) {
  const modalTitle = props.modalTitle;
  const modalContent = props.modalContent;
  const setPhone = props.setPhone ?? null;
  const page = props.page;
  let content;

  switch (modalContent) {
    case "FindId":
        content = <FindId onHide={props.onHide} />;
      break;
    case "FindPassword":
        content = <FindPassword onHide={props.onHide}/>;
      break;
    case "CreditCard":
        content = <CreditCard onHide={props.onHide} setValue={props.setValue} />;
      break;
    case "CertifyPhone":
        content = <CertifyPhone onHide={props.onHide} setValue={props.setValue} setPhone={setPhone} setIsCertifyPhone={props.setIsCertifyPhone}/>;
      break;
    default:
      break;
  }

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      aria-labelledby="contained-modal-title-vcenter"
      // centered
      autoFocus
      fullscreen="md-down"
      className={page}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="modalTitle">
          {modalTitle}
        </Modal.Title>
      </Modal.Header>
      {content}
    </Modal>
  )
}
export default DefaultModal
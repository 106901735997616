import { Modal } from 'react-bootstrap';
import React, { useEffect, useState } from 'react'
import DaumPostcode from 'react-daum-postcode';

function AddressModal(props) {
    const [modalAddressShow, setModalAddressShow] = useState(props.show);

    useEffect(() => {
        setModalAddressShow(props.show)
    }, [props.show]);
    
    const onCloseModalHandler = () => {
        props.setModalAddressShow(false);
    }

    const handleComplete = (data) => {
        props.setModalAddressShow(false);
        props.setValue("rdnmadr", data.roadAddress, { shouldValidate: true });
        props.setValue("adres", data.jibunAddress === "" ? data.autoJibunAddress : data.jibunAddress);
        props.setValue("zipCode", data.zonecode);
    }

    return (
        <Modal
            show={modalAddressShow}
            onHide={onCloseModalHandler}
            aria-labelledby="contained-modal-title-vcenter"
            autoFocus
            fullscreen="md-down"
            className="address"
            >
            <Modal.Header closeButton>
            </Modal.Header>

            <DaumPostcode onComplete={handleComplete}/>
        </Modal>
    )
}

export default AddressModal
import {
    CERTIFY_REQ_PHONE
} from '../_actions/types';

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = {}, action) {
    switch (action.type) {
        case CERTIFY_REQ_PHONE:
            return { ...state, certifyReqSuccess: action.payload}
        default:
            return state;
    }
}
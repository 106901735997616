import React from 'react';
import { Link } from 'react-router-dom';
import imgMember from '../../img/img-member.png';
import imgCompeny from '../../img/img-company.png';

function RegisterPage(props) {
    return (
        <main className="register">
            <div className="container margin-container">
                <div className="row justify-content-center margin-row">
                    <div className="col-12 col-lg-8 margin-col">
                        <div className="trans-box">
                            <div className="text-center mb-3">
                                <h3>멤버십 가입</h3>
                            </div>

                            <div className="text-center d-none d-lg-block">
                                <ul>
                                    호환성 문제로 인터넷 익스플로러(Internet Explorer)에서는 가입 중 오류가 발생할 수 있습니다. <br/>
                                    크롬, 사파리 등 다른 브라우저 이용을 권장 드립니다.
                                </ul>
                            </div>

                            <div className="text-center d-block d-lg-none">
                                <ul>
                                호환성 문제로 인터넷 익스플로러(Internet Explorer)에서는 가입 중 <br/> 오류가 발생할 수 있습니다.
                                크롬, 사파리 등 다른 브라우저 이용을 권장 드립니다.
                                </ul>
                            </div>

                            <div className="row mb-4">
                                <div className="col-6">
                                <Link to="/register/member" className="btn btn-block btn-subBox">
                                    <img src={imgMember} alt="img_member" /> 개인회원
                                </Link>
                                </div>
                                <div className="col-6">
                                <Link to="/register/company" className="btn btn-block btn-subBox">
                                    <img src={imgCompeny} alt="img_company" /> 법인회원
                                </Link>
                                </div>
                            </div>

                            <ul clsss="mb-0">
                                <li>개인회원의 경우, 결제카드를 등록해 주셔야 합니다.</li>
                                <li>회원 가입 시 멤버십 카드는 자동으로 발급되어 발송합니다.</li>
                                <li>멤버십 카드는 회원 정보 입력 시 발급유무를 선택할 수 있고, 수령까지 영업일 기준 3~4일 정도 소요됩니다.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default RegisterPage
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { QRChargingInfo } from "../../../validations/Validation";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router";
import { yupResolver } from "@hookform/resolvers/yup";
import InputMask from "react-input-mask";
import { useDispatch } from "react-redux";
import Payment from "../Contents/Payment";
import { qrpayPayment } from "../../../_actions/qr_action";

function ChargingInfo() {
  const dispatch = useDispatch();
  const location = useLocation();
  const schema = QRChargingInfo;
  const qrcode = location.state.qrcode;
  const charger = location.state.charger;

  let [phone, setPhone] = useState("");
  let [kwhPay, setKwhPay] = useState("20000");
  const [isChargingInfo, setIsChargingInfo] = useState(false);
  const [allCheck, setAllCheck] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (event) => {
    setIsChargingInfo(true);
    setPhone(event.phone);
    //setKwhPay(event.kwhPay);
    setKwhPay(kwhPay);
  };

  const onChangeKwhPayHandler = (event) => {
    setKwhPay(event.target.id);
  };

  const onQrpaySubmit = (event) => {
    if (!allCheck) {
      alert("결제 진행을 위해 약관에 동의가 필요합니다.");
    } else {
      if (!isSubmit) {
        setIsSubmit(true);
        let body = {
          qrcode: parseInt(charger.qrcode),
          phone: phone.replace(/ /gi, "").replace(/-/gi, ""),
          reqPrice: kwhPay,
          paycardNo: event.paycardNo.replace(/ /gi, "").replace(/-/gi, ""),
          paycardExp: event.paycardExp.replace(/ /gi, "").replace(/-/gi, ""),
          paycardBirth: event.paycardBirth
            .replace(/ /gi, "")
            .replace(/-/gi, ""),
          paycardPw: event.paycardPw.replace(/ /gi, ""),
        };

        dispatch(qrpayPayment(body)).then((response) => {
          if (response.payload.result === "OK") {
            // let urlarr = window.location.href.split("QRpay");
            // window.location.href =
            //   urlarr[0] + "QRpay/" + qrcode + "/payment_result";
            navigate(`QRpay/${qrcode}/payment_result`);
          } else {
            if (response.payload.msg === "사용이 불가능한 충전기입니다.") {
              alert("사용이 불가능한 충전기입니다.");
              //   let urlarr = window.location.href.split("QRpay");
              //     window.location.href = urlarr[0] + "QRpay/" + qrcode;
              navigate(`QRpay/${qrcode}`);
            } else if (response.payload.data.error) {
              alert(response.payload.data.error.msg);
            } else if (response.payload.data.validation) {
              alert(
                "[" +
                  response.payload.data.validation.error +
                  "] " +
                  response.payload.data.validation.msg
              );
            } else {
              alert(
                "[페이지 오류 안내] 홈페이지 이용에 불편을 드려 죄송합니다. 새로고침 (F5키)을 누르시거나, 잠시 후에 이용해 주십시오."
              );
            }
          }
        });
      }
    }
  };

  return (
    <div>
      {!isChargingInfo ? (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <label className="col-sm-12 col-form-label">
              휴대폰 번호를 입력해 주세요{" "}
              <span className="text-danger"> *</span>
            </label>
            <div className="col-sm-12">
              <InputMask
                className={
                  "form-control " + (errors.phone?.message ? "is-invalid" : "")
                }
                placeholder="ex) 010-1234-5678"
                mask="999-9999-9999"
                maskChar=""
                name="phone"
                {...register("phone")}
              />
              {/* <InputBox name="phone" label_length={0} register={register("phone")} placeholder="ex) 010-1234-5678" /> */}
              {errors.phone?.message && (
                <div className="invalid-feedback">{errors.phone?.message}</div>
              )}
            </div>
          </div>
          <div className="form-group row mb-3">
            <div className="col-sm-12">
              <small className="form-text text-muted">
                휴대전화 번호는 본인 확인을 위해 이용됩니다. 정확한 번호를
                입력하지 않을 시, 서비스 이용에 불편을 겪을 수 있습니다.
              </small>
            </div>
          </div>
          <div className="row">
            <label className="col-sm-12 col-form-label">
              {" "}
              충전 요금을 선택해 주세요 <span className="text-danger"> *</span>
            </label>
            <div className="col-12 form-group">
              <Form.Check
                type="radio"
                label="3,000"
                name="kwhPay"
                value="3000"
                id="3000"
                {...register("kwhPay")}
                onChange={onChangeKwhPayHandler}
                isInvalid={errors.kwhPay}
                className={errors.kwhPay?.message && "is-invalid"}
              />
            </div>
            <div className="col-12 form-group">
              <Form.Check
                type="radio"
                label="5,000"
                name="kwhPay"
                value="5000"
                id="5000"
                {...register("kwhPay")}
                onChange={onChangeKwhPayHandler}
                isInvalid={errors.kwhPay}
                className={errors.kwhPay?.message && "is-invalid"}
              />
            </div>
            <div className="col-12 form-group">
              <Form.Check
                type="radio"
                label="10,000"
                name="kwhPay"
                value="10000"
                id="10000"
                {...register("kwhPay")}
                onChange={onChangeKwhPayHandler}
                isInvalid={errors.kwhPay}
                className={errors.kwhPay?.message && "is-invalid"}
              />
            </div>
            <div className="col-12 form-group">
              <Form.Check
                type="radio"
                label="20,000"
                name="kwhPay"
                value="20000"
                id="20000"
                defaultChecked="checked"
                {...register("kwhPay")}
                onChange={onChangeKwhPayHandler}
                isInvalid={errors.kwhPay}
                className={errors.kwhPay?.message && "is-invalid"}
              />
            </div>
            <div className="col-12 form-group">
              <Form.Check
                type="radio"
                label="30,000"
                name="kwhPay"
                id="30000"
                value="30000"
                {...register("kwhPay")}
                onChange={onChangeKwhPayHandler}
                isInvalid={errors.kwhPay}
                className={errors.kwhPay?.message && "is-invalid"}
              />
              {errors.kwhPay?.message && (
                <div className="invalid-feedback">{errors.kwhPay?.message}</div>
              )}
            </div>
          </div>
          <div className="form-group row">
            <div className="col-sm-12">
              <small className="form-text text-muted">
                실제 충전량 만큼만 과금되며, 차액은 환불됩니다.
              </small>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-sm-12">
              <div className="card bg-light">
                <div>
                  <sub>예상 충전량</sub>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="text-right">
                        <span id="charging_kwh">
                          {(kwhPay / parseInt(charger.price)).toFixed(2)}
                        </span>
                        <span className="text-right">kWh</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <small className="form-text text-muted">
                예상 충전량은 시간대별 적용요금에 따라 변경되며, 실제와 차이가
                있을 수 있습니다.
              </small>
            </div>
          </div>
          <div className="navigator">
            <Link to={-1} className="prev float-left">
              이전
            </Link>
            <button
              type="submit"
              className="btn next text-right float-right active btn-go-pay"
            >
              결제페이지 이동
            </button>
            {/* <Link to="payment" className="next text-right float-right active btn-go-pay">결제페이지 이동</Link> */}
            <div className="clearfix"></div>
          </div>
        </Form>
      ) : (
        <Payment
          onQrpaySubmit={onQrpaySubmit}
          setAllCheck={setAllCheck}
          allCheck={allCheck}
        />
      )}
    </div>
  );
}

export default ChargingInfo;

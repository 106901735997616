import React, { useState, useLayoutEffect, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import CheckBox from "../../components/CheckBox/CheckBox";
import InputBox from "../../components/InputBox/InputBox";
import AddressModal from "../../components/Modal/AddressModal";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { logOutUser, showUser, updateCompanyUser, updateMemberUser } from "../../_actions/user_action";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { updateMemberUserValidation } from "../../validations/Validation";
import { updateCompanyUserValidation } from "../../validations/Validation";
import { getCarList } from "../../_actions/car_action";

function Me() {
  const userId = useSelector((state) => state.user.loginReqSuccess);
  const [user, setUser] = useState({});
  const [car, setCar] = useState([]);
  const dispatch = useDispatch();
  const [modalAddressShow, setModalAddressShow] = useState(false);
  const [schema, setSchema] = useState(updateMemberUserValidation);

  const [smsRcptnAgreYn, setSmsRcptnAgreYn] = useState(false);
  const [emlRcptnAgreYn, setEmlRcptnAgreYn] = useState(false);

  const [carCompany, setCarCompany] = useState([]);
  const [selectCompany, setSelectCompany] = useState("선택없음");
  const [selectCar, setSelectCar] = useState("none");

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const addressModal = (modal) => {
    setModalAddressShow([modalAddressShow]);
  };

  const onAddressModalHandler = (event) => {
    if (!modalAddressShow) {
      setModalAddressShow(true);
    }
  };

  const onSmsRcptnAgreYnCheck = (event) => {
    setSmsRcptnAgreYn(!smsRcptnAgreYn);
  };

  const onEmlRcptnAgreYnCheck = (event) => {
    setEmlRcptnAgreYn(!emlRcptnAgreYn);
  };

  const onSubmitHandler = (event) => {
    let formData = new FormData();
    let body = {};

    if (event.file) {
      formData.append("id", userId.data.id);
      formData.append("ty", user.ty);
      formData.append("phone", event.phone);
      formData.append("email", event.email);
      formData.append("adres", event.adres ?? "-");
      formData.append("rdnmadr", event.rdnmadr ?? "-");
      formData.append("adresDetail", event.adresDetail === "" ? "null" : event.adresDetail);
      formData.append("zipCode", event.zipCode);
      formData.append("userCarId", user.carData.id);
      formData.append("carModelId", event.carModelId);
      // formData.append("carModelComapny", event.carModelComapny);
      formData.append("carNo", event.carNo);
      formData.append("managerNm", event.managerNm);
      formData.append("managerCttpc", event.managerCttpc);
      formData.append("managerEmail", event.managerEmail);
      formData.append("coFax", event.coFax ?? null);
      formData.append("smsRcptnAgreYn", smsRcptnAgreYn ? "Y" : "N");
      formData.append("emlRcptnAgreYn", emlRcptnAgreYn ? "Y" : "N");

      if (event.file[0]) {
        formData.append("file", event.file[0]);
      }

      if (user.fileId) {
        formData.append("fileId", user.fileId);
      }
    } else {
      body = {
        id: userId.data.id,
        ty: user.ty,
        phone: event.phone.replace(/ /gi, "").replace(/-/gi, ""),
        email: event.email,
        adres: event.adres ?? "-",
        rdnmadr: event.rdnmadr ?? "-",
        adresDetail: event.adresDetail === "" ? "null" : event.adresDetail,
        zipCode: event.zipCode,
        smsRcptnAgreYn: smsRcptnAgreYn ? "Y" : "N",
        emlRcptnAgreYn: emlRcptnAgreYn ? "Y" : "N",
        userCarId: user.carData.id,
        carModelId: event.carModelId,
        // carModelComapny: event.carModelComapny,
        carNo: event.carNo,
      };
    }

    if (window.confirm("저장하시겠습니까?")) {
      if (event.file) {
        dispatch(updateCompanyUser(formData)).then((response) => {
          if (response.payload.result === "OK") {
            alert("정상적으로 완료되었습니다.");
            //window.location.reload();
            navigate.reload();
          } else {
            if (response.payload.msg === "exist") {
              if (response.payload.data.data === "phone") {
                alert("이미 가입되어 있는 핸드폰 번호 입니다.");
              } else if (response.payload.data.data === "email") {
                alert("이미 가입되어 있는 이메일 입니다.");
              } else {
                alert("이미 가입되어 있는 " + response.payload.data.data + " 입니다.");
              }
            } else if (response.payload.data.validation) {
              alert("[" + response.payload.data.validation.error + "] " + response.payload.data.validation.msg);
            } else {
              alert("[페이지 오류 안내] 홈페이지 이용에 불편을 드려 죄송합니다. 새로고침 (F5키)을 누르시거나, 잠시 후에 이용해 주십시오.");
            }
          }
        });
      } else {
        dispatch(updateMemberUser(body)).then((response) => {
          if (response.payload.result === "OK") {
            alert("정상적으로 완료되었습니다.");
            //window.location.reload();
            // navigate.reload();
          } else {
            if (response.payload.msg === "exist") {
              if (response.payload.data.data === "phone") {
                alert("이미 가입되어 있는 핸드폰 번호 입니다.");
              } else if (response.payload.data.data === "email") {
                alert("이미 가입되어 있는 이메일 입니다.");
              } else {
                alert("이미 가입되어 있는 " + response.payload.data.data + " 입니다.");
              }
            } else if (response.payload.data.validation) {
              alert("[" + response.payload.data.validation.error + "] " + response.payload.data.validation.msg);
            } else {
              alert("[페이지 오류 안내] 홈페이지 이용에 불편을 드려 죄송합니다. 새로고침 (F5키)을 누르시거나, 잠시 후에 이용해 주십시오.");
            }
          }
        });
      }
    }
  };

  useLayoutEffect(() => {
    let testId = "";

    dispatch(showUser(userId.data.id)).then((response) => {
      if (response.payload.result === "OK") {
        testId = response.payload.data.carData.carModelId;
        setUser(response.payload.data);
        setSmsRcptnAgreYn(response.payload.data.smsRcptnAgreYn === "Y" ? true : false);
        setEmlRcptnAgreYn(response.payload.data.emlRcptnAgreYn === "Y" ? true : false);
        if (response.payload.data.ty === "COMPANY") {
          setSchema(updateCompanyUserValidation);
        }
        dispatch(getCarList(selectCar)).then((response) => {
          let companynm = "";
          let tempLst = [{ nm: "선택없음" }];
          if (response.payload.result === "OK") {
            for (var i = 0; i < response.payload.data.length; i++) {
              if (response.payload.data[i]["id"] === testId) {
                companynm = response.payload.data[i]["nm"];
              }
              tempLst.push({ nm: response.payload.data[i]["nm"] });
            }
            let setList = [...new Set(tempLst.map(JSON.stringify))].map(JSON.parse);
            setCarCompany(setList);
            setSelectCompany(companynm);
          } else {
            alert("[페이지 오류 안내] 홈페이지 이용에 불편을 드려 죄송합니다. 새로고침 (F5키)을 누르시거나, 잠시 후에 이용해 주십시오.");

            navigate("/");
          }
        });
        dispatch(getCarList()).then((response) => {
          if (response.payload.result === "OK") {
            let tempList = [{ modelNm: "선택없음", id: "none" }];
            for (let i = 0; i < response.payload.data.length; i++) {
              if (testId === response.payload.data[i].id) {
                tempList.push({ modelNm: response.payload.data[i].modelNm, id: response.payload.data[i].id });
              }
            }
            setCar(tempList);
            setSelectCar(testId);
          } else {
            alert("[페이지 오류 안내] 홈페이지 이용에 불편을 드려 죄송합니다. 새로고침 (F5키)을 누르시거나, 잠시 후에 이용해 주십시오.");
            navigate("/");
          }
        });
      } else {
        if (response.payload.msg === "exist") {
          alert("[페이지 오류 안내] 존재하지 않는 회원입니다.");
          // let urlarr = window.location.href.split("/");
          // window.location.href=urlarr[0] + "/";
          dispatch(logOutUser());
          // .then(response => {
          //let urlarr = window.location.href.split("/");
          //window.location.href = urlarr[0] + "/login";
          navigate("/login");
          // });
        } else {
          alert("[페이지 오류 안내] 홈페이지 이용에 불편을 드려 죄송합니다. 새로고침 (F5키)을 누르시거나, 잠시 후에 이용해 주십시오.");
          //let urlarr = window.location.href.split("/");
          //window.location.href = urlarr[0] + "/";
          navigate("/");
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeCarCompanyHandler = (event) => {
    let tempList = [{ modelNm: "선택없음", id: "none" }];
    let modelnm = event.target.value;
    if (event.target.value !== "선택없음") {
      dispatch(getCarList(event.target.value)).then((response) => {
        if (response.payload.result === "OK") {
          for (var i = 0; i < response.payload.data.length; i++) {
            if (modelnm === response.payload.data[i].nm) {
              tempList.push({ modelNm: response.payload.data[i].modelNm, id: response.payload.data[i].id });
            }
          }
          setCar(tempList);
        } else {
          alert("[페이지 오류 안내] 홈페이지 이용에 불편을 드려 죄송합니다. 새로고침 (F5키)을 누르시거나, 잠시 후에 이용해 주십시오.");
          //   let urlarr = window.location.href.split("/");
          //   window.location.href = urlarr[0] + "/" + urlarr[1];
          navigate("/");
        }
      });
    } else {
      setCar(tempList);
    }
  };
  return (
    Object.keys(user).length !== 0 && (
      <main className="me-index">
        <div className="container">
          <section className="section-4">
            <div className="section-4-title text-center position-relative">마이페이지</div>
            <Form onSubmit={handleSubmit(onSubmitHandler)} encType="multipart/form-data">
              <table className="table">
                <thead>
                  <tr>
                    <th>회원정보</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>이름</th>
                    <td>{user.nm}</td>
                  </tr>
                  <tr>
                    <th>아이디</th>
                    <td>{user.lgnId}</td>
                  </tr>
                  <tr>
                    <th>비밀번호</th>
                    <td>
                      <Link to="/password" className="btn btn-secondary rounded-pill">
                        비밀번호 변경
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <th>연락처</th>
                    <td>
                      <InputBox
                        label_length="0"
                        name="phone"
                        placeholder="숫자만 입력해주세요."
                        register={register("phone", { value: user.phone })}
                        errors={errors.phone?.message ? errors.phone?.message : null}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>이메일</th>
                    <td>
                      <InputBox
                        label_length="0"
                        name="email"
                        placeholder="이메일을 입력해주세요."
                        register={register("email", { value: user.email })}
                        errors={errors.email?.message ? errors.email?.message : null}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>주소</th>
                    <td>
                      <InputBox
                        label_length="0"
                        name="rdnmadr"
                        placeholder="클릭하여 입력해주세요."
                        readonly={true}
                        onClickHandler={onAddressModalHandler}
                        register={register("rdnmadr", { value: user.rdnmadr })}
                      />
                      <input name="adres" type="hidden" {...register("adres")} />
                      <input name="zipCode" type="hidden" {...register("zipCode")} />
                    </td>
                  </tr>
                  <tr>
                    <th></th>
                    <td>
                      <InputBox
                        label_length="0"
                        name="adresDetail"
                        register={register("adresDetail", {
                          value: user.adresDetail,
                        })}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>결제카드 정보</th>
                    <td>
                      현재 등록카드 :{" "}
                      {user.paycardData ? (
                        user.paycardData.paycardCo + " " + user.paycardData.paycardNo
                      ) : (
                        <span className="text-danger">결제카드가 등록되어있지 않습니다.</span>
                      )}
                      <div>
                        <Link to="/paycard" className="btn btn-secondary rounded-pill">
                          결제카드 변경
                          {/* { user.paycardData 
                                                ? "결제카드 변경"
                                                : "결제카드 등록"
                                            } */}
                        </Link>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>정보수신 동의</th>
                    <td className="flex">
                      <div className="mr-3">
                        <CheckBox
                          label="SMS 수신여부"
                          name="smsRcptnAgreYn"
                          register={register("smsRcptnAgreYn")}
                          onClick={onSmsRcptnAgreYnCheck}
                          checked={smsRcptnAgreYn}
                        />
                      </div>
                      <div>
                        <CheckBox
                          label="이메일 수신여부"
                          name="emlRcptnAgreYn"
                          register={register("emlRcptnAgreYn")}
                          onClick={onEmlRcptnAgreYnCheck}
                          checked={emlRcptnAgreYn}
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              {user.ty === "COMPANY" && (
                <table className="table">
                  <thead>
                    <tr>
                      <th>담당자 및 기업정보</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>담당자명</th>
                      <td>
                        {user.managerNm ? (
                          <InputBox
                            label_length="0"
                            name="managerNm"
                            placeholder="담당자 이름을 입력해주세요."
                            register={register("managerNm", {
                              value: user.managerNm,
                            })}
                            errors={errors.managerNm?.message ? errors.managerNm?.message : null}
                          />
                        ) : (
                          <InputBox
                            label_length="0"
                            name="managerNm"
                            placeholder="담당자 이름을 입력해주세요."
                            register={register("managerNm")}
                            errors={errors.managerNm?.message ? errors.managerNm?.message : null}
                          />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th>담당자 이메일</th>
                      <td>
                        {user.managerEmail ? (
                          <InputBox
                            label_length="0"
                            name="managerEmail"
                            placeholder="담당자 이메일을 입력해주세요."
                            register={register("managerEmail", {
                              value: user.managerEmail,
                            })}
                            errors={errors.managerEmail?.message ? errors.managerEmail?.message : null}
                          />
                        ) : (
                          <InputBox
                            label_length="0"
                            name="managerEmail"
                            placeholder="담당자 이메일을 입력해주세요."
                            register={register("managerEmail")}
                            errors={errors.managerEmail?.message ? errors.managerEmail?.message : null}
                          />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th>담당자 연락처</th>
                      <td>
                        {user.managerCttpc ? (
                          <InputBox
                            label_length="0"
                            name="managerCttpc"
                            placeholder="숫자만 입력해주세요."
                            register={register("managerCttpc", {
                              value: user.managerCttpc,
                            })}
                            errors={errors.managerCttpc?.message ? errors.managerCttpc?.message : null}
                          />
                        ) : (
                          <InputBox
                            label_length="0"
                            name="managerCttpc"
                            placeholder="숫자만 입력해주세요."
                            register={register("managerCttpc")}
                            errors={errors.managerCttpc?.message ? errors.managerCttpc?.message : null}
                          />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th>FAX</th>
                      <td>
                        {user.coFax ? (
                          <InputBox
                            label_length="0"
                            name="coFax"
                            placeholder="숫자만 입력해주세요."
                            register={register("coFax", { value: user.coFax })}
                            errors={errors.coFax?.message ? errors.coFax?.message : null}
                          />
                        ) : (
                          <InputBox
                            label_length="0"
                            name="coFax"
                            placeholder="숫자만 입력해주세요."
                            register={register("coFax")}
                            errors={errors.coFax?.message ? errors.coFax?.message : null}
                          />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th>구비서류</th>
                      <td>
                        <input
                          type="file"
                          id="file"
                          name="file"
                          accept="application/zip"
                          {...register("file")}
                          className={"form-control " + (errors.file?.message ? "is-invalid" : "")}
                        />
                        <Form.Text className="text-muted">
                          압축된 500KB 미만의 .zip 파일만 가능
                          {/* 3MB 이하 .pdf / jpg / bmp / png / jpeg 파일만 가능 */}
                        </Form.Text>
                        {errors.file?.message && <div className="invalid-feedback">{errors.file?.message}</div>}
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}

              <table className="table">
                <thead>
                  <tr>
                    <th>차량정보</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>차량제조사</th>
                    <td>
                      {user.carData ? (
                        <Form.Select
                          name="comapnyNm"
                          {...register("carModelComapny", {
                            value: "default",
                          })}
                          className={errors.carModelComapny ? "is-invalid" : null}
                          // defaultValue={user.carData.nm}
                          value={selectCompany}
                          onChange={(e) => {
                            setValue("carModelComapny", e.target.value, {
                              shouldValidate: true,
                            });

                            setSelectCompany(e.target.value);
                            setSelectCar("none");
                            setValue("carModelId", e.target.value, {
                              shouldValidate: true,
                            });
                            onChangeCarCompanyHandler(e);
                          }}
                        >
                          {carCompany.map((company, key) => (
                            <option key={"selectCompany" + company.nm} value={company.nm}>
                              {company.nm}
                            </option>
                          ))}
                        </Form.Select>
                      ) : (
                        <Form.Select name="carModelComapny" {...register("carModelComapny")}>
                          {carCompany.map((company, key) => (
                            <option key={"selectCompany" + key} value={company.nm}>
                              {company.nm}
                            </option>
                          ))}
                        </Form.Select>
                      )}
                      {errors.carModelComapny?.message && <div className="invalid-feedback">{errors.carModelComapny?.message}</div>}
                    </td>
                  </tr>
                  <tr>
                    <th>차량종류</th>
                    <td>
                      {user.carData ? (
                        <Form.Select
                          name="carModelId"
                          className={errors.carModelId ? "is-invalid" : null}
                          // {...register("carModelId", {
                          //   value: selectCar,
                          // })}
                          {...register("carModelId", {
                            value: user.carData.carModelId,
                          })}
                          value={selectCar}
                          onChange={(e) => {
                            setValue("carModelId", e.target.value, {
                              shouldValidate: true,
                            });
                            setSelectCar(e.target.value);
                          }}
                        >
                          {car.map((car, key) => (
                            <option key={"select" + car.id} value={`${car.id}`}>
                              {car.modelNm}
                            </option>
                          ))}
                        </Form.Select>
                      ) : (
                        <Form.Select name="carModelId" {...register("carModelId")}>
                          {car.map((car, key) => (
                            <option key={key} value={key}>
                              {car.modelNm}
                            </option>
                          ))}
                        </Form.Select>
                      )}
                      <div className="invalid-feedback">{errors.carModelId?.message}</div>
                    </td>
                  </tr>
                  <tr>
                    <th>차량번호</th>
                    <td>
                      {" "}
                      {user.carData ? (
                        <InputBox
                          label_length="0"
                          name="carNo"
                          register={register("carNo", {
                            value: user.carData.carNo,
                          })}
                          errors={errors.carNo?.message ? errors.carNo?.message : null}
                        />
                      ) : (
                        <InputBox
                          label_length="0"
                          name="carNo"
                          register={register("carNo")}
                          placeholder="등록 된 차량이 존재하지 않습니다."
                          errors={errors.carNo?.message ? errors.carNo?.message : null}
                        />
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>

              <table className="table">
                <thead>
                  <tr>
                    <th>멤버십 카드</th>
                  </tr>
                </thead>
                {user.cardData ? (
                  <tbody>
                    <tr>
                      <th>카드번호</th>
                      <td>{user.cardData.cardNo}</td>
                    </tr>
                    <tr>
                      <th>카드상태</th>
                      <td>
                        {
                          {
                            NONE: "알수없음",
                            READY: "정상",
                            CHARGING: "충전진행중",
                            STOP: "정지",
                            LOSS: "분실",
                            BROKEN: "파손",
                            WITHDRAWAL: "탈퇴",
                            EXPIRE: "만료",
                          }[user.cardData.sttus]
                        }
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <th>회원가입 후 2영업일 이내에 자동 발급됩니다.</th>
                    </tr>
                  </tbody>
                )}
              </table>
              <div className="btns my-5 text-center">
                <button className="btn btn-main rounded-pill" type="submit">
                  수정
                </button>
                <Link to="/close" className="btn btn-gray rounded-pill">
                  회원탈퇴
                </Link>
              </div>
            </Form>
          </section>
        </div>
        <AddressModal
          addressModal={addressModal}
          show={modalAddressShow}
          onHide={() => setModalAddressShow(false)}
          setModalAddressShow={setModalAddressShow}
          setValue={setValue}
        />
      </main>
    )
  );
}

export default Me;

import React from "react";
import topLabel from "../../img/img-top-label.png";
import iconJoin from "../../img/icon-join.png";
import iconCharger from "../../img/icon-charger.png";
import iconCs from "../../img/icon-cs.png";
//import iconEvwhere from "../../img/ico-new-evwhere.png";
import iconChargeKing from "../../img/app_icon_img.png";
import btnAppstore from "../../img/btn-appstore.png";
import btnPlaystore from "../../img/btn-playstore.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import ListBoard from "../../components/ListBoard/ListBoard";
import { Link } from "react-router-dom";
import products from "../../../src/products.pdf";
import { useSelector } from "react-redux";

function LandingPage() {
  const isLoggedIn = useSelector((state) => state.user.loginReqSuccess);
  const checkLogin = (e) => {
    if (isLoggedIn) {
      alert("이미 가입한 사용자입니다.");
      e.preventDefault();
    }
    return;
  };
  return (
    <main className="index">
      <section className="section-1 background-rule">
        <div className="container text-white">
          <div className="row">
            <div className="col">
              <p className="section-1-title text-center">충전왕 서비스란?</p>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-lg-5">
              <div className="section-1-content-left">
                <img src={topLabel} alt="topLabel" />
              </div>
            </div>
            <div className="col-12 col-lg-7">
              <div className="section-1-content-right">
                <p className="section-1-text">
                  충전왕 서비스란 ?<br />
                  전기차 충전 요금 걱정 끝<br />
                  경제적인 충전 생활의 시작
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-2 text-center">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6">
              <img src={iconJoin} alt="iconJoin" />
              <div className="section-2-title">충전왕 멤버십 가입</div>
              <div className="section-2-content">
                지금 바로 충전왕의 멤버가 되어주세요. <br />
                다양한 혜택이 고객님을 기다립니다.
              </div>
              <Link
                to="/register"
                className="btn btn-main rounded-pill"
                onClick={checkLogin}
              >
                멤버십 가입
              </Link>
            </div>

            <div className="col-12 col-lg-6">
              <img src={iconCharger} alt="iconCharger" />
              <div className="section-2-title">충전기 설치신청</div>
              <div className="section-2-content">
                충전왕 멤버로 가입하시고 <br />
                원하는 장소에 충전기 설치를 신청해 보세요.
              </div>
              <Link to="/receptions" className="btn btn-sub rounded-pill">
                바로가기
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="section-3 text-center">
                <div className="container">
                    <div>
                        <a href={products} className="btn btn-main rounded-pill">충전기 상세정보 보기</a>
                    </div>
                </div>
            </section> */}

      <section className="section-4">
        <ListBoard
          boardTitle="공지사항"
          isMore={true}
          moreLink={"/notice?currentPage=1"}
          boardType="notice"
        />
      </section>

      <section className="section-5 background-rule">
        <div className="container py-5">
          <div className="flex justify-content-center row">
            <div className="box mx-3 text-center col-sm-12">
              <img src={iconCs} alt="icon_cs" className="icon-image mt-4" />
              <p className="section-5-title">고객센터</p>
              <div className="section-5-content mb-4">
                고객 만족을 위해 <br />
                노력하겠습니다. <br />
              </div>
              {/* <div className="mb-3"> */}
              <div className="section-5-content2">
                <a
                  href="tel:0269310907"
                  className="btn btn-sub rounded-pill btn-call"
                >
                  <FontAwesomeIcon icon={faPhone} /> 02-6231-0907
                </a>
              </div>
              {/* <div className="">
                                <a href="tel:0269563055" className="btn btn-sub rounded-pill btn-call">
                                    <FontAwesomeIcon icon={faPhone} /> 02-6956-3000
                                </a>
                            </div> */}
            </div>

            {/* <div className="d-lg-none"></div> */}

            <div className="box mx-3 text-center">
              <img
                src={iconChargeKing}
                alt="icon_evwhere"
                className="icon-image"
              />
              <p className="section-5-title">충전소 찾기</p>
              <div className="section-5-content mb-4">
                <span className="color1">충전왕</span>을 통해 모바일로 <br />
                충전소를 찾아보세요.
              </div>
              <div className="mb-3">
                <a
                  href="https://apps.apple.com/ae/app/%EC%B6%A9%EC%A0%84%EC%99%95/id1592635649"
                  traget="_blank"
                >
                  <img
                    src={btnAppstore}
                    alt="btn_appstore"
                    className="btn-image"
                  />
                </a>
              </div>
              <div className="">
                <a
                  href="https://play.google.com/store/apps/details?id=kr.solarconnect.evcharge"
                  traget="_blank"
                >
                  <img
                    src={btnPlaystore}
                    alt="btn_playstore"
                    className="btn-image"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default LandingPage;

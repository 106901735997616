export const LOGIN_USER = "login_user";
export const LOGOUT_USER = "logout_user";
export const SHOW_USER = "show_user";
export const FIND_ID = "find_id";
export const REGISTER_USER = "register_user";
export const AUTH_USER = "auth_user";
export const CERTIFY_REQ_PHONE = "certify_req_phone";
export const STIPULATION_AGREE_USER = "stipulation_agree_user";
export const GET_STIPULATION = "get_stipulation";
export const GET_LANDING_NOTICE_LIST = "get_landing_notice_list";
export const GET_CAR_LIST = "get_car_list";
export const GET_DETAIL_ARTICLE = "get_detail_article";
export const GET_ARTICLE_LIST = "get_article_list";
export const GET_CHARGING_LIST = "get_charging_list";
export const CHARGER_RECEPTION = "charger_reception";
export const UPDATE_MEMBER_USER = "update_member_user";
export const UPDATE_COMPANY_USER = "update_company_user";
export const DELETE_USER = "delete_user";
export const UPDATE_USER_PASSWORD = "update_user_password";
export const UPDATE_USER_PAYCARD = "update_user_paycard";
export const CERTIFY_AUTH_CODE = "certify_auth_code";
export const STORE_MEMBER_USER = "store_member_user";
export const STORE_COMPANY_USER = "store_company_user";
export const USER_TEMP_PASSWORD = "user_temp_password";
export const GET_CHARGER_TO_QR = "get_charger_to_qr";
export const QRPAY_PAYMENT = "qrpay_payment";

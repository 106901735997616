import React from 'react'
import ListBoard from '../../components/ListBoard/ListBoard'

function NoticePage() {
    return (
        <main className="common-index">
            <div className="container">
                <section className="section-4">
                    <ListBoard boardTitle="공지사항" boardType="notice" search="keyword" pagination={true}/>
                </section>
            </div>
        </main>
    )
}

export default NoticePage
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import React, { useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { findIdValidation } from '../../../validations/Validation';
import { findId } from '../../../_actions/user_action';
import InputBox from '../../InputBox/InputBox';

function FindId(props) {
    const dispatch = useDispatch();
    const schema = findIdValidation;
    const { register, handleSubmit, formState:{ errors } } = useForm({
        resolver: yupResolver(schema)
    });

    const [sendEmail, setSendEmail] = useState(false);

    const onSubmitHandler = (event) => {
        let params = {
            email: event.email
        }

        dispatch(findId(params))
        .then(response => {
            if (response.payload.result === "OK") {
                setSendEmail(true);
            } else {
                if (response.payload.msg === "exist") {
                    if (response.payload.data.data === "email") {
                        alert ("존재하지 않는 이메일 입니다.");
                    } else {
                        alert("[페이지 오류 안내] 홈페이지 이용에 불편을 드려 죄송합니다. 새로고침 (F5키)을 누르시거나, 잠시 후에 이용해 주십시오.");
                    }
                } else if (response.payload.msg === "email 여러개가 검색 되었습니다.") {
                    alert ("중복 등록 된 이메일이 존재합니다. 고객센터에 문의해주세요.");
                } else {
                    alert("[페이지 오류 안내] 홈페이지 이용에 불편을 드려 죄송합니다. 새로고침 (F5키)을 누르시거나, 잠시 후에 이용해 주십시오.");
                }
            }
        });
    }

    return (
        <div>
            <Form onSubmit={handleSubmit(onSubmitHandler)} className={sendEmail ? "hide" : ""}>
                <Modal.Body>
                    <Row>
                        <Col sm={12} md={3}>
                            <Form.Label>이메일</Form.Label>
                        </Col>
                        <Col sm={12} md={9}>
                            <InputBox label_length={0} name="email" placeholder='가입시 등록하신 이메일을 입력해주세요.' register={register("email")} errors={errors.email?.message ? errors.email?.message : null}/>
                            {/* <Form.Control type="text" name="email" placeholder='가입시 등록하신 이메일을 입력해주세요.' {...register("email")} /> */}
                        </Col>
                    </Row>
                    {/* {
                        errors.email &&
                        <Row>
                            <Col sm={12} md={3}>
                            </Col>
                            <Col className="text-danger" sm={12} md={9}>{errors.email.message}</Col>
                        </Row>
                    } */}
                </Modal.Body>
                <Modal.Footer>
                    <button type="submit" className="btn btn-main rounded-pill btn-center btn-chargeking">찾기</button>
                </Modal.Footer>
            </Form>
            <div className={!sendEmail ? "hide" : ""}>
                <Modal.Body className="text-center">
                    가입하신 이메일로 아이디를 보내드렸습니다.
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={props.onHide} className="btn btn-gray rounded-pill btn-center">확인</button>
                </Modal.Footer>
            </div>
        </div>
    )
}

export default FindId
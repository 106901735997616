import { apiDelete, apiFilePost, apiGet, apiGetPrams, apiPost } from "../api/api";
import {
    LOGIN_USER,
    LOGOUT_USER,
    STORE_MEMBER_USER,
    STORE_COMPANY_USER,
    SHOW_USER,
    FIND_ID,
    UPDATE_MEMBER_USER,
    UPDATE_COMPANY_USER,
    DELETE_USER,
    UPDATE_USER_PASSWORD,
    UPDATE_USER_PAYCARD,
    USER_TEMP_PASSWORD
} from './types';

export function loginUser(data) {
    const request = apiPost('/api/v1/users/login', data)
    .then(response => response.data); //catch로 에러를 잡아야 한다.

    return  {
        type: LOGIN_USER,
        payload: request
    }
}

export function logOutUser() {
    return  {
        type: LOGOUT_USER,
        payload: false
    }
}

export function storeMemberUser(data) {
    const request = apiPost('/api/v1/users/store', data)
    .then(response => response.data);

    return {
        type: STORE_MEMBER_USER,
        payload: request
    }
}

export function storeCompanyUser(data) {
    const request = apiFilePost('/api/v1/users/store', data)
    .then(response => response.data);

    return {
        type: STORE_COMPANY_USER,
        payload: request
    }
}

export function userTempPassword(params) {
    const request = apiGetPrams('/api/v1/users/findPw', params)
    .then(response => response.data);

    return {
        type: USER_TEMP_PASSWORD,
        payload: request
    }
}

export function showUser(id) {
    const request = apiGet('/api/v1/users/show/' + id)
    .then(response => response.data);

    return {
        type: SHOW_USER,
        payload: request
    }
}

export function updateUserPassword(data) {
    const request = apiPost('/api/v1/users/changePassword', data)
    .then(response => response.data);

    return {
        type: UPDATE_USER_PASSWORD,
        payload: request
    }
}

export function updateUserPaycard(data) {
    const request = apiPost('/api/v1/users/updatePaycard', data)
    .then(response => response.data);

    return {
        type: UPDATE_USER_PAYCARD,
        payload: request
    }
}

export function findId(params) {
    const request = apiGetPrams('/api/v1/users/findId', params)
    .then(response => response.data);

    return {
        type: FIND_ID,
        payload: request
    }
}

export function updateMemberUser(data) {
    const request = apiPost("/api/v1/users/update", data)
    .then(response => response.data);

    return  {
        type: UPDATE_MEMBER_USER,
        payload: request
    }
}

export function updateCompanyUser(data) {
   
    const request = apiFilePost("/api/v1/users/update", data)
    .then(response => response.data);

    return  {
        type: UPDATE_COMPANY_USER,
        payload: request
    }
}

export function deleteUser(id) {
    const request = apiDelete("/api/v1/users/delete/" + id)
    .then(response => response.data);

    return {
        type: DELETE_USER,
        payload: request
    }
}
import React from 'react'
import QRpay from '../Layouts/QRpay/QRpay'
import QRpayResult from './Contents/QRpayResult'

function QRpayResultPage() {
  return (
     //<QRpay QRContent={<QRpayResult />}/>
    <QRpayResult/>
  )
}

export default QRpayResultPage
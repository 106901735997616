import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import btnAppStore from '../../../img/btn-appstore.png';
import btnPlayStore from '../../../img/btn-playstore.png';

function QRpayResult() {
    // 로그인 여부 확인 후 이동
    const [user] = useState(useSelector(state => state.user));

    return (
        <main className="qrpay">
           <div className="qrpay-guide pb-5">
               <div className="container margin-container item-center">
                   <div className="top-box">
                        <div className="text-center">
                            <div className="mt-1">QR 인증이 완료되었습니다.</div>
                            <div className="mt-1"><span className="text-evplug">충전기의 안내</span>에 따라</div>
                            <div className="mt-1">충전을 시작해주세요.</div>
                            
                            <div className="card card-body mt-5 mb-5 text-center">
                                {!user.loginReqSuccess && 
                                    <div>
                                        <p className="primary"><b>충전왕 회원</b>으로 가입하세요</p>
                                        <p><span className="primary">훨씬</span> <u className="primary">저렴한 요금</u>으로
                                        <br/>
                                        이용 하실 수 있습니다
                                        </p>
                                        <div className="col text-center mt-3">
                                            <Link to="/register" className="btn btn-main rounded-pill">
                                            회원가입
                                            </Link>
                                        </div>
                                    </div>
                                }      
                                <div className="mt-3">
                                    <p>*충전상태정보는 <span className="primary">충전왕</span>에서
                                    <br/>
                                    확인할 수 있습니다.
                                    </p>
                                </div>
                                <div className="row no-gutters">
                                    <div className="col">
                                        <div className="px-1">
                                            <a href="https://apps.apple.com/ae/app/%EC%B6%A9%EC%A0%84%EC%99%95/id1592635649" traget="_blank">
                                            <img src={btnAppStore} className="img-fluid" alt="btn_app_store"/>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="px-1">
                                            <a href="https://play.google.com/store/apps/details?id=kr.solarconnect.evcharge" traget="_blank">
                                            <img src={btnPlayStore} className="img-fluid" alt="btn_play_store"/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
               </div>
           </div>
        </main>
    )
}

export default QRpayResult
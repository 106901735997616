import React, { useState } from "react";
import { Form, Table } from "react-bootstrap";
import InputBox from "../../components/InputBox/InputBox";
import AddressModal from "../../components/Modal/AddressModal";
import { useDispatch } from "react-redux";
import { reqChargerReceptionValidation } from "../../validations/Validation";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { chargerReception } from "../../_actions/reception_action";
import CheckBox from "../../components/CheckBox/CheckBox";
import StipulationBlock from "../../components/StipulationBlock/StipulationBlock";
import { useNavigate } from "react-router-dom";

function ChargerReceptionPage() {
  const dispatch = useDispatch();
  const schema = reqChargerReceptionValidation;
  const [modalAddressShow, setModalAddressShow] = useState(false);
  const navigate = useNavigate();

  const [agree, setAgree] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const addressModal = (modal) => {
    setModalAddressShow([modalAddressShow]);
  };

  const onAddressModalHandler = (event) => {
    if (!modalAddressShow) {
      setModalAddressShow(true);
    }
  };

  const onChangeAgreeHandler = (event) => {
    setAgree(!agree);
  };

  const onSubmithandler = (event) => {
    if (!agree) {
      alert("개인정보 수집 및 이용동의의 동의가 필수입니다.");
    } else {
      let body = {
        nm: event.nm,
        cttpc: event.cttpc.replace(/ /gi, "").replace(/-/gi, ""),
        email: event.email,
        rdnmadr: event.rdnmadr,
        adres: event.adres,
        adresDetail: event.adres_detail,
        ty: event.ty,
        placeTy: event.place_ty,
        sideTy: event.side_ty,
        carCnt: event.car_cnt,
        parkingCnt: event.parking_cnt,
        slowChrgrCnt: event.slow_chrgr_cnt,
        fastChrgrCnt: event.fast_chrgr_cnt,
      };

      dispatch(chargerReception(body)).then((response) => {
        if (response.payload.result === "OK") {
          //window.location.replace("/receptions/complete");
          navigate("/receptions/complete", { replace: true });
        } else {
          if (response.payload.data.validation) {
            alert(
              "[" +
                response.payload.data.validation.error +
                "] " +
                response.payload.data.validation.msg
            );
          } else {
            alert(
              "[페이지 오류 안내] 홈페이지 이용에 불편을 드려 죄송합니다. 새로고침 (F5키)을 누르시거나, 잠시 후에 이용해 주십시오."
            );
            //let urlarr = window.location.href.split("/");
            //window.location.href = urlarr[0] + "/";
            navigate("/");
          }
        }
      });
    }
  };

  return (
    <main className="reception">
      <div className="reception-guide mb-5 container">
        <section className="section-reception">
          <div className="text-center mb-4">
            <h3 className="d-none d-lg-block">충전기 설치 신청</h3>
            <h5 className="d-block d-lg-none">충전기 설치 신청</h5>
          </div>
          {/* <div className="text-center mb-3">
              충전기 설치 신청 고객에게 충전기 설치 비용을 국가보조금으로 지원합니다.
            </div>
            <div className="row reception-guide mb-3">
              <div className="col-6 col-lg-2 top-box d-none d-lg-block">
                <div className="row">
                  <div className="col-12 col-lg-12 mb-3">
                    <img src={imgType1} alt=""/>
                  </div>
                  <div className="mb-3">
                    <Badge pill bg="warning">STEP 01</Badge>
                  </div>
                  <div className="col-12 col-lg-12">
                    <h5>신청/문의</h5>
                    <p className="text-muted">충전기 <span>설치 신청서</span>를 작성해주세요.</p>
                  </div>
                </div>
              </div>
              <div className="col-6 col-lg-2 top-box d-none d-lg-block">
                <div className="row">
                  <div className="col-12 col-lg-12 mb-3">
                    <img src={imgType1} alt=""/>
                  </div>
                  <div className="mb-3">
                  <Badge pill bg="warning">STEP 02</Badge>
                  </div>
                  <div className="col-12 col-lg-12">
                    <h5>상담진행</h5>
                    <p className="text-muted">입력하신 정보를 바탕으로 담당자가 연락드립니다.</p>
                  </div>
                </div>
              </div>
              <div className="col-6 col-lg-2 top-box d-none d-lg-block">
                <div className="row">
                  <div className="col-12 col-lg-12 mb-3">
                    <img src={imgType1} alt=""/>
                  </div>
                  <div className="mb-3">
                  <Badge pill bg="warning">STEP 03</Badge>
                  </div>
                  <div className="col-12 col-lg-12">
                    <h5>현장 실사</h5>
                    <p className="text-muted">-</p>
                  </div>
                </div>
              </div>
              <div className="col-6 col-lg-2 top-box d-none d-lg-block">
                <div className="row">
                  <div className="col-12 col-lg-12 mb-3">
                    <img src={imgType1} alt=""/>
                  </div>
                  <div className="mb-3">
                  <Badge pill bg="warning">STEP 04</Badge>
                  </div>
                  <div className="col-12 col-lg-12">
                    <h5>보조금 신청</h5>
                    <p className="text-muted">-</p>
                  </div>
                </div>
              </div>
              <div className="col-6 col-lg-2 top-box d-none d-lg-block">
                <div className="row">
                  <div className="col-12 col-lg-12 mb-3">
                    <img src={imgType1} alt=""/>
                  </div>
                  <div className="mb-3">
                  <Badge pill bg="warning">STEP 05</Badge>
                  </div>
                  <div className="col-12 col-lg-12">
                    <h5>충전기 설치</h5>
                    <p className="text-muted">-</p>
                  </div>
                </div>
              </div>
            </div> */}
          <section className="row justify-content-center margin-row ">
            <Form onSubmit={handleSubmit(onSubmithandler)}>
              <Table responsive>
                <thead>
                  <tr>
                    <td colSpan={2}>
                      <h5>설치신청서 작성</h5>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>
                      신청자 <span className="text-danger">*</span>
                    </th>
                    <td>
                      <InputBox
                        label_length="0"
                        name="nm"
                        placeholder="이름이나 사업자명을 입력해주세요."
                        register={register("nm")}
                        errors={errors.nm?.message ? errors.nm?.message : null}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      연락처 <span className="text-danger">*</span>
                    </th>
                    <td>
                      <InputBox
                        label_length="0"
                        name="cttpc"
                        placeholder="연락처를 입력해주세요."
                        register={register("cttpc")}
                        errors={
                          errors.cttpc?.message ? errors.cttpc?.message : null
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      이메일 <span className="text-danger">*</span>
                    </th>
                    <td>
                      <InputBox
                        label_length="0"
                        name="email"
                        placeholder="이메일을 입력해주세요."
                        register={register("email")}
                        errors={
                          errors.email?.message ? errors.email?.message : null
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      개방형태 <span className="text-danger">*</span>
                    </th>
                    <td>
                      <div>
                        <Form.Check
                          inline
                          label="공용"
                          name="ty"
                          {...register("ty")}
                          type="radio"
                          id="OFFICIAL_USE"
                          value="OFFICIAL_USE"
                          isInvalid={!!errors.ty}
                          className={errors.ty?.message && "is-invalid"}
                        />
                        <Form.Check
                          inline
                          label="비공용"
                          name="ty"
                          type="radio"
                          id="NON_OFFICIAL_USE"
                          value="NON_OFFICIAL_USE"
                          {...register("ty")}
                          isInvalid={errors.ty}
                          className={errors.ty?.message && "is-invalid"}
                        />
                        <Form.Check
                          inline
                          label="알수없음"
                          name="ty"
                          type="radio"
                          id="UNKNOWN"
                          value="UNKNOWN"
                          {...register("ty")}
                          isInvalid={errors.ty}
                          className={errors.ty?.message && "is-invalid"}
                        />
                        {errors.ty?.message && (
                          <div className="invalid-feedback">
                            {errors.ty?.message}
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      설치장소 <span className="text-danger">*</span>
                    </th>
                    <td>
                      <div key="inline-radio">
                        <Form.Check
                          inline
                          label="공동주택"
                          name="place_ty"
                          type="radio"
                          id="SHARE"
                          value="SHARE"
                          {...register("place_ty")}
                          isInvalid={errors.place_ty}
                          className={errors.place_ty?.message && "is-invalid"}
                        />
                        <Form.Check
                          inline
                          label="관공서"
                          name="place_ty"
                          type="radio"
                          id="GOVERNMENT"
                          value="GOVERNMENT"
                          {...register("place_ty")}
                          isInvalid={errors.place_ty}
                          className={errors.place_ty?.message && "is-invalid"}
                        />
                        <Form.Check
                          inline
                          label="공영 및 관광지 주차장"
                          name="place_ty"
                          type="radio"
                          id="PUBLIC_SERVICE"
                          value="PUBLIC_SERVICE"
                          {...register("place_ty")}
                          isInvalid={errors.place_ty}
                          className={errors.place_ty?.message && "is-invalid"}
                        />
                        <Form.Check
                          inline
                          label="업무시설 주차장"
                          name="place_ty"
                          type="radio"
                          id="OFFICE"
                          value="OFFICE"
                          {...register("place_ty")}
                          isInvalid={errors.place_ty}
                          className={errors.place_ty?.message && "is-invalid"}
                        />
                        <Form.Check
                          inline
                          label="상업시설 주차장"
                          name="place_ty"
                          type="radio"
                          id="COMMERCE"
                          value="COMMERCE"
                          {...register("place_ty")}
                          isInvalid={errors.place_ty}
                          className={errors.place_ty?.message && "is-invalid"}
                        />
                        <Form.Check
                          inline
                          label="기타시설"
                          name="place_ty"
                          type="radio"
                          id="OTHER"
                          value="OTHER"
                          {...register("place_ty")}
                          isInvalid={errors.place_ty}
                          className={errors.place_ty?.message && "is-invalid"}
                        />
                        {errors.place_ty?.message && (
                          <div className="invalid-feedback">
                            {errors.place_ty?.message}
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      옥외/옥내 <span className="text-danger">*</span>
                    </th>
                    <td>
                      <div key="inline-radio">
                        <Form.Check
                          inline
                          label="옥외"
                          name="side_ty"
                          type="radio"
                          id="OUTSIDE"
                          value="OUTSIDE"
                          {...register("side_ty")}
                          isInvalid={errors.side_ty}
                          className={errors.side_ty?.message && "is-invalid"}
                        />
                        <Form.Check
                          inline
                          label="옥내"
                          name="side_ty"
                          type="radio"
                          id="INSIDE"
                          value="INSIDE"
                          {...register("side_ty")}
                          isInvalid={errors.side_ty}
                          className={errors.side_ty?.message && "is-invalid"}
                        />
                        <Form.Check
                          inline
                          label="알수없음"
                          name="side_ty"
                          type="radio"
                          id="SIDETYUNKNOWN"
                          value="UNKNOWN"
                          {...register("side_ty")}
                          isInvalid={errors.side_ty}
                          className={errors.side_ty?.message && "is-invalid"}
                        />
                        {errors.side_ty?.message && (
                          <div className="invalid-feedback">
                            {errors.side_ty?.message}
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      설치장소 주소 <span className="text-danger">*</span>
                    </th>
                    <td>
                      <InputBox
                        label_length="0"
                        name="rdnmadr"
                        placeholder="클릭하여 입력해주세요."
                        readonly={true}
                        onClickHandler={onAddressModalHandler}
                        register={register("rdnmadr")}
                        errors={
                          errors.rdnmadr?.message
                            ? errors.rdnmadr?.message
                            : null
                        }
                      />
                      <input
                        name="adres"
                        type="hidden"
                        {...register("adres")}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th></th>
                    <td>
                      <InputBox
                        label_length="0"
                        name="adres_detail"
                        placeholder="상세주소"
                        register={register("adres_detail")}
                        errors={
                          errors.adres_detail?.message
                            ? errors.adres_detail?.message
                            : null
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      보유 전기차 수 <span className="text-danger">*</span>
                    </th>
                    <td>
                      <InputBox
                        type="number"
                        label_length="0"
                        name="car_cnt"
                        placeholder="숫자를 입력해주세요."
                        register={register("car_cnt")}
                        errors={
                          errors.car_cnt?.message
                            ? errors.car_cnt?.message
                            : null
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>설치장소 주차면 수</th>
                    <td>
                      <InputBox
                        type="number"
                        label_length="0"
                        name="parking_cnt"
                        placeholder="숫자를 입력해주세요."
                        register={register("parking_cnt")}
                        errors={
                          errors.parking_cnt?.message
                            ? errors.parking_cnt?.message
                            : null
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>완속(7kW) 신청 수량</th>
                    <td>
                      <InputBox
                        type="number"
                        label_length="0"
                        name="slow_chrgr_cnt"
                        placeholder="숫자를 입력해주세요."
                        register={register("slow_chrgr_cnt")}
                        errors={
                          errors.slow_chrgr_cnt?.message
                            ? errors.slow_chrgr_cnt?.message
                            : null
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>급속(50kW 이상) 신청 수량</th>
                    <td>
                      <InputBox
                        type="number"
                        label_length="0"
                        name="fast_chrgr_cnt"
                        placeholder="숫자를 입력해주세요."
                        register={register("fast_chrgr_cnt")}
                        errors={
                          errors.fast_chrgr_cnt?.message
                            ? errors.fast_chrgr_cnt?.message
                            : null
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      개인정보 수집 및 이용 동의{" "}
                      <span className="text-danger">*</span>
                    </th>
                    <td>
                      <div className="padding-if">
                        <StipulationBlock
                          page="join"
                          title1="충전왕"
                          title2="개인정보 수집 및 이용 동의"
                          ty="policy-station"
                        />
                      </div>
                      {/* * 개인정보 취급방침
                        <ul>
                          개인정보 수집 및 이용에 대한 안내
                          <li>수집 주체 : 엔라이튼 주식회사</li>
                          <li>수집 항목 : 신청자, 연락처, 이메일, 설치주소, 개인정보 취급방침</li>
                          <li>수집 목적 : 공용충전기 신청 및 결과 회신</li>
                          <li>이용 기간 : 원칙적으로, 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체없이 파기합니다. 단, 관계법령의 규정에 의하여 보존할 필요가 있는 경우 아래와 같이 관계법령에서 정한 일정 기간동안 개인정보를 보관할 수 있습니다.</li>
                        
                        </ul>
                        <p>- 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 (전자상거래등에서의 소비자보호에 관한 법률)</p>
                        <p>- 위의 개인정보 소집 및 이용에 동의합니다.(동의 후 문의가 가능합니다.)</p> */}
                      <CheckBox
                        name="agree"
                        label="동의합니다."
                        onCheck={onChangeAgreeHandler}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
              <div>
                <button
                  type="submit"
                  className="btn btn-main rounded-pill btn-center btn-chargeking"
                >
                  설치 신청
                </button>
              </div>
            </Form>
          </section>
        </section>
      </div>

      <AddressModal
        addressModal={addressModal}
        show={modalAddressShow}
        onHide={() => setModalAddressShow(false)}
        setModalAddressShow={setModalAddressShow}
        setValue={setValue}
      />
    </main>
  );
}

export default ChargerReceptionPage;

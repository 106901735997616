import { GET_CAR_LIST } from "./types";
import { apiGet } from "../api/api";

export function getCarList(data) {
  const request = apiGet("/api/v1/cars").then((response) => response.data);

  return {
    type: GET_CAR_LIST,
    payload: request,
  };
}

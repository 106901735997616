import React from 'react';
import imgType2 from '../../img/img-type2.png';

function SubsidyPage() {
  return (
    <main className="common-index">
      <div className="container">
        <section className="section-4">
          <div className="section-4-title text-center position-relative">
            충전기 설치 정부 지원
          </div>
          
          <div className="text-center mb-3 col-12">
            충전기 설치 신청 고객에게 충전기 설치 비용을 국가보조금으로 지원합니다.
          </div>

          <div className="row my-5 text-center charger-info">
            <div className="col-12 col-lg-8 offset-lg-2">
              <img src={imgType2} alt="" className="m-4" />
              <h4>완속충전기 설치 보조금</h4>
              <table className="table">
                <thead>
                  <tr>
                    <td colSpan="4" className="text-right unit py-1">(단위 : 만원)</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th className="first head" width="25%">구분</th>
                    <th className="head" width="25%">1기</th>
                    <th className="head" width="25%">2~5기</th>
                    <th className="head" width="25%">6기이상</th>
                  </tr>
                  <tr>
                    <th className="first">완속충전기<br/>(C타입)</th>
                    <td className="border-right">200</td>
                    <td className="border-right">180</td>
                    <td>150</td>
                  </tr>
                  <tr>
                    <th className="first">과금형콘센트</th>
                    <td colSpan="3">50</td>
                  </tr>
                  <tr>
                    <th className="first">키오스크 충전기</th>
                    <td colSpan="3">100</td>
                  </tr>
                </tbody>
              </table>
              <br />
              <p>동일 장소에 설치하는 충전기 수량에 따라 지원단가 차등 지원함</p>
            </div>
          </div>
          <div className="row my-5 text-left charger-info">
              <ul className="lst_sty">
                <li>충전기 설치 보조금은 지원 가능한 최대 금액을 말하며, 보조금 한도를 초과하여 발생한 충전기 설치 비용은 설치희망자가 부담함.</li>
                <li>키오스크 형식의 완속충전기(C타입)는 기본 2기(보조금 200만원)을 지원하며, 1기 추가 시 추가보조금 100만원을 지원함.</li>
                <li>과금형콘센트의 경우 기존 전기배선을 활용한 단순교체가 가능함. 단, 누전 및 화재 등의 안전사고가 발생하지 않도록 충분한 안전조치를 취해야 하며 과금형 콘센트 운영 사업수행기관은 충전정보의 교환, 전력 분배 또는 차단에 대한 조치를 하여야 함.</li>
                <li>동일 장소에 설치된 과금형콘센트는 모두 동시에 충전기 가능하여야 하며, 하나의 차단기에 최대 2기의 과금형콘센트만 설치 가능.</li>
              </ul>
          </div>

          <div className="row my-5 text-center charger-info">
            <div className="col-12 col-lg-10 offset-lg-1">
              <h4>‘21년도 완속 공용 충전시설 설치수량 산정기준(완속충전기 기준)</h4>
              <table className="table">
                <tbody>
                  <tr >
                    <td colSpan="4" className="text-right unit py-1"></td>
                  </tr>
                  <tr>
                    <th className="first head" colSpan="2">구분</th>
                    <th className="p-2 head">설치 가능 수량</th>
                  </tr>
                  <tr>
                    <th colSpan="2" className="text-left bg-white pl-3 border-right">1) 해당 장소에 설치된 충전기가 없는 경우</th>
                    <td rowSpan="3">주차면수 x 2%</td>
                  </tr>
                  <tr>
                    <th colSpan="1" className="p-2">상반기</th>
                    <th className="bg-white text-left pl-3 border-right">상시 이용 전기차가 있는 경우에만 지원 가능<br/>
                      주차면수 x 2% (해당 장소의 주차단위구획 수의 2% 이내)</th>
                  </tr>
                  <tr>
                    <th colSpan="1" className="p-2">하반기</th>
                    <th className="bg-white text-left pl-3 border-right">전기차 보유 여부 관계 없이 지원 가능<br/>
                      (해당 장소의 주차단위구획 수의 2% 이내) </th>
                  </tr>
                  <tr>
                    <th colSpan="2" className="text-left bg-white pl-3 border-right">2) 해당 장소에 이미 설치된 충전기가 있는 경우</th>
                    <td rowSpan="3">(주차면수 x 2%)<br/>이미 설치된 충전시설 수</td>
                  </tr>
                  <tr>
                    <th colSpan="2" className="text-left bg-white pl-3 border-right">해당 장소의 주차단위구획 수의 2% 이내 - 기 설치된 충전기 수</th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="row my-5 text-left charger-info">
            <ol className="lst_sty2">
              <li>1)에서 소수점 이하는 올림하여 처리한다.</li>
              <li>키오스크 충전기는 계산된 설치가능 수량 2를 곱한 수만큼, 과금형콘센트는 4를 곱한 수만큼 지원 가능하다.</li>
              <li>기 설치된 급속충전기, 완속충전기는 1기로 산정(환경부에서 지원받은 급속충전기는 10기로 산정)하고, 키오스크 충전기는 2기당 완속충전기 1기, 과금형콘센트는 4기당 완속충전기 1기 산정한다.</li>
              <li>1)과 2)를 통해 산정된 설치가능 수량은 완속충전기는 최대 5기, 키오스크 충전기는 최대 10기, 과금형콘센트는 최대 20기를 초과할 수 없다.</li>
              <li> 4.에도 불구하고, 설치 희망자(장소)의 상시이용 전기차(주민등록지 등 증빙이 가능한 경우에 한함) 대수를 2로 나눈 값이 설치가능 수량을 초과하는 경우 초과분만큼 추가로 지원할 수 있으며 , 키오스크 충전기는 초과분에 2를 곱한수만큰, 과금형콘센트는 4를 곱한수만큼 추가로 지원할 수 있다.
                <br/>(소수점 이하는 올림하여 지원)
              </li>
            </ol>
          </div>
        </section>
      </div>
    </main>
  )
}

export default SubsidyPage
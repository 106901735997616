import React, { useEffect, useState } from 'react';
import { Form, FormControl, InputGroup } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { certifyAuthCode, certifyReqPhone } from '../../../_actions/certify_action';
import { Modal } from 'react-bootstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { certifyAuthCodeValidation, findPasswordValidation } from '../../../validations/Validation';
import Timer from '../../Timer/Timer';

function CertifyPhone(props) {
    const dispatch = useDispatch();
    const [schema, setSchema] = useState(findPasswordValidation);
    const [timer, setTimer] = useState(false);
    const [certifyPhone, setCertifyPhone] = useState("");
    const setPhone = props.setPhone;
    const setIsCertifyPhone = props.setIsCertifyPhone;
    const { register, handleSubmit, formState:{ errors } } = useForm({
        resolver: yupResolver(schema)
    });

    const [sendAuthCode, setsendAuthCode] = useState(false); // 인증 번호 전송 여부

    const onSubmitSendHandler = (event) => {
        let params = {
            ty: "AUTH",
            phone: (event.phone.replace(/ /gi, "")).replace(/-/gi, ""),
        }

        dispatch(certifyReqPhone(params))
        .then(response => {
            if (response.payload.result === "OK") {
                setSchema(certifyAuthCodeValidation);
                setsendAuthCode(true);
                setCertifyPhone(event.phone);
            } else {
                if (response.payload.msg === "exist") {
                    if (response.payload.data.data === "phone") {
                        alert("이미 가입되어 있는 핸드폰 번호 입니다.");
                    } else if (response.payload.data.data === "email") {
                        alert("이미 가입되어 있는 이메일 입니다.");
                    } else {
                        alert("이미 가입되어 있는 " + response.payload.data.data + " 입니다.");
                    }
                } else if (response.payload.data.validation) {
                    alert("[" + response.payload.data.validation.error + "] " + response.payload.data.validation.msg);
                } else {
                    alert("[페이지 오류 안내] 홈페이지 이용에 불편을 드려 죄송합니다. 새로고침 (F5키)을 누르시거나, 잠시 후에 이용해 주십시오.");
                }
            }
        });
    }

    const onSubmitCertifyHandler = (event) => {
        if (sendAuthCode) {
            let params = {
                authCode: event.authCode
            }
    
            dispatch(certifyAuthCode(params))
            .then(response => {
                if (response.payload.result === "OK") {
                    alert("인증이 완료되었습니다.");
                    setsendAuthCode(false);
                    setPhone(certifyPhone);
                    props.setValue("phone", certifyPhone, { shouldValidate: true });
                    setIsCertifyPhone(true);
                    props.onHide();
                } else {
                    if (response.payload.msg === "exist") {
                        alert("인증에 실패하였습니다.");
                    } else if (response.payload.data.validation) {
                        alert("[" + response.payload.data.validation.error + "] " + response.payload.data.validation.msg);
                    } else {
                        alert("[페이지 오류 안내] 홈페이지 이용에 불편을 드려 죄송합니다. 새로고침 (F5키)을 누르시거나, 잠시 후에 이용해 주십시오.");
                    }
                }
            });
        } else {
            alert("인증번호가 전송되지 않았습니다.");
        }
    }

    useEffect(() => {
        if (timer) {
            alert("인증 제한 시간이 만료되었습니다. 인증 버튼을 다시 클릭해주세요.");
            setTimer(false);
            setSchema(findPasswordValidation);
            setsendAuthCode(false);
        }
    }, [timer]);

    return (
        <div>
            <Modal.Body>
                <Form onSubmit={handleSubmit(onSubmitSendHandler)} className="mb-3">
                    <InputGroup>
                        <FormControl
                            name="phone"
                            {...register("phone")}
                            className={"modal-input " + (errors.phone?.message ? "is-invalid" : "")}
                            placeholder='숫자만 입력해주세요.'
                            readOnly={sendAuthCode ? true : false}
                        />
                        <div className="tail-button">
                            <button type="submit" className="btn btn-secondary rounded-pill btn-center ml-2" disabled={sendAuthCode ? true : false}>인증번호 전송</button>
                        </div>
                        {
                            errors.phone?.message &&
                            <div className="invalid-feedback">{errors.phone?.message}</div>
                        }
                    </InputGroup>
                    {/* <InputBox label_length={0} name="phone" type="text" placeholder='숫자만 입력해주세요.' tailButtonLabel="인증번호 전송" buttonType="submit" register={register("phone")} errors={errors.phone?.message ? errors.phone?.message : null}/> */}
                </Form>
                <Form onSubmit={handleSubmit(onSubmitCertifyHandler)}>
                    <InputGroup>
                        <FormControl
                            name="authCode"
                            {...register("authCode")}
                            className={errors.authCode?.message && "is-invalid"}
                            placeholder='인증번호를 입력해주세요.'
                        />
                        <div className="tail-button">
                            <button type="submit" className="btn btn-secondary rounded-pill btn-center ml-2">인증번호 확인</button>
                        </div>
                        {
                            errors.authCode?.message &&
                            <div className="invalid-feedback">{errors.authCode?.message}</div>
                        }
                    </InputGroup>
                    {/* <InputBox label_length={0} name="authCode" type="text" placeholder='인증번호를 입력해주세요.' tailButtonLabel="인증번호 확인" buttonType="submit" register={register("authCode")} errors={errors.authCode?.message ? errors.authCode?.message : null}/> */}
                </Form>
                <div className={"mt-2 " + (!sendAuthCode ? "hide" : "")}>
                    <small className="text-danger">
                        인증번호 유효시간: {sendAuthCode && <Timer mm={3} ss={0} setTimer={setTimer}></Timer>}
                    </small>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={props.onHide} className="btn btn-main rounded-pill btn-center btn-chargeking">닫기</button>
            </Modal.Footer>
        </div>
    )
}

export default CertifyPhone
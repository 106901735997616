import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

import React, { useEffect, useState } from "react";
import { Form, FormControl, InputGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import arrowGo from "../../img/arrow-go.png";
import Notice from "./Content/Notice";
import Page from "../Pagination/Page";
import Charging from "./Content/Charging";

import { enGB } from "date-fns/locale";
import { DateRangePicker, START_DATE, END_DATE } from "react-nice-dates";
import { useDispatch } from "react-redux";
import { getArticleList, getLandingNoticeList } from "../../_actions/article_action";
import { getChargingList } from "../../_actions/charging_action";
import { UtctoLocaleFormmatter } from "../../utils/Utils";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { chargingRangeValidation } from "../../validations/Validation";

function ListBoard(props) {
  let navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);

  const schema = chargingRangeValidation;
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [currentPage, setCurrentPage] = useState(params.get("currentPage") ?? 1);

  const cPage = params.get("currentPage");
  const perPage = 10; // 기본
  const boardTitle = props.boardTitle ?? "게시판";
  const isMore = props.isMore ?? false;
  const moreLink = props.moreLink ?? false;
  const search = props.search ?? false;
  const pagination = props.pagination ?? false;
  const boardType = props.boardType;
  const userId = props.userId ?? false;

  const [data, setData] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [keyword, setKeyword] = useState("");

  const onKeyDownHandler = (e) => {
    if (e.key === "Enter") {
      onClickSearchHandler();
    }
  };

  useEffect(() => {
    // 초기 컴포넌트가 마운트 되었을 때 호출

    // pagination이 없는 경우 => LandingNoticeList 호출
    if (!pagination && boardType === "notice") {
      dispatch(getLandingNoticeList()).then((response) => {
        if (response.payload.result === "OK") {
          setData(response.payload.data.data);
        } else {
          alert("[페이지 오류 안내] 홈페이지 이용에 불편을 드려 죄송합니다. 새로고침 (F5키)을 누르시거나, 잠시 후에 이용해 주십시오.");
        }
      });
    } else if (pagination && boardType === "notice") {
      let params = {
        ty: "NOTICE,GUIDE",
        currentPage: currentPage,
        perPage: perPage,
      };
      dispatch(getArticleList(params)).then((response) => {
        if (response.payload.result === "OK") {
          setData(response.payload.data.data);
          setTotalPage(parseInt(response.payload.data.totalPage));
        } else {
          if (response.payload.msg === "exist") {
            alert("[페이지 오류 안내] 존재하지 않는 게시글입니다.");
            let urlarr = window.location.href.split("/");
            window.location.href = urlarr[0] + "/";
          } else {
            alert("[페이지 오류 안내] 홈페이지 이용에 불편을 드려 죄송합니다. 새로고침 (F5키)을 누르시거나, 잠시 후에 이용해 주십시오.");
            let urlarr = window.location.href.split("/");
            window.location.href = urlarr[0] + "/";
          }
        }
      });
    } else if (pagination && boardType === "charging") {
      let params = {
        userId: userId,
        currentPage: currentPage,
        perPage: perPage,
      };
      dispatch(getChargingList(params)).then((response) => {
        if (response.payload.result === "OK") {
          setData(response.payload.data.data);
          setTotalPage(parseInt(response.payload.data.totalPage));
        } else {
          alert("[페이지 오류 안내] 홈페이지 이용에 불편을 드려 죄송합니다. 새로고침 (F5키)을 누르시거나, 잠시 후에 이용해 주십시오.");
          let urlarr = window.location.href.split("/");
          window.location.href = urlarr[0] + "/";
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeKeywordHandler = (event) => {
    setKeyword(event.currentTarget.value);
  };

  const onClickSearchHandler = (event) => {
    let params = {
      currentPage: 1,
      search: keyword,
      perPage: perPage,
      ty: boardType.toUpperCase(),
    };

    dispatch(getArticleList(params)).then((response) => {
      if (response.payload.result === "OK") {
        setData(response.payload.data.data);
        setTotalPage(parseInt(response.payload.data.totalPage));
        navigate("?currentPage=1&search=" + keyword);
      } else {
        if (response.payload.msg === "exist") {
          alert("[페이지 오류 안내] 존재하지 않는 게시글입니다.");
          let urlarr = window.location.href.split("/");
          window.location.href = urlarr[0] + "/";
        } else {
          alert("[페이지 오류 안내] 홈페이지 이용에 불편을 드려 죄송합니다. 새로고침 (F5키)을 누르시거나, 잠시 후에 이용해 주십시오.");
          let urlarr = window.location.href.split("/");
          window.location.href = urlarr[0] + "/";
        }
      }
    });
  };

  const onSubmitRangeHandler = (event) => {
    let params = {
      userId: userId,
      perPage: perPage,
      currentPage: 1,
    };

    if (startDate) {
      params = {
        ...params,
        startDts: UtctoLocaleFormmatter(startDate),
      };
    }

    if (endDate) {
      params = {
        ...params,
        startDte: UtctoLocaleFormmatter(endDate),
      };
    }

    dispatch(getChargingList(params)).then((response) => {
      if (response.payload.result === "OK") {
        setData(response.payload.data.data);
        setTotalPage(parseInt(response.payload.data.totalPage));
        navigate("?currentPage=1");
      } else {
        alert("[페이지 오류 안내] 홈페이지 이용에 불편을 드려 죄송합니다. 새로고침 (F5키)을 누르시거나, 잠시 후에 이용해 주십시오.");
        let urlarr = window.location.href.split("/");
        window.location.href = urlarr[0] + "/";
      }
    });
  };

  // 페이지 클릭
  const onClickPageChangeHandler = (page) => {
    setCurrentPage(page);
    if (params.get("search")) {
      navigate("?currentPage=" + page + "&search=" + params.get("search"));
    } else {
      navigate("?currentPage=" + page);
    }
  };

  useEffect(() => {
    if (pagination && boardType === "notice") {
      let param = {
        ty: "NOTICE,GUIDE",
        currentPage: cPage,
        perPage: perPage,
      };

      if (params.get("search")) {
        setKeyword(params.get("search"));

        param = {
          ...param,
          search: params.get("search"),
        };
      } else {
        setKeyword("");
      }

      dispatch(getArticleList(param)).then((response) => {
        if (response.payload.result === "OK") {
          setData(response.payload.data.data);
          setCurrentPage(parseInt(response.payload.data.currentPage));
          setTotalPage(parseInt(response.payload.data.totalPage));
        } else {
          if (response.payload.msg === "exist") {
            alert("[페이지 오류 안내] 존재하지 않는 게시글입니다.");
            let urlarr = window.location.href.split("/");
            window.location.href = urlarr[0] + "/";
          } else {
            alert("[페이지 오류 안내] 홈페이지 이용에 불편을 드려 죄송합니다. 새로고침 (F5키)을 누르시거나, 잠시 후에 이용해 주십시오.");
            let urlarr = window.location.href.split("/");
            window.location.href = urlarr[0] + "/";
          }
        }
      });
    } else if (boardType === "charging") {
      let params = {
        userId: userId,
        perPage: perPage,
        currentPage: cPage,
      };

      if (startDate) {
        params = {
          ...params,
          startDts: UtctoLocaleFormmatter(startDate),
        };
      }

      if (endDate) {
        params = {
          ...params,
          startDte: UtctoLocaleFormmatter(endDate),
        };
      }

      dispatch(getChargingList(params)).then((response) => {
        if (response.payload.result === "OK") {
          setData(response.payload.data.data);
          setCurrentPage(parseInt(response.payload.data.currentPage));
          setTotalPage(parseInt(response.payload.data.totalPage));
        } else {
          alert("[페이지 오류 안내] 홈페이지 이용에 불편을 드려 죄송합니다. 새로고침 (F5키)을 누르시거나, 잠시 후에 이용해 주십시오.");
          let urlarr = window.location.href.split("/");
          window.location.href = urlarr[0] + "/";
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, cPage, params.get("search")]);

  return (
    <div className="container">
      <div className="section-4-title text-center position-relative">
        {boardTitle}

        {
          {
            keyword: (
              <div className="row item-right">
                <div className="col-sm-5 col-lg-3 mt-3">
                  <InputGroup>
                    <FormControl name="search" placeholder="검색어" value={keyword} onChange={onChangeKeywordHandler} onKeyDown={onKeyDownHandler} />
                    <button variant="outline-secondary" className="btn btn-search" id="button-addon2" onClick={onClickSearchHandler}>
                      <FontAwesomeIcon icon={faMagnifyingGlass} />
                    </button>
                  </InputGroup>
                </div>
              </div>
            ),

            range: (
              <Form onSubmit={handleSubmit(onSubmitRangeHandler)}>
                <div className="picker item-right">
                  <DateRangePicker
                    startDate={startDate}
                    endDate={endDate}
                    onStartDateChange={(value) => {
                      setStartDate(value);
                      setValue("startDate", value, { shouldValidate: true });
                    }}
                    onEndDateChange={(value) => {
                      setEndDate(value);
                      setValue("endDate", value, { shouldValidate: true });
                    }}
                    // minimumDate={new Date()}
                    minimumLength={0}
                    format="yyyy-MM-dd"
                    locale={enGB}
                  >
                    {({ startDateInputProps, endDateInputProps, focus }) => (
                      <div className="date-range">
                        <div className="col-5 startDt">
                          <input
                            {...register("startDate")}
                            className={
                              (errors.anotherFiled?.message ? "is-invalid " : "") +
                              "form-control begin-dt" +
                              (focus === START_DATE ? " -focused" : "")
                            }
                            name="startDate"
                            {...startDateInputProps}
                            placeholder="충전시작일"
                          />
                          {errors.anotherFiled?.message && <div className="invalid-feedback">{errors.anotherFiled?.message}</div>}
                        </div>

                        <span className="date-range_arrow"> ~ </span>
                        <div className="col-5">
                          <input
                            {...register("endDate")}
                            className={
                              (errors.anotherFiled?.message ? "is-invalid " : "") + "form-control end-dt" + (focus === END_DATE ? " -focused" : "")
                            }
                            name="endDate"
                            {...endDateInputProps}
                            placeholder="충전시작일"
                          />
                          {/* {
                                                errors.anotherFiled?.message &&
                                                <div className="invalid-feedback">{errors.anotherFiled?.message}</div>
                                            } */}
                        </div>
                      </div>
                    )}
                  </DateRangePicker>
                  <button type="submit" className="btn btn-main rounded-pill">
                    조회
                  </button>
                </div>
              </Form>
            ),
          }[search]
        }

        {isMore && (
          <Link to={moreLink} className="position-absolute btn-more">
            더보기 <img src={arrowGo} alt="arrow_go" />
          </Link>
        )}
      </div>
      <div className="list-board">
        {
          {
            notice: (
              <div>
                <div className="list-head text-center">
                  <div className="row">
                    <div className="col-9 col-sm-9">제목</div>
                    <div className="col-3 col-sm-3">작성일</div>
                  </div>
                </div>
                {data.length !== 0 ? (
                  data.map((data, key) => {
                    switch (boardType) {
                      case "notice":
                        return <Notice key={data.id} ty={data.ty} id={data.id} subjectTitle={data.sj} subjectDate={data.regDt} />;
                      default:
                        return null;
                    }
                  })
                ) : (
                  <div className="list">
                    <div className="list-subject text-center">작성된 글이 존재하지 않습니다.</div>
                  </div>
                )}
              </div>
            ),

            charging: (
              <div className="min-table">
                <div className="list-head text-center">
                  <div className="row">
                    <div className="col-2">충전소명</div>
                    <div className="col-2">충전기번호</div>
                    <div className="col-3">충전시간 (시작시간 ~ 종료시간)</div>
                    <div className="col-2">충전량(kWh)</div>
                    <div className="col-1">충전금액(원)</div>
                    <div className="col-2">결제상태</div>
                  </div>
                </div>
                {data.length !== 0 ? (
                  data.map((data, key) => (
                    <Charging
                      key={key}
                      // data = {article}
                      stationNm={data.stationNm}
                      chrgrIdntfr={data.chrgrIdntfr}
                      beginDt={data.beginDt}
                      endDt={data.endDt}
                      elctcQy={data.elctcQy}
                      elctcPc={data.elctcPc}
                      paySttus={data.paySttus}
                    />
                  ))
                ) : (
                  <div className="list">
                    <div className="list-subject text-center">충전내역이 존재하지 않습니다.</div>
                  </div>
                )}
              </div>
            ),
          }[boardType]
        }
      </div>
      {pagination && <Page currentPage={currentPage} totalPage={totalPage} onClickPageChange={onClickPageChangeHandler} />}
    </div>
  );
}

export default ListBoard;

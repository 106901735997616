import React from 'react';
import QRpay from '../Layouts/QRpay/QRpay';
import Payment from '../QRPage/Contents/Payment';

function QRpayPaymentPage() {
  return (
    <QRpay QRContent={<Payment />}/>
  )
}

export default QRpayPaymentPage
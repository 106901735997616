import React, { useEffect, useState } from 'react';
import { Form, FormControl, InputGroup } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { certifyAuthCode, certifyReqPhone } from '../../../_actions/certify_action';
import { Modal } from 'react-bootstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { certifyAuthCodeValidation, findPasswordValidation } from '../../../validations/Validation';
import Timer from '../../Timer/Timer';
import { userTempPassword } from '../../../_actions/user_action';

function FindPassword(props) {
    const dispatch = useDispatch();
    const [schema, setSchema] = useState(findPasswordValidation);
    const [timer, setTimer] = useState(false);
    const { register, handleSubmit, formState:{ errors } } = useForm({
        resolver: yupResolver(schema)
    });

    const [certifyPhone, setCertifyPhone] = useState("");
    const [sendAuthCode, setsendAuthCode] = useState(false);
    const [isCertify, setIsCertify] = useState(false);
    const [sendTempPassword, setSendTempPassword] = useState(false);

    const onSubmitSendHandler = (event) => {
        let params = {
            ty: "PW",
            phone: (event.phone.replace(/ /gi, "")).replace(/-/gi, ""),
        }

        dispatch(certifyReqPhone(params))
        .then(response => {
            if (response.payload.result === "OK") {
                setSchema(certifyAuthCodeValidation);
                setCertifyPhone(event.phone);
                setsendAuthCode(true);
            } else {
                if (response.payload.msg === "exist") {
                    if (response.payload.data.data === "phone") {
                        alert("가입되지 않은 핸드폰 번호 입니다.");
                    }
                } else if (response.payload.data.validation) {
                    alert("[" + response.payload.data.validation.error + "] " + response.payload.data.validation.msg);
                } else {
                    alert("[페이지 오류 안내] 홈페이지 이용에 불편을 드려 죄송합니다. 새로고침 (F5키)을 누르시거나, 잠시 후에 이용해 주십시오.");
                }
            }
        });
    }

    const onSubmitCertifyHandler = (event) => {
        if (sendAuthCode) {
            let params = {
                authCode: event.authCode
            }
    
            dispatch(certifyAuthCode(params))
            .then(response => {
                if (response.payload.result === "OK") {
                    alert("인증이 완료되었습니다.");
                    setsendAuthCode(false);
                    setIsCertify(true);
                } else {
                    if (response.payload.msg === "exist") {
                        alert("인증에 실패하였습니다.");
                    } else if (response.payload.data.validation) {
                        alert("[" + response.payload.data.validation.error + "] " + response.payload.data.validation.msg);
                    } else {
                        alert("[페이지 오류 안내] 홈페이지 이용에 불편을 드려 죄송합니다. 새로고침 (F5키)을 누르시거나, 잠시 후에 이용해 주십시오.");
                    }
                }
            });
        } else {
            alert("인증번호가 전송되지 않았습니다.");
        }
    }

    const onSubmitPasswordHandler = (event) => {
        if (!isCertify) {
            alert("인증받은 전화번호가 없습니다.");
        } else {
            let params = {
                phone: (certifyPhone.replace(/ /gi, "")).replace(/-/gi, ""),
            }

            dispatch(userTempPassword(params))
            .then(response => {
                if (response.payload.result === "OK") {
                    setSendTempPassword(true);
                } else { 
                    if (response.payload.msg === "다수의 회원이 존재합니다.") {
                        alert("임시 비밀번호를 발급할 수 없는 계정입니다. 고객센터에 문의해주세요.");
                    } else {
                        alert("[페이지 오류 안내] 홈페이지 이용에 불편을 드려 죄송합니다. 새로고침 (F5키)을 누르시거나, 잠시 후에 이용해 주십시오.");
                    }
                }
            });
        }
    }

    useEffect(() => {
        if (timer) {
            alert("인증 제한 시간이 만료되었습니다. 인증 버튼을 다시 클릭해주세요.");
            setTimer(false);
            setSchema(findPasswordValidation);
            setsendAuthCode(false);
        }
    }, [timer]);

    return (
        <div>
            <Modal.Body>
                {
                    !sendTempPassword 
                    ? <div>
                        <Form onSubmit={handleSubmit(onSubmitSendHandler)} className="mb-3">
                        <InputGroup>
                            <FormControl
                                name="phone"
                                {...register("phone")}
                                className={"modal-input " + (errors.phone?.message ? "is-invalid" : "")}
                                placeholder='숫자만 입력해주세요.'
                                readOnly={sendAuthCode ? true : false}
                            />
                            <div className="tail-button">
                                <button type="submit" className="btn btn-secondary rounded-pill btn-center ml-2" disabled={sendAuthCode ? true : false}>인증번호 전송</button>
                            </div>
                            {
                                errors.phone?.message &&
                                <div className="invalid-feedback">{errors.phone?.message}</div>
                            }
                        </InputGroup>
                        </Form>
                        <Form onSubmit={handleSubmit(onSubmitCertifyHandler)}>
                            <InputGroup>
                                <FormControl
                                    name="authCode"
                                    {...register("authCode")}
                                    className={errors.authCode?.message && "is-invalid"}
                                    placeholder='인증번호를 입력해주세요.'
                                />
                                <div className="tail-button">
                                    <button type="submit" className="btn btn-secondary rounded-pill btn-center ml-2">인증번호 확인</button>
                                </div>
                                {
                                    errors.authCode?.message &&
                                    <div className="invalid-feedback">{errors.authCode?.message}</div>
                                }
                            </InputGroup>
                        </Form>
                        <div className={"mt-2 " + (!sendAuthCode ? "hide" : "")}>
                            <small className="text-danger">
                                인증번호 유효시간: {sendAuthCode && <Timer mm={3} ss={0} setTimer={setTimer}></Timer>}
                            </small>
                        </div>
                    </div>
                    : <div>
                        <div className='text-center'>
                        인증받은 휴대폰번호로 임시 비밀번호를 보냈습니다. <br/>
                        반드시 비밀번호를 변경한 후 사용해 주세요.
                        </div>
                    </div>
                }
                
            </Modal.Body>
            <Modal.Footer>
                {
                    !sendTempPassword 
                    ? <button onClick={onSubmitPasswordHandler} className="btn btn-main rounded-pill btn-center btn-chargeking">임시 비밀번호 받기</button>
                    : <button onClick={props.onHide} className="btn btn-gray rounded-pill btn-center">확인</button>
                }
           </Modal.Footer>
        </div>
    )
}

export default FindPassword
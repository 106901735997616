import {
    GET_CHARGING_LIST
} from './types';
import { apiGetPrams } from '../api/api';

export function getChargingList(data) {
    const request = apiGetPrams("/api/v1/chargings", data)
    .then(response => response.data);

    return  {
        type: GET_CHARGING_LIST,
        payload: request
    }
}

